import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Grid from '@material-ui/core/Grid';
import { DataContext } from '../ContextData';
import _ from 'lodash';
import { Button, Typography } from '@material-ui/core';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: 3,
      // width: '25ch',
    },
  },
}));

export default function BasicTextFields(props) {
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [state, setState] = React.useContext(DataContext);
  let currentIndex = _.indexOf(state.poSelect, state.selectedLot);
  console.log(state.lotDetails);
  const [qty, setQty] = React.useState([
    ...(state.lotDetails[currentIndex] || []),
  ]);
  const [heat, setHeat] = React.useState([
    ...(state.heatDetails[currentIndex] || []),
  ]);

  // add editable to package with zero QTY
  let disableQTY =
    // false; //ลบออกถ้าไม่ให้แก้เมื่อมี paket standart
    !!state.poSelect[currentIndex].PIECES_PER_KANBAN &&
    state.poSelect[currentIndex].PIECES_PER_KANBAN !== '0';

  console.log(disableQTY, state.poSelect[currentIndex].PIECES_PER_KANBAN);
  console.log('reset', state.lotDetails, state.lotDetails[currentIndex]);

  if (!state.qtyLot[currentIndex]) {
    return (
      <>
        <Typography>No. Pack is not defined.</Typography>
        <Grid container justify={'flex-end'}>
          <Button
            style={{ margin: 10 }}
            color="secondary"
            variant="contained"
            onClick={() => props.closeDialog()}
          >
            CANCEL
          </Button>
        </Grid>
      </>
    );
  }
  console.log(currentIndex, state, state.qtyLot[currentIndex]);
  let rows = Array.from(Array(parseInt(state.qtyLot[currentIndex])).keys());
  // console.log(state);
  const handleConfirm = () => {
    // verify sum
    console.log(parseInt(state.delAmount[currentIndex]), _.sum(qty));
    if (parseInt(state.delAmount[currentIndex]) !== _.sum(qty)) {
      // retset QTY
      // setQty([...(state.lotDetails[currentIndex] || [])]);
      setHeat([...(state.heatDetails[currentIndex] || [])]);
      return enqueueSnackbar('Total Quantity is not correct', {
        varint: 'error',
      });
    } else {
      // verified required heat (TODO and item check for material order)
      // if (!(state.heatDetails[currentIndex])) return enqueueSnackbar("Please fill in heat details", {
      //   varint: "error"
      // })

      // update state lotDetails
      let newLotDetails = state.lotDetails;
      newLotDetails[currentIndex] = qty;
      setState((state) => ({ ...state, lotDetails: newLotDetails }));

      // update state heatDetails
      let newHeatDetails = state.heatDetails;
      console.log(heat);
      newHeatDetails[currentIndex] = heat;
      setState((state) => ({ ...state, heatDetails: newHeatDetails }));
      props.closeDialog();
      return;
    }
  };

  return (
    // loop though number of lot
    <>
      <div>
        {rows.map((data, index) => (
          <form
            className={classes.root}
            noValidate
            autoComplete="off"
            key={index}
          >
            <TextField
              id="id"
              style={{ width: 50 }}
              disabled
              label="id"
              size="small"
              defaultValue={index + 1}
            />
            <TextField
              id="qty"
              size="small"
              label="QTY"
              disabled={disableQTY}
              // variant="outlined"
              defaultValue={qty.length !== 0 ? qty[index] : []}
              onChange={(e) => {
                let newValue = qty;
                newValue[index] = parseInt(e.target.value);
                setQty(newValue);
              }}
            />
            <TextField
              id="heat"
              size="small"
              label="Heat No."
              // variant="outlined"
              required
              defaultValue={heat.length !== 0 ? heat[index] : []}
              onChange={(e) => {
                let newValue = heat;
                newValue[index] = e.target.value;
                console.log(e.target.value);
                setHeat(newValue);
              }}
            />
          </form>
        ))}
      </div>

      <Grid
        container
        justify={'flex-end'}
        style={{
          marginTop: 10,
          position: 'sticky',
          bottom: 0,
        }}
      >
        <Button color="primary" variant="contained" onClick={handleConfirm}>
          CONFIRM
        </Button>
        <Button
          style={{ marginLeft: 5 }}
          color="secondary"
          variant="contained"
          onClick={() => props.closeDialog()}
        >
          CANCEL
        </Button>
      </Grid>
    </>
  );
}
