import React from 'react';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import InputBase from '@material-ui/core/InputBase';
import { fade, makeStyles } from '@material-ui/core/styles';
import SearchIcon from '@material-ui/icons/Search';
import { DataContext } from '../ContextData';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
    display: 'none',
    [theme.breakpoints.up('sm')]: {
      display: 'block',
    },
    fontFamily: 'Bai Jamjuree',
  },
  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: fade(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: fade(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '10ch',
      '&:focus': {
        width: '14ch',
      },
    },
  },
}));

export default function SearchAppBar() {
  const classes = useStyles();
  const [state, setState] = React.useContext(DataContext);
  const [searchTerm, setSearchTerm] = React.useState(state.searchTerm);
  const [deleted, setDelete] = React.useState(false);

  // console.log(state.searchTerm);
  const handleSearchChange = (event) => {
    const { value } = event.target;
    setSearchTerm(value);
    setState((state) => ({ ...state, searchTerm: value }));
  };

  const handleChange = (event) => {
    const { value } = event.target;
    setState((state) => ({ ...state, selectedValue: value }));
  };

  // React.useEffect(()=>{},[state.serachTerm])
  // React.useEffect(()=>{},[deleted])

  return (
    <div className={classes.root}>
      <AppBar position="static">
        <Toolbar>
          <Typography className={classes.title} variant="h6" noWrap>
            Deliveries Status
          </Typography>
          <div>
            <RadioGroup
              row
              aria-label="status"
              name="status"
              value={state.selectedValue}
              onChange={handleChange}
            >
              <FormControlLabel
                value="transit"
                disabled={state.deleted}
                control={<Radio />}
                label="Transit"
              />
              <FormControlLabel
                value="received"
                disabled={state.deleted}
                control={<Radio />}
                label="Received"
              />
              <FormControlLabel
                value="completed"
                disabled={state.deleted}
                control={<Radio />}
                label="Completed"
              />
              {/* <FormControlLabel value="deleted" control={<Radio />} label="Deleted" /> */}
              <FormControlLabel
                value="all"
                disabled={state.deleted}
                control={<Radio />}
                label="All"
              />
            </RadioGroup>
          </div>
          <div className={classes.search}>
            <div className={classes.searchIcon}>
              <SearchIcon />
            </div>
            <InputBase
              placeholder="Invoice/DO"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              disabled={state.deleted}
              inputProps={{ 'aria-label': 'search' }}
              value={state.searchTerm.inv_do}
              onChange={(event) => {
                const { value } = event.target;
                let data = { ...state.searchTerm, inv_do: value };
                setSearchTerm(data);
                setState((state) => ({ ...state, searchTerm: data }));
              }}
            />
            <InputBase
              placeholder="PO"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              disabled={state.deleted}
              inputProps={{ 'aria-label': 'search' }}
              value={state.searchTerm.po}
              onChange={(event) => {
                const { value } = event.target;
                let data = { ...state.searchTerm, po: value };
                setSearchTerm(data);
                setState((state) => ({ ...state, searchTerm: data }));
              }}
            />
            <InputBase
              placeholder="Rel"
              classes={{
                root: classes.inputRoot,
                input: classes.inputInput,
              }}
              disabled={state.deleted}
              inputProps={{ 'aria-label': 'search' }}
              value={state.searchTerm.rel}
              onChange={(event) => {
                const { value } = event.target;
                let data = { ...state.searchTerm, rel: value };
                setSearchTerm(data);
                setState((state) => ({ ...state, searchTerm: data }));
              }}
            />
          </div>
        </Toolbar>
      </AppBar>
    </div>
  );
}
