import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { DataContextMobile } from '../ContextData_Mobile';
import moment from 'moment';
import BarcodeScannerComponent from 'react-webcam-barcode-scanner';
import _, { intersectionBy } from 'lodash';
import CropFreeIcon from '@material-ui/icons/CropFree';
import { useSnackbar } from 'notistack';
import ReactLoading from 'react-loading';
import {
  createReceivedInternal,
  receivedDeliveryInternal_store,
  checkExistRecord,
} from '../utils/apis';
import ItemDetails from './ItemDetails';
import BoxDetails from './ScannedDetails_box';
// import { SignalCellularNullOutlined } from '@material-ui/icons';

//TODO create private to local network for scanner, remove remote login through server from scanner page

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1),
    },
    width: '100%',
    marginRight: 15,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
  paper: {
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  margin: {
    width: '90%',
    paddingLeft: 15,
    marginTop: 10,
  },
  heading: {
    flexBasis: '40%',
    flexShrink: 0,
    fontFamily: 'Bai Jamjuree',
  },
  secondaryHeading: {
    flexBasis: '20%',
    justifyContent: 'center',
    fontFamily: 'Bai Jamjuree',
  },
  thirdHeading: {
    flexBasis: '20%',
    justifyContent: 'center',
    fontFamily: 'Bai Jamjuree',
  },
  text: {
    fontFamily: 'Bai Jamjuree',
    fontSize: 16,
  },
}));

const Received = (props) => {
  console.log(props);
  // process scan main QR code => then generate required qr_id & scan packs => show progress
  const classes = useStyles();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [state, setState] = React.useContext(DataContextMobile);
  const [showScanEmpID, setShowScanEmpID] = React.useState(false);
  const [loading, setLoading] = React.useState(false);
  const [expanded, setExpanded] = React.useState(false);
  const handleChange = (panel) => (event, isExpanded) => {
    console.log(panel, props.mainRef);
    props.mainRef.current.focus();
    setExpanded(isExpanded ? panel : false);
    event.preventDefault();
  };

  console.log(expanded);

  // console.log(state);
  const handleSubmit = async (header, index) => {
    // console.log(header);

    let toSubmitData = [];
    let qrItemCodeList = _.map(state.qrCodeItem[header], 'itemData');
    qrItemCodeList.map((po, index) => {
      let data = po.split('|');
      toSubmitData.push({
        po: data[2],
        rel: data[3],
        line: data[4],
        inv: header.split('|')[2],
        qty: data[6],
        supplier_lot: data[8],
        aapico_lot: moment(data[9]).format('YYYYMMDD'),
        inv_orgid: data[10],
        delivery_date: moment(data[9]).format('YYYYMMDD'),
        invoice_date: moment(header.split('|')[4]).format('YYYYMMDD'),
        site1: header.split('|')[6],
        isInvoice: header.split('|')[1] === '0' ? false : true,
        shipment_num: data[12],
      });
    });

    // TODO create lot

    // alert(state.qrCodeMain);
    setLoading(true);

    // check if transit deliveries exist
    let exist = await checkExistRecord(
      header.split('|')[2],
      header.split('|')[8],
      'transit'
    );
    // alert('exit' + exist)
    if (exist === 0) {
      setLoading(false);
      return enqueueSnackbar('ไม่พบใบจัดส่งนี้ในระบบ', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
        autoHideDuration: 300000,
      });
    }
    createReceivedInternal(toSubmitData).then((res) => {
      setLoading(false);
      if (res.Status === 'ERROR') {
        return enqueueSnackbar(res.Message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'center',
          },
          autoHideDuration: 300000,
        });
      }
      receivedDeliveryInternal_store(
        {
          vendor_id: header.split('|')[8],
          invoiceNo: header.split('|')[2],
        },
        header.split('|')[1] === '0' ? false : true,
        state.employeeID,
        res,
        localStorage.getItem('STORE_NAME')
      ).then(async (response) => {
        setLoading(false);
        if (response.status === 200) {
          /** Received no set to set for updated submit button */
          let newReceiveNo = state.receiveNo;
          newReceiveNo[index] = res.ReceiveNumber;
          setState((state) => ({ ...state, receiveNo: newReceiveNo }));

          enqueueSnackbar(
            `Received successfully invoice no: ${
              header.split('|')[2]
            }- receive no: ${res.ReceiveNumber}`,
            {
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              autoHideDuration: 300000,
            }
          );
        }
        // return setState(state => ({...state, qrCodeMain: null, qrCodeItem: [], employeeID: ""}));
      });
    });
  };

  if (loading)
    return <ReactLoading type={'bars'} color={'#444'} className="preloader" />;

  if (showScanEmpID)
    return (
      <div
        style={{ margin: 'auto', alignContent: 'center', textAlign: 'center' }}
      >
        <BarcodeScannerComponent
          // width={'100%'}
          // height={300}
          onUpdate={(err, result) => {
            if (typeof result.text === 'string') {
              let data = result.text;
              // alert(typeof(data));
              // alert(result.text);
              // alert(data.split("|")[0]);
              if (data.split('|')[0] === '2') {
                setState((state) => ({
                  ...state,
                  qrCodeMain: '',
                  qrCodeItem: [],
                  warning: 'Please select Do to Invoice option instead',
                }));
                return setShowScanEmpID(false);
              }
              if (data.split('|')[0] === '0') {
                setState((state) => ({
                  ...state,
                  qrCodeMain: data,
                  warning: null,
                }));
                return setShowScanEmpID(false);
              } else if (data.split('|')[0] === '1') {
                // check main qr
                if (!state.qrCodeMain) {
                  setState((state) => ({
                    ...state,
                    warning: 'Scan header first',
                  }));
                  return setShowScanEmpID(false);
                }
                // alert(state.qrCodeMain.split("|")[8])
                // alert(data.split("|")[2])
                // check main qr
                if (state.qrCodeMain.split('|')[8] !== data.split('|')[2]) {
                  setState((state) => ({ ...state, warning: 'PO not match' }));
                  return setShowScanEmpID(false);
                }
                if (_.indexOf(state.qrCodeItem, data) === -1) {
                  let newQrItem = [...state.qrCodeItem, data];
                  setState((state) => ({ ...state, qrCodeItem: newQrItem }));
                  return setShowScanEmpID(false);
                }
              } else {
                // alert(data);
                setState((state) => ({ ...state, employeeID: data }));
                return setShowScanEmpID(false);
              }
            }
          }}
        />
        {/* <Button color="primary" onClick={() => setShowScanEmpID(false)}>Back</Button> */}
      </div>
    );

  console.log(state);

  return (
    <>
      <Grid container justify={'center'} alignItems={'center'} direction="row">
        {/* <Typography style={{margin: "auto", color: "red"}}>{state.warning}</Typography> */}
        {state.warning && (
          <div className="internet-error">
            <p>{state.warning}</p>
          </div>
        )}
        <Grid item xs={12}>
          <TextField
            id="outlined-basic"
            fullWidth
            size="small"
            disabled
            label="Employee ID"
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            value={state.employeeID}
          />
        </Grid>
        {/* TODO make this to arrays */}
        {state.qrCodeMain
          ? state.qrCodeMain.map((mainItem, index) => (
              <Grid item xs={12} style={{ marginBottom: 15 }}>
                <Accordion>
                  <AccordionSummary
                    style={{
                      color: 'white',
                      marginTop: '10px',
                      background: '#162850',
                    }}
                    expandIcon={<ExpandMoreIcon style={{ color: 'white' }} />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                  >
                    {' '}
                    <div className={classes.heading}>
                      <Typography className={classes.text}>
                        Invoice No.{' '}
                      </Typography>
                      <Typography className={classes.text} variant="h6">
                        {mainItem.split('|')[2]}
                      </Typography>
                    </div>
                    <div className={classes.secondaryHeading}>
                      <Typography className={classes.text}>
                        Total items
                      </Typography>
                      <Typography className={classes.text} variant="h6">
                        {mainItem.split('|')[5]}
                      </Typography>
                    </div>
                  </AccordionSummary>
                  <AccordionDetails>
                    <div className={classes.root} noValidate autoComplete="off">
                      <TextField
                        size="small"
                        fullWidth
                        id="del-id"
                        label="Type"
                        variant="outlined"
                        disabled
                        value={mainItem.split('|')[1] ? 'Invoice' : 'DO'}
                      />
                      <TextField
                        size="small"
                        fullWidth
                        id="del-id"
                        label="Invoice/DO Number"
                        variant="outlined"
                        disabled
                        value={mainItem.split('|')[2]}
                      />
                      <TextField
                        size="small"
                        fullWidth
                        id="del-id"
                        label="Invoice Date"
                        variant="outlined"
                        disabled
                        value={mainItem.split('|')[4]}
                      />
                      <TextField
                        size="small"
                        fullWidth
                        id="del-id"
                        label="Total item"
                        variant="outlined"
                        disabled
                        value={mainItem.split('|')[5]}
                      />
                      <TextField
                        size="small"
                        fullWidth
                        id="del-id"
                        label="Site"
                        variant="outlined"
                        disabled
                        value={mainItem.split('|')[6]}
                      />
                    </div>
                  </AccordionDetails>
                </Accordion>

                {/* verified that itemqr array exist and items scann not equal 0 */}
                {state.qrCodeItem &&
                  state.qrCodeItem[mainItem].map((scannedItem, ind) => {
                    // console.log(item, state.items);
                    // // get correct state from item store
                    // let scannedItem = _.filter(state.items, ite => {
                    //   // filter more variables item name, line num and shipment_num
                    //   return ite.compare === item.split("|")[5] +  "|" + item.split("|")[12] + "|" + item.split("|")[4]
                    // });
                    console.log(scannedItem, mainItem);
                    return (
                      <Accordion
                        expanded={expanded === `panel${index}-${ind}`}
                        onChange={handleChange(`panel${index}-${ind}`)}
                      >
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <div className={classes.heading}>
                            <Typography className={classes.text}>
                              Item No.{' '}
                            </Typography>
                            <Typography className={classes.text} variant="h6">
                              {scannedItem.itemData.split('|')[5]}
                            </Typography>
                          </div>
                          <div className={classes.secondaryHeading}>
                            <Typography className={classes.text}>
                              QTY:{' '}
                            </Typography>
                            <Typography
                              className={classes.text}
                              variant="h6"
                              style={
                                scannedItem.scannedValue !==
                                scannedItem.totalValue
                                  ? { color: 'red' }
                                  : null
                              }
                            >
                              {scannedItem.scannedValue}/
                              {scannedItem.totalValue}
                            </Typography>
                          </div>
                          <div className={classes.thirdHeading}>
                            <Typography className={classes.text}>
                              Boxes:{' '}
                            </Typography>
                            <Typography
                              className={classes.text}
                              variant="h6"
                              style={
                                scannedItem.scannedValue !==
                                scannedItem.totalValue
                                  ? { color: 'red' }
                                  : null
                              }
                            >
                              {scannedItem.scannedTag.length}/
                              {scannedItem.totalTag}
                            </Typography>
                          </div>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div>
                            <div style={{ marginBottom: '10px' }}>
                              <BoxDetails
                                item={scannedItem}
                                index={index + '-' + ind}
                              />
                            </div>
                            <div>
                              <ItemDetails
                                item={scannedItem.itemData}
                                styleProps={classes.root}
                              />
                            </div>
                          </div>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}

                {/* <Grid container justify="center">
                  <div>
                  {(!props.isDisconnected && !!state.employeeID && !!state.qrCodeMain && parseInt(state.qrCodeMain.split("|")[5]) === state.qrCodeItem.length)?
                  <Button variant="contained" size="small" color="primary" style={{margin: 10}} onClick={handleSubmit}>
                  Submit
                  </Button>:null}
                  </div>
                </Grid> */}

                {/* check all box */}
                <Grid container justify="center">
                  <div>
                    {state.receiveNo[index] === '' ? (
                      !props.isDisconnected &&
                      !!state.employeeID &&
                      parseInt(mainItem.split('|')[5]) ===
                        state.qrCodeItem[mainItem].length &&
                      _.every(
                        state.qrCodeItem[mainItem],
                        (item) => item.scannedValue === item.totalValue
                      ) ? (
                        <Button
                          variant="contained"
                          size="small"
                          color="primary"
                          style={{ margin: 10 }}
                          onClick={() => handleSubmit(mainItem, index)}
                        >
                          Submit
                        </Button>
                      ) : null
                    ) : (
                      <Button
                        variant="contained"
                        disabled={true}
                        size="small"
                        color="primary"
                        style={{ margin: 10 }}
                      >
                        {`Rec. no. : ${state.receiveNo[index]}`}
                      </Button>
                    )}
                  </div>
                </Grid>
              </Grid>
            ))
          : null}
      </Grid>
    </>
  );
};

export default Received;
