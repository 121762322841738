import React from 'react';
import { BsFillEyeFill, BsInboxesFill } from 'react-icons/bs';
import { MdMarkEmailRead } from 'react-icons/md';

function ASNCard({ data }) {
  return (
    <div className="flex items-center justify-center flex-col m-3 bg-slate-200">
      <div className="break-inside relative overflow-hidden flex flex-col justify-between space-y-2 text-sm rounded-xl max-w-[23rem] p-4 mb-4 bg-blue-700 text-white">
        <div className="flex justify-between p-auto">
          <span className="text-md w-24 flex justify-center items-center rounded-full shadow-md bg-green-400">
            {data.delivery_date}
          </span>
          {data.email_sent && (
            <MdMarkEmailRead className="w-8 h-8 text-green-300" />
          )}
        </div>
        <div className="flex flex-row items-center space-x-3 my-5">
          <BsInboxesFill className="w-12 h-12" />
          <span className="text-base font-bold">
            Advance Shipment Notification
          </span>
        </div>
        <div className="flex justify-between items-center ml-3">
          <span>By: {data.uploader}</span>
          <button
            onClick={() => window.open(data.attachment[0].url, '_blank')}
            className="flex items-center justify-center text-xs font-medium rounded-full px-4 py-2 space-x-1 bg-white text-black"
          >
            <BsFillEyeFill className="w-5 h-5" />
          </button>
        </div>
      </div>
    </div>
  );
}

export default ASNCard;
