import React from 'react';
import Table from '../../components/TailwindTable';
import { useQuery } from '@tanstack/react-query';
import _ from 'lodash';
import Pagination from '../../components/Pagination';
import { getConfirmPO, sendingEmail, updateConfirmPO } from '../../utils/apis';
import { getUserType, alert_email, user_email } from '../../utils';
import ListBox from '../../components/ListBox';
import { BsFillEyeFill, BsClipboardPlus, BsCheckAll } from 'react-icons/bs';
import { AiOutlineDelete } from 'react-icons/ai';
import { GiConfirmed } from 'react-icons/gi';
import HeadlessSlideOver from '../../components/SlideIn';
import ConfirmASN from './confirmASN';
import { BsChevronRight, BsChevronDown } from 'react-icons/bs';
import ASNCard from './ASNCard';
import Loading from '../../components/Loading';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import Toggle from './Toggle';

const pageSizeList = [
  { id: 1, name: 5 },
  { id: 2, name: 10 },
  { id: 3, name: 20 },
  { id: 4, name: 50 },
  { id: 5, name: 100 },
];

function POTable({ show, setShow, handleDelete, handleView, reload }) {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectablePage, setSelectablePage] = React.useState([1, 2, 3]);
  const [activePage, setActivePage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState();
  const [totalConfirmPO, setTotalConfirmPO] = React.useState();
  const [pageSize, setPageSize] = React.useState(50);
  const [confirmPO, setConfirmPO] = React.useState([]);
  const [selectedPO, setSelectedPO] = React.useState('');
  const [searchPO, setSearchPO] = React.useState('');
  const [searchREL, setSearchREL] = React.useState('');
  const [searchSupName, setSearchSupName] = React.useState('');
  const [searchSupCode, setSearchSupCode] = React.useState('');
  const [toggle, setToggle] = React.useState(false);
  const [pageSizeSelected, setPageSizeSelected] = React.useState(
    pageSizeList[3]
  );
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const resentEmail = async (e) => {
    console.log('sent mail');
    setLoading(true);
    // TODO sending email notification and update record
    console.log(e.receiver_email, alert_email());
    let emailResult = await sendingEmail({
      to: e.receiver_email,
      cc: alert_email(),
      subject: 'Automatic PO Confirmation',
      templateReferenceId: 2, // template number
      data: {
        url: 'https://smp.aapico.com/#/confirm-po',
        factory: e.factory,
        po: e.po_number,
        rel: e.rel_number,
      },
    });

    if (emailResult.status !== 200) {
      refetch();
      setLoading(false);
      return enqueueSnackbar('Email not sent', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 300000,
      });
    } else {
      // update email sent status
      await updateConfirmPO(e.id, { mail_sent: true });
      refetch();
      setLoading(false);
      return enqueueSnackbar('Email sent successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 300000,
      });
    }
  };

  // Create a function that will render our row sub components
  const detailComponents = React.useCallback(({ row: { original } }) => {
    console.log(original);
    return (
      <div className="w-full bg-white flex">
        {original.confirm_asns.map((each) => (
          <ASNCard data={each} />
        ))}
      </div>
    );
  }, []);

  const columns = React.useMemo(
    () => [
      {
        // Build our expander column
        id: 'expander', // Make sure it has an ID

        Cell: ({ row }) => (
          <div {...row.getToggleRowExpandedProps()} className="ml-2">
            {row.isExpanded ? (
              <BsChevronDown className="w-4 h-4" />
            ) : (
              <BsChevronRight className="w-4 h-4" />
            )}
          </div>
        ),
      },

      {
        Header: 'View PO',
        Cell: ({ row }) => {
          return (
            <div className="space-x-2">
              <button
                className="bg-gray-50 p-2 rounded-lg shadow-md"
                onClick={() => handleView(row.original)}
              >
                <BsFillEyeFill className={'text-blue-600 w-5 h-5'} />
              </button>
              {getUserType() === 'store' && (
                <button
                  className="bg-gray-100 p-2 rounded-lg shadow-md"
                  onClick={() => handleDelete(row.original)}
                >
                  <AiOutlineDelete className={'text-blue-600 w-5 h-5'} />
                </button>
              )}
            </div>
          );
        },
      },
      {
        Header: 'Factory',
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.factory}
            </span>
          );
        },
      },
      {
        Header: 'PO No.',
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.po_number}
            </span>
          );
        },
      },
      {
        Header: 'REL No.',
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.rel_number}
            </span>
          );
        },
      },
      {
        Header: 'Supplier Name',
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.supplier_name}
            </span>
          );
        },
      },
      {
        Header: 'Supplier Code',
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.supplier_code}
            </span>
          );
        },
      },
      {
        Header: 'Upload by',
        Cell: ({ row }) => {
          return (
            <div className={`flex-col`}>
              <span className={`text-sm font-jamjuree`}>
                {row.original.uploader}
              </span>
              <span className={`flex text-sm font-jamjuree`}>
                {new moment(row.original.createdAt).format('YYYY-MM-DD HH:mm')}
              </span>
            </div>
          );
        },
      },
      {
        Header: 'Mail Sent',
        Cell: ({ row }) => {
          let disabled = row.original.mail_sent || getUserType() === 'vendor';
          return (
            <span className={`text-sm font-jamjuree`}>
              <button
                onClick={() => resentEmail(row.original)}
                className={` ${
                  disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-500'
                }  p-2 rounded-md text-white shadow-md`}
                disabled={disabled}
              >
                {row.original.mail_sent ? (
                  <BsCheckAll className={'w-4 h-4 '} />
                ) : (
                  'Send'
                )}
              </button>
            </span>
          );
        },
      },
      {
        Header: 'Email Group',
        Cell: ({ row }) => {
          return (
            <div className={`flex flex-col`}>
              {row.original.receiver_email.split(',').map((each) => (
                <span className={`text-sm font-jamjuree`}>{each}</span>
              ))}{' '}
            </div>
          );
        },
      },
      {
        Header: 'Download by',
        Cell: ({ row }) => {
          return (
            <div className={`flex-col`}>
              <span className={`flex text-sm font-jamjuree`}>
                {row.original.downloaded_by}
              </span>
              <span className={`flex text-sm font-jamjuree`}>
                {row.original.downloaded_at}
              </span>
            </div>
          );
        },
      },
      {
        Header: 'View Count',
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.download_count || 0}
            </span>
          );
        },
      },
      {
        Header: 'ASN Confirmation',
        Cell: ({ row }) => {
          return (
            <div>
              <button
                disabled={getUserType() !== 'vendor'}
                onClick={() => {
                  setSelectedPO(row.original);
                  setShowConfirm(true);
                }}
                className={`bg-indigo-500 ${
                  getUserType() !== 'vendor' &&
                  'bg-gray-400 opacity-50 cursor-not-allowed'
                }  rounded-md shadow-md`}
              >
                <div className={`flex text-white space-x-2 p-2 my-auto `}>
                  <GiConfirmed className={`w-5 h-5`} />
                  <p>Confirm ASN</p>
                </div>
              </button>
            </div>
          );
        },
      },
      {
        Header: 'Confirm ASN Count',
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.confirm_asns.length}
            </span>
          );
        },
      },
    ],
    []
  );

  const previousPage = () => {
    if (activePage - 1 > 0) {
      setActivePage(activePage - 1);
    }
  };
  const nextPage = () => {
    if (totalPages >= activePage + 1) {
      setActivePage(activePage + 1);
    }
  };
  const gotoLastPage = () => {
    setActivePage(totalPages == 0 ? 1 : totalPages);
  };
  const gotoFirstPage = () => {
    setActivePage(1);
  };

  const handlePageSizeSelected = (pageSizeSelect) => {
    setPageSize(pageSizeSelect.name);
    setPageSizeSelected(pageSizeSelect);
  };

  const formatData = (data) => {
    console.log(data);
    // filter email addresses
    let result = [];
    // filter ouput by email if result hase email
    if (getUserType() == 'vendor') {
      result = _.filter(data.data, (each) => {
        if (each.receiver_email) {
          // check if it belong to user
          return each.receiver_email.includes(user_email());
        } else {
          return true;
        }
      });
      setConfirmPO(result);
    } else {
      setConfirmPO(data.data);
    }
    // setTotalConfirmPO(Math.ceil(data.total / pageSize));
    setTotalConfirmPO(data.total);
  };

  const handleToggle = (e) => {
    setToggle(e);
  };

  const { isLoading, error, data, isFetching, refetch } = useQuery(
    ['confirmPO', activePage],
    () =>
      getConfirmPO(
        activePage,
        pageSize,
        searchPO,
        searchREL,
        toggle,
        searchSupName,
        searchSupCode
      )
  );
  React.useEffect(() => {
    if (data) {
      formatData(data);
    }
  }, [data]);

  React.useEffect(() => {
    refetch();
  }, [
    pageSize,
    activePage,
    selectablePage,
    pageSizeSelected,
    searchPO,
    searchREL,
    toggle,
    searchSupName,
    searchSupCode,
  ]);

  React.useEffect(() => {
    if (totalPages === 0) {
      setSelectablePage([1]);
    } else {
      let newSelecteablePages = _.filter(
        [activePage - 1, activePage, activePage + 1],
        (item) => item !== 0 && item !== totalPages + 1
      );
      setSelectablePage(newSelecteablePages);
    }
  }, [activePage, totalPages]);

  React.useEffect(() => {
    refetch();
  }, [reload]);

  if (isLoading) {
    return <Loading />;
  }

  if (confirmPO) {
    return (
      <div className="flex-col overflow-hidden w-full">
        <div className="">
          {showConfirm && (
            <HeadlessSlideOver
              open={showConfirm}
              setOpen={setShowConfirm}
              width="w-96"
              layout="right"
            >
              <div className="flex flex-col h-full pt-3">
                <ConfirmASN
                  data={selectedPO}
                  refetch={refetch}
                  setShowConfirm={setShowConfirm}
                />
              </div>
            </HeadlessSlideOver>
          )}
        </div>

        <div className="flex w-full">
          <div className="flex w-1/2 mx-5 space-x-2 mt-3 h-10">
            <input
              type="text"
              name="pono"
              id="pono"
              placeholder="PO No."
              className="bg-gray-50 border w-40 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) => setSearchPO(e.target.value)}
            ></input>
            <input
              type="text"
              name="rel"
              id="rel"
              placeholder="REL No."
              className="bg-gray-50 border w-40 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) => setSearchREL(e.target.value)}
            ></input>
            <input
              type="text"
              name="rel"
              id="rel"
              placeholder="Sup. name"
              className="bg-gray-50 border w-40 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) => setSearchSupName(e.target.value)}
            ></input>
            <input
              type="text"
              name="rel"
              id="rel"
              placeholder="Sup. code"
              className="bg-gray-50 border w-40 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) => setSearchSupCode(e.target.value)}
            ></input>
          </div>
          <div className="flex flex-col w-1/2 right-0 mr-5 z-10 pl-2 pt-3 bg-slate-700 ">
            <div className="flex space-x-2 w-full justify-end">
              <div>
                <Pagination
                  selectablePage={selectablePage}
                  activePage={activePage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  gotoLastPage={gotoLastPage}
                  gotoFirstPage={gotoFirstPage}
                  setActivePage={setActivePage}
                />
              </div>
              <div className="flex space-x-2 justify-end">
                <div className="flex mt-1 mr-2 text-gray-500 font-jamjuree">
                  Total Record: {totalConfirmPO}
                </div>
                <ListBox
                  width={'w-20'}
                  data={pageSizeList}
                  type="event pagination"
                  multiple={false}
                  handleSelected={handlePageSizeSelected}
                  defaultValue={pageSizeSelected}
                />
              </div>
              {(getUserType() == 'store' || getUserType() === 'admin') && (
                <div className="flex h-9 shadow-md">
                  <button
                    onClick={() => setShow(true)}
                    className="rounded-md bg-green-500 p-2"
                  >
                    <BsClipboardPlus className="w-5 h-5" />
                  </button>
                </div>
              )}
            </div>
            <div className="flex justify-end">
              <Toggle handleToggle={handleToggle} />
            </div>
          </div>
        </div>
        {loading && <Loading />}
        <div
          className="block mt-20 mx-5 w-full overflow-x-scroll"
          style={{ height: '85vh' }}
        >
          <Table
            columns={columns}
            data={confirmPO}
            manualPagination={true}
            showSelect={false}
            handleSelect={(selected) => console.log(selected)}
            detailComponents={detailComponents}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="flex justify-center w-auto h-full items-center overflow-hidden">
      <div
        style={{ borderTopColor: 'transparent' }}
        className="w-16 h-16 border-4 border-blue-400 border-dotted rounded-full animate-spin"
      ></div>
    </div>
  );
}

export default POTable;
