import React, { useEffect } from 'react';
import { Table } from '../../components/Table';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import TextField from '@material-ui/core/TextField';
import EditIcon from '@material-ui/icons/Edit';
import _, { set, delay } from 'lodash';
import { DataContext } from '../../ContextData';
import Dialog from '../Dialog';
import { useSnackbar } from 'notistack';
import { getUserType } from '../../utils';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const SweetAlert = withReactContent(Swal);

// Let's simulate a large dataset on the server (outside of our component)
// const serverData = makeData(10000)

function App({ remoteData, history }) {
  // console.log(history);
  // console.log(remoteData);
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = React.useContext(DataContext);
  // const [select, setSelect] = useState(state.poSelect);
  const appendSelect = async (original) => {
    let currentIndex = _.findIndex(state.poSelect, original);
    // console.log(state.poSelect);
    if (currentIndex === -1) {
      // setSelect(state => [...state, original])
      setState((state) => ({
        ...state,
        poSelect: [...state.poSelect, original],
        delAmount: [...state.delAmount, null],
      }));
    } else {
      let newSelect = await _.remove(state.poSelect, (el) => {
        return !_.isEqual(el, original);
      });
      // console.log(newSelect);
      // setSelect(newSelect);
      // setState(state => ({...state, poSelect: newSelect}))
      // remove delAmount & qtyLot
      // console.log(original);
      // console.log(currentIndex);
      // console.log(state);
      let newDelAmount = state.delAmount;
      newDelAmount.splice(currentIndex, 1);
      let newQtyLot = state.qtyLot;
      newQtyLot.splice(currentIndex, 1);
      let newLotSup = state.lotSup;
      newLotSup.splice(currentIndex, 1);
      let newLotDetails = state.lotDetails;
      newLotDetails.splice(currentIndex, 1);
      setState((state) => ({
        ...state,
        poSelect: newSelect,
        delAmount: newDelAmount,
        qtyLot: newQtyLot,
        lotSup: newLotSup,
        lotDetails: newLotDetails,
      }));
    }
  };
  // check select exist
  const RenderDelAmount = ({ row: { original } }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(
      state.delAmount[_.findIndex(state.poSelect, original)]
    );
    const onChange = (e) => {
      setValue(e.target.value);
    };
    const appendDelAmount = () => {
      // console.log(_.findIndex(state.poSelect, original));
      let currentIndex = _.findIndex(state.poSelect, original);

      console.log(state);

      // if not materail
      if (
        !state.poSelect[currentIndex].ITEM_TYPE.includes('Raw Materials') &&
        !state.poSelect[currentIndex].ITEM_TYPE.includes('RM')
      ) {
        // return error in case del amount > remain
        if (
          parseFloat(state.poSelect[currentIndex].QUANTITY_REMAIN) -
            parseFloat(original.transit) <
          parseFloat(value)
        ) {
          // reset value
          setValue('');
          return enqueueSnackbar(
            `ข้อผิดพลาด: จำนวนการจัดส่ง(${parseFloat(
              value
            )}) มากกว่า ปริมาณคงเหลือ(${
              parseFloat(state.poSelect[currentIndex].QUANTITY_REMAIN) -
              parseFloat(original.transit)
            })`,
            {
              variant: 'error',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            }
          );
        }
      }

      if (value) {
        let newAmount = state.delAmount;
        console.log(newAmount);
        console.log(
          'soknath',
          currentIndex,
          value,
          state.poSelect[currentIndex]
        );
        newAmount[currentIndex] = value;
        let newQtyLot = state.qtyLot;
        // case standard package available
        // soknath add special condition to AA only
        // update AA set QTY to 0
        if (
          state.poSelect[currentIndex].PIECES_PER_KANBAN &&
          state.poSelect[currentIndex].PIECES_PER_KANBAN !== '0'
        ) {
          // check if standard package correct ---------------
          let remain =
            parseFloat(newAmount[currentIndex]) %
            parseInt(state.poSelect[currentIndex].PIECES_PER_KANBAN);

          let check_aa_prm =
            state.poSelect[currentIndex].INV_ORGID === '310'
              ? !state.poSelect[currentIndex].ITEM_CODE.includes('PRM')
              : true;
          if (
            parseFloat(newAmount[currentIndex]) &&
            remain !== 0 &&
            check_aa_prm
          ) {
            // add exceptional to AAA - 20220211
            // alert('No follow standard package');
            SweetAlert.fire({
              icon: 'error',
              title: 'Oops...',
              text: 'ไม่เป็นไปตามแพ็คเกจมาตรฐาน',
            });
            newAmount[currentIndex] = null;
            // clear existing state
            newQtyLot[currentIndex] = null;
            // newQtyLot.splice(currentIndex, 1);
            // delete newQtyLot[currentIndex];
            let newLotDetails = state.lotDetails;
            newLotDetails[currentIndex] = null;
            // newLotDetails.splice(currentIndex, 1);
            // delete newLotDetails[currentIndex];
            console.log(currentIndex, newAmount, newQtyLot, newLotDetails);
            setState((state) => ({
              ...state,
              delAmount: newAmount,
              qtyLot: newQtyLot,
              lotDetails: newLotDetails,
            }));
          }
          // -----------------------------------------
          else {
            newQtyLot[currentIndex] = Math.ceil(
              value / parseInt(state.poSelect[currentIndex].PIECES_PER_KANBAN)
            );
            // update lot details
            let newLotDetails = state.lotDetails;
            newAmount[currentIndex] = value;
            // console.log(state);
            let supLength = parseInt(
              parseFloat(newAmount[currentIndex]) /
                parseInt(state.poSelect[currentIndex].PIECES_PER_KANBAN)
            );
            console.log(supLength);
            // update alert if input cannot be group into packet standard
            console.log(
              parseFloat(newAmount[currentIndex]) %
                parseInt(state.poSelect[currentIndex].PIECES_PER_KANBAN)
            );
            newLotDetails[currentIndex] =
              parseFloat(newAmount[currentIndex]) %
                parseInt(state.poSelect[currentIndex].PIECES_PER_KANBAN) !==
              0
                ? new Array(supLength)
                    .fill(
                      parseInt(state.poSelect[currentIndex].PIECES_PER_KANBAN)
                    )
                    .concat(
                      parseFloat(newAmount[currentIndex]) %
                        parseInt(state.poSelect[currentIndex].PIECES_PER_KANBAN)
                    )
                : new Array(supLength).fill(
                    parseInt(state.poSelect[currentIndex].PIECES_PER_KANBAN)
                  );

            // console.log(newLotDetails);
            setState((state) => ({
              ...state,
              delAmount: newAmount,
              qtyLot: newQtyLot,
              lotDetails: newLotDetails,
            }));
          }
        }
        // case no standard package
        else {
          setState((state) => ({ ...state, delAmount: newAmount }));
        }
      } else {
        // clear lot, qtylot and lot details
        let newAmount = state.delAmount;
        let newQtyLot = state.qtyLot;
        let newLotDetails = state.lotDetails;
        // newAmount.splice(currentIndex, 1);
        // newQtyLot.splice(currentIndex, 1);
        // newLotDetails.splice(currentIndex, 1);
        newAmount[currentIndex] = null;
        newQtyLot[currentIndex] = null;
        newLotDetails[currentIndex] = null;
        setState((state) => ({
          ...state,
          delAmount: newAmount,
          qtyLot: newQtyLot,
          lotDetails: newLotDetails,
        }));
      }
    };
    // console.log(state, value);
    return (
      <TextField
        size="small"
        inputProps={{ style: { fontSize: 12 } }} // font size of input text
        disabled={_.findIndex(state.poSelect, original) !== -1 ? false : true}
        value={value}
        onChange={onChange}
        onBlur={appendDelAmount}
        onKeyDown={(e) => {
          if (e.keyCode === 13) {
            e.target.blur();
            //Write you validation logic here
          }
        }}
        type="number"
        // label={"Amo."}
        variant="outlined"
        fullWidth
        style={{ width: 80 }}
      />
    );
  };

  // check select exist
  const RenderQtyLot = ({ row: { original } }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(
      state.qtyLot[_.findIndex(state.poSelect, original)]
    );
    let currentIndex = _.findIndex(state.poSelect, original);
    const onChange = (e) => {
      setValue(e.target.value);
    };
    const appendQtyLot = () => {
      if (value) {
        // check of delivery amount is deliverd
        if (currentIndex !== -1 && !state.delAmount[currentIndex]) {
          enqueueSnackbar('กรุณากรอกข้อมูล delivery QTY.', {
            varain: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
          let newAmount = state.qtyLot;
          newAmount[currentIndex] = null;
          return setState((state) => ({ ...state, qtyLot: newAmount }));
        }
        // update on qty is available
        // update AA set QTY to 0
        if (
          state.poSelect[currentIndex].PIECES_PER_KANBAN &&
          state.poSelect[currentIndex].PIECES_PER_KANBAN !== '0'
        ) {
          let newAmount = state.qtyLot;
          console.log(newAmount);
          let newLotDetails = state.lotDetails;
          newAmount[currentIndex] = value;
          // console.log(state);
          let supLength = parseInt(
            parseFloat(state.delAmount[currentIndex]) /
              parseInt(state.poSelect[currentIndex].PIECES_PER_KANBAN)
          );
          // console.log(supLength);
          newLotDetails[currentIndex] =
            parseFloat(state.delAmount[currentIndex]) %
              parseInt(state.poSelect[currentIndex].PIECES_PER_KANBAN) !==
            0
              ? new Array(supLength)
                  .fill(
                    parseInt(state.poSelect[currentIndex].PIECES_PER_KANBAN)
                  )
                  .concat(
                    parseFloat(state.delAmount[currentIndex]) %
                      parseInt(state.poSelect[currentIndex].PIECES_PER_KANBAN)
                  )
              : new Array(supLength).fill(
                  parseInt(state.poSelect[currentIndex].PIECES_PER_KANBAN)
                );
          // console.log(newLotDetails);
          setState((state) => ({
            ...state,
            qtyLot: newAmount,
            lotDetails: newLotDetails,
          }));
        } else {
          let newAmount = state.qtyLot;
          newAmount[currentIndex] = value;
          let supLength = parseInt(value);
          let newLotDetails = state.lotDetails;
          // console.log(supLength);
          newLotDetails[currentIndex] = new Array(supLength).fill(
            parseInt(state.delAmount[currentIndex] / state.qtyLot[currentIndex])
          );
          // console.log(newLotDetails);
          return setState((state) => ({
            ...state,
            qtyLot: newAmount,
            lotDetails: newLotDetails,
          }));
        }
      }
    };

    // console.log(original)
    return (
      <TextField
        size="small"
        inputProps={{ style: { fontSize: 12 } }} // font size of input text
        disabled={
          _.findIndex(state.poSelect, original) !== -1 &&
          (!original.PIECES_PER_KANBAN || original.PIECES_PER_KANBAN === '0')
            ? false
            : true
        } // QTY per Pack is disable when PACK STD are exist
        value={value}
        onChange={onChange}
        onBlur={appendQtyLot}
        type="number"
        // label={"Amo."}
        variant="outlined"
        fullWidth
        style={{ width: 80 }}
      />
    );
  };
  const RenderLotSup = ({ row: { original } }) => {
    // We need to keep and update the state of the cell normally
    const [value, setValue] = React.useState(
      state.lotSup[_.findIndex(state.poSelect, original)]
    );
    const onChange = (e) => {
      setValue(e.target.value);
    };
    const appendLotSup = () => {
      // console.log(_.findIndex(state.poSelect, original));
      let currentIndex = _.findIndex(state.poSelect, original);
      let newAmount = state.lotSup;
      newAmount[currentIndex] = value;
      setState((state) => ({ ...state, lotSup: newAmount }));
    };
    return (
      <TextField
        size="small"
        inputProps={{ style: { fontSize: 12 } }} // font size of input text
        disabled={_.findIndex(state.poSelect, original) !== -1 ? false : true}
        value={value}
        onChange={onChange}
        onBlur={appendLotSup}
        // label={"Amo."}
        variant="outlined"
        fullWidth
        style={{ width: 80 }}
      />
    );
  };
  const EditClick = ({ row: { original } }) => {
    return (
      <IconButton
        aria-label="edit-lot"
        disabled={_.findIndex(state.poSelect, original) !== -1 ? false : true}
        onClick={() => {
          // check if both qty and lot are exist
          let currentIndex = _.indexOf(state.poSelect, original);
          if (state.poSelect[currentIndex].PIECES_PER_KANBAN) {
            if (!state.delAmount[currentIndex] || !state.qtyLot[currentIndex]) {
              return enqueueSnackbar('กรุณากรอกข้อมูล delivery QTY.', {
                varain: 'error',
                anchorOrigin: {
                  vertical: 'bottom',
                  horizontal: 'right',
                },
              });
            }
          }
          setState((state) => ({
            ...state,
            open: true,
            selectedLot: original,
          }));
        }}
        color="primary"
      >
        <EditIcon />
      </IconButton>
    );
  };
  const columns = React.useMemo(() => [
    {
      Header: 'PO No.',
      accessor: 'PO_NUMBER',
    },
    {
      Header: 'Rel.',
      accessor: 'RELEASE_NUM',
      width: 50,
    },
    {
      Header: 'Line No.',
      accessor: 'LINE_NUM',
      width: 50,
    },
    {
      Header: 'Ship No.',
      accessor: 'SHIPMENT_NUM',
      width: 50,
    },
    {
      Header: 'Due Date',
      accessor: 'DUE_DATE',
      width: 150,
    },
    {
      Header: 'Item Code',
      accessor: 'ITEM_CODE',
      width: 150,
    },
    {
      Header: 'Description',
      // accessor: 'DESCRIPTION',
      Cell: ({ row: { original } }) => {
        return (
          <div style={{ width: 200, fontSize: 12, whiteSpace: 'wrap' }}>
            {original.DESCRIPTION}
          </div>
        );
      },
      width: 150,
    },
    {
      Header: 'QTY/ PACK',
      accessor: 'PIECES_PER_KANBAN',
      width: 70,
    },
    {
      Header: 'QTY ORD.',
      accessor: 'QUANTITY',
      width: 70,
    },
    {
      Header: 'QTY TRAN.',
      accessor: 'transit',
      width: 70,
    },
    {
      Header: 'QTY REC.',
      accessor: 'QUANTITY_RECEIVED',
      width: 70,
    },
    {
      Header: 'QTY REM.',
      accessor: 'QUANTITY_REMAIN',
      width: 70,
    },
    {
      Header: 'Unit Price',
      accessor: 'UNIT_PRICE',
      width: 70,
    },
    {
      Header: 'UOM',
      accessor: 'UOM',
      width: 60,
    },
  ]);
  // console.log(state);
  if (getUserType() !== 'admin') {
    columns.unshift({
      id: 'select',
      Header: '',
      Cell: ({ row: { original } }) => {
        if (
          original.transit < original.QUANTITY_REMAIN &&
          parseInt(original.QUANTITY_REMAIN) > 0
        ) {
          // console.log(state.poSelect, _.findIndex(state.poSelect, original));
          return (
            <Checkbox
              style={{ padding: 0 }}
              checked={
                _.findIndex(state.poSelect, original) !== -1 ? true : false
              }
              onChange={() => {
                return appendSelect(original);
              }}
              inputProps={{ 'aria-label': 'primary checkbox' }}
            />
          );
        } else {
          return null;
        }
      },
      width: 50,
    });
    columns.push({
      Header: 'QTY Del.',
      Cell: RenderDelAmount,
    });
    columns.push({
      Header: '# Packs',
      Cell: RenderQtyLot,
    });
    columns.push({
      Header: 'Set up Lot',
      Cell: EditClick,
    });
    columns.push({
      Header: 'Lot Sup',
      Cell: RenderLotSup,
    });
  }
  // We'll start our table without any data
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [serverData, setServerData] = React.useState(remoteData);
  const handleCloseHere = () => {
    setState((state) => ({ ...state, open: false }));
  };
  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setLoading(true);

      // We'll even set a delay to simulate a server here
      setTimeout(() => {
        // Only update the data if this is the latest fetch
        if (fetchId === fetchIdRef.current) {
          const startRow = pageSize * pageIndex;
          const endRow = startRow + pageSize;
          setData(serverData.slice(startRow, endRow));

          // Your server could send back total page count.
          // For now we'll just fake it, too
          setPageCount(serverData.length);

          setLoading(false);
        }
      }, 1000);
    },
    [serverData]
  );

  useEffect(() => {
    setServerData(remoteData);
  }, [remoteData]);

  if (serverData.length !== 0) {
    return (
      <div style={{ margin: 10 }}>
        <Dialog open={state.open} handleClose={handleCloseHere} />
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
        />
      </div>
    );
  } else {
    return null;
  }
}

export default App;
