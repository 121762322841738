import * as React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';
import { updateUserAlert } from '../utils/apis';

export default function FormDialog({ open, setOpen, data }) {
  const [alertEmail, setAlertEmail] = React.useState(data.email_alert);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = async () => {
    if (!alertEmail) {
      return enqueueSnackbar('ไม่พบข้อมูล', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
    setOpen(false);
    let update = await updateUserAlert(data.id, {
      email_alert: alertEmail,
    });
    console.log('update', update);
    if (update) {
      return enqueueSnackbar('บันทึกการเปลี่ยนแปลงเรียบร้อยแล้ว', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };
  return (
    <div>
      <Dialog open={open} onClose={handleClose}>
        <DialogTitle>Subscribe</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please make change to email alert list (i.e.
            xxx@yyy.com,aaa@bbb.com)
          </DialogContentText>
          <TextField
            type="textarea"
            autoFocus
            margin="dense"
            id="name"
            label="Alert Email"
            fullWidth
            variant="standard"
            value={alertEmail}
            onChange={(e) => setAlertEmail(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSubmit}>Submit</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}
