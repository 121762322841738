import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import Button from '@material-ui/core/Button';
import Table from './ForecastMTable';
import ReactLoading from 'react-loading';
import { DataContext } from '../../ContextData';
import { useSnackbar } from 'notistack';
import { DatePicker } from '@material-ui/pickers';
import Loading from '../../components/Loading';
import {
  forecast,
  getForecast,
  getAllUsers,
  getAllGroupEmail,
  sendingEmail,
} from '../../utils/apis';
import moment from 'moment';
import emailTemplate from '../../emails';
import {
  getUserType,
  vendor_id,
  company_name,
  username,
  alert_email,
  company_id,
  user_email,
} from '../../utils';
import Toggle from './Toggle';
import _ from 'lodash';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const SweetAlert = withReactContent(Swal);

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    marginLeft: 10,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
  input: {
    display: 'none',
  },
}));
export default function PO() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [state] = React.useContext(DataContext);
  const [company, setCompany] = React.useState('');
  const [companies, setCompanies] = React.useState(null);
  const [vendor, setVendor] = React.useState('');
  const [selectedDate, setSelectedDate] = React.useState(new Date());
  const [searchDate, setSearchDate] = React.useState(null);
  const [file, setFile] = React.useState(null);
  const [fileName, setFileName] = React.useState(null);
  const [loading, setLoading] = React.useState(false);
  const [isLoading, setIsLoading] = React.useState(false);
  const [serverData, setServerData] = React.useState(null);
  const [emailGroups, setEmailGroups] = React.useState([]);
  const [resultEmails, setResultEmails] = React.useState([]);
  const [selectedEmails, setSelectedEmails] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [toggle, setToggle] = React.useState(false);
  // console.log(state);

  const getAllGroup = async () => {
    let data = await getAllGroupEmail();
    setEmailGroups(data);
  };
  const handleSearch = async (e) => {
    console.log(emailGroups, e);
    let resultDrops =
      searchTerm === ''
        ? ''
        : emailGroups.filter((group) =>
            group.name
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(searchTerm.toLowerCase().replace(/\s+/g, ''))
          );
    if (resultDrops.length == 0) {
      setSelectedEmails('');
    }
    if (
      selectedEmails &&
      resultDrops.length !== 0 &&
      resultDrops[0].name === selectedEmails.name
    ) {
      setResultEmails([]);
    } else {
      setResultEmails(resultDrops);
    }
  };

  const submit = async () => {
    console.log(selectedEmails);
    if (!file)
      return enqueueSnackbar('Please select file', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    if (!selectedEmails)
      return enqueueSnackbar('Please select mail group', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });

    // check if all file not correct
    // check file name and selected group
    if (
      _.some(file, (fil) => {
        return fil.name.split('-')[0] !== selectedEmails.name.split('-')[1];
      })
    ) {
      return enqueueSnackbar(`File name and email group is not same.`, {
        variant: 'info',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 300000,
      });
    }

    let promiseFiles = _.map(
      file,
      async (item, index) => await submitSingle(item, index)
    );
    Promise.all(promiseFiles);
  };

  const submitSingle = async (item, index) => {
    console.log('submit');
    console.log('processing file', fileName[index]);

    // check if finle name already exist

    let nameSplit;
    if (fileName[index]) {
      nameSplit = fileName[index].split('-');
      if (nameSplit.length !== 4) {
        return enqueueSnackbar(
          'File Name format is not correct (i.e. XXX-MM-YYYY-VERSION.XLS)',
          {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          }
        );
      }
    }
    // setLoading(true);
    // need to follow TO change group email
    let formData = new FormData();
    formData.append('files.forecast', item);
    // let supplierEmail = await getSupplierEmail(nameSplit[0]);
    // console.log('alert email to: ', supplierEmail);

    // check duplicated submit of each file
    let existingForcast = await getForecast(
      `?forecast_date=${
        nameSplit[1] + '-' + nameSplit[2].split('.')[0]
      }&vendor_id=${selectedEmails.vendor_id}&company=${company_name()}`
    );
    let exist = _.filter(
      existingForcast,
      (item) => item.forecast.name + item.forecast.ext == fileName[index]
    );
    console.log(existingForcast, fileName[index], exist);
    if (exist.length !== 0) {
      return enqueueSnackbar(
        `Upload error for ${fileName[index]}, file already exist`,
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        }
      );
    }

    // add sending email status
    formData.append(
      'data',
      JSON.stringify({
        company: company_name(),
        vendor: nameSplit[0], //vendor.name,
        company_id: company_id(),
        vendor_id: selectedEmails.vendor_id, //vendor.id,
        forecast_date: nameSplit[1] + '-' + nameSplit[2].split('.')[0], //new moment(selectedDate).format('MM-yyyy'),
        email_status: 1,
        uploader: username(),
        receiver_email: selectedEmails.email_list, // add receiver_email for filter
      })
    );

    // sending email
    if (selectedEmails.email_list) {
      const rawResponse = await forecast(formData);
      const content = await rawResponse.json();
      setLoading(false);

      if (rawResponse.ok) {
        setIsLoading(true);
        let emailResult = await sendingEmail({
          to: selectedEmails.email_list,
          cc: alert_email(),
          subject: `Automatic Email: New Forecast for ${content.forecast_date}`,
          templateReferenceId: 4, // template number
          data: {
            url: 'https://smp.aapico.com/#/forecast',
            factory: company_name(),
            forecast_date: content.forecast_date,
          },
        });
        retrieveForecast();
        setLoading(false);
        console.log(emailResult);
        if (emailResult.status === 200) {
          setIsLoading(false);
          setSelectedEmails('');
          setSearchTerm('');
          setFile();
          setFileName('');
          return enqueueSnackbar(
            `Upload success, Email Sent to ${selectedEmails.email_list}`,
            {
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'right',
              },
            }
          );
        } else {
          setIsLoading(false);
          setSelectedEmails('');
          setSearchTerm('');
          setFile();
          setFileName('');
          return enqueueSnackbar('Upload error', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        }
      }
    } else {
      // SweetAlert.fire('Email Fail!', 'Sent Fail.', 'error');
      setLoading(false);
      return enqueueSnackbar('Upload error, no email alert', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  const handleSearchDateChange = (date) => {
    setSearchDate(date);
  };
  // const handleDateChange = (date) => {
  //   setSelectedDate(date);
  // };
  const onChange = (e) => {
    switch (e.target.name) {
      // Updated this
      case 'selectedFile':
        if (e.target.files.length > 0) {
          // Accessed .name from file
          let fileNameArray = _.map(e.target.files, (item) => item.name);
          setFile(e.target.files);
          setFileName(fileNameArray);
        }
        break;
      default:
        return null;
    }
  };
  const retrieveForecast = async () => {
    setLoading(true);
    let result = [];
    let resultAll = [];
    let resultVendors = [];

    if (getUserType() === 'vendor') {
      resultAll = await getForecast(`?_limit=-1&_sort=createdAt:DESC`); // remove vendor filter vendor_id=${vendor_id()}

      resultVendors = _.filter(resultAll, (each) => {
        if (each.vendor === 'AA') {
          // check if it belong to user
          return true;
        } else {
          console.log(each.vendor_id, vendor_id());
          return each.vendor_id == vendor_id();
        }
      });

      console.log(resultVendors);

      // filter ouput by email if result hase email
      result = _.filter(resultVendors, (each) => {
        if (each.receiver_email) {
          // check if it belong to user
          return each.receiver_email.includes(user_email());
        } else {
          return true;
        }
      });
    } else {
      result = await getForecast(
        `?company_id=${company_id()}&_limit=-1&_sort=createdAt:DESC`
      );
    }
    //(getUserType() === 'store' || getUserType() === 'admin')
    // else {
    //   result = await getForecast(`?_sort=createdAt:DESC`);
    // }
    // console.log(result);
    setLoading(false);
    setServerData(result);
  };
  const submitSearch = async () => {
    setLoading(true);
    // check if vedor type
    let result;
    console.log(getUserType());
    if (getUserType() === 'vendor') {
      result = await getForecast(
        `?_limit=-1&_sort=createdAt:desc${
          searchDate
            ? '&forecast_date=' + new moment(searchDate).format('MM-yyyy')
            : ''
        }&vendor_id=${vendor_id()}${toggle ? '&down_count=0' : ''}`
      );
    } else {
      result = await getForecast(
        `${
          searchDate
            ? '?_limit=-1&forecast_date=' +
              new moment(searchDate).format('MM-yyyy')
            : '?_limit=-1&_sort=createdAt:desc'
        }${toggle ? '&down_count=0' : ''}&company_id=${company_id()}`
      );
    }
    console.log(result);
    setLoading(false);
    setServerData(result);
  };

  const handleToggle = (e) => {
    setToggle(e);
  };

  const getCompanies = async () => {
    let companies_list = await getAllUsers();
    // console.log(companies_list);
    let filter_companies = companies_list.map((item) => ({
      name: item.username.substring(0, item.username.length - 2),
      id: item.vendor_id,
    }));
    // console.log(filter_companies);
    setCompanies(filter_companies);
  };

  useEffect(() => {
    if (!companies) getCompanies();
    if (!serverData) retrieveForecast();
    getAllGroup();
  }, []);

  React.useEffect(() => {
    // perform
    handleSearch();
  }, [searchTerm]);
  React.useEffect(() => {
    // perform
    submitSearch();
  }, [toggle]);

  return (
    <div className={classes.root}>
      <br />

      {isLoading && <Loading />}
      <Paper className={classes.paper}>
        <Grid container spacing={1} alignItems={'center'}>
          <Grid item xs={2}>
            <DatePicker
              size="small"
              defaultValue={null}
              views={['year', 'month']}
              label="Year and Month"
              value={searchDate}
              onChange={handleSearchDateChange}
              inputVariant="outlined"
              clearable={true}
            />
          </Grid>
          <Grid item xs={2} style={{ display: 'flex' }}>
            <Button variant="contained" color="primary" onClick={submitSearch}>
              Search
            </Button>
          </Grid>
          <Grid item xs={2}>
            <Toggle handleToggle={handleToggle} />
          </Grid>
          {/* <Grid item xs={1}>
          </Grid> */}
          {(getUserType() === 'admin' || getUserType() === 'store') &&
          companies ? (
            <>
              <Grid item xs={6}>
                <Grid container justify={'flex-end'} spacing={1}>
                  <Grid item>
                    <div className="flex w-auto">
                      <div className="flex flex-col w-64">
                        <input
                          type="text"
                          name="Group mail"
                          id="groupMail"
                          value={searchTerm}
                          placeholder="Group Mail"
                          className=" w-64 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                          required
                          onChange={async (e) => setSearchTerm(e.target.value)}
                        ></input>
                        {resultEmails.length !== 0 && (
                          <div className="flex absolute z-50 w-84 max-h-72 rounded shadow bg-white overflow-hidden peer-checked:flex flex-col mt-12 border border-gray-200">
                            {resultEmails.map((each) => (
                              <div class="cursor-pointer group">
                                <button
                                  onClick={() => {
                                    setSearchTerm(each.name);
                                    setSelectedEmails(each);
                                    setResultEmails([]);
                                  }}
                                  className="block w-full p-2 border-transparent border-l-4 hover:border-blue-600 hover:bg-gray-100 text-left"
                                >
                                  {each.name} [{each.description}]
                                </button>
                              </div>
                            ))}
                          </div>
                        )}
                        {/* input email group */}
                        <div className="flex w-full mx-2 space-x-2">
                          {selectedEmails &&
                            selectedEmails.email_list
                              .split(',')
                              .map((email) => {
                                return (
                                  <span className="flex p-2 bg-indigo-500 mt-5 rounded-md break-normal text-white">
                                    {email}
                                  </span>
                                );
                              })}
                        </div>
                      </div>
                    </div>
                  </Grid>
                  <Grid item>
                    <input
                      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
                      className={classes.input}
                      id="contained-button-file"
                      multiple={true}
                      type="file"
                      name="selectedFile"
                      onChange={(event) => onChange(event)}
                    />
                    <label htmlFor="contained-button-file">
                      <Button
                        variant="contained"
                        color="primary"
                        component="span"
                      >
                        {fileName ? '[' + fileName.join(', ') + ']' : 'File'}
                      </Button>
                    </label>
                  </Grid>
                  <Grid item>
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={submit}
                    >
                      Submit
                    </Button>
                  </Grid>
                </Grid>
              </Grid>
            </>
          ) : null}
        </Grid>
      </Paper>
      <br />
      <Grid item xs={12}>
        {loading || !serverData ? (
          <ReactLoading type={'bars'} color={'#444'} className="preloader" />
        ) : (
          <Table
            data={serverData}
            reload={() => retrieveForecast()}
            loading={loading}
          />
        )}
      </Grid>
    </div>
  );
}
