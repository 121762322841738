import settings from '../config';
import { isMobile } from 'react-device-detect';
import jwt_decode from 'jwt-decode';
const TOKEN_KEY = 'jwt';

export const login = (account, props, enqueueSnackbar) => {
  console.log(account);
  // call api if success then save crdential
  fetch(`${settings.server}/auth/local`, {
    method: 'POST',
    body: JSON.stringify(account),
    headers: new Headers({ 'Content-Type': 'application/json' }),
  }).then(async (res) => {
    let data = await res.json();
    if (data.statusCode && data.statusCode !== 200) {
      return alert('login fails');
    } else {
      localStorage.setItem(TOKEN_KEY, data.jwt);
      console.log(data);
      enqueueSnackbar('Signin successfully!', {
        autoHideDuration: 3000,
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      localStorage.setItem('USER_NAME', data.user.username);
      localStorage.setItem('USER_ID', data.user.id);
      localStorage.setItem('USER_TYPE', data.user.user_type);
      localStorage.setItem('VENDOR_NAME', data.user.vendor_name);
      localStorage.setItem('COMPANY_NAME', data.user.company_name);
      localStorage.setItem('VENDOR_ID', data.user.vendor_id);
      localStorage.setItem('COMPANY_ID', data.user.company_id);
      localStorage.setItem('COMPANY_OPER_ID', data.user.company_id);
      localStorage.setItem('EMAIL', data.user.email);
      localStorage.setItem('EMAIL_ALERT', data.user.email_alert);
    }
    // if(getUserType() === "admin") {
    //     return props.history.push("/table");
    // }
    console.log(props);
    // mobile after login
    // check if accounting redirect to accouting
    if (data.user.user_type.includes('account')) {
      return props.history.push('/invoice-admin');
    }
    return props.history.push('#');
  });
};

export const loginScanner = (account, props, enqueueSnackbar) => {
  console.log(account);
  // call api if success then save crdential
  fetch(`${settings.scanner}/auth/local`, {
    method: 'POST',
    body: JSON.stringify(account),
    headers: new Headers({ 'Content-Type': 'application/json' }),
  }).then(async (res) => {
    let data = await res.json();
    if (data.statusCode && data.statusCode !== 200) {
      alert('login fails');
    } else {
      localStorage.setItem(TOKEN_KEY, data.jwt);
      console.log(data);
      enqueueSnackbar('Signin successfully!', {
        autoHideDuration: 3000,
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
      localStorage.setItem('USER_NAME', data.user.username);
      localStorage.setItem('USER_ID', data.user.id);
      localStorage.setItem('COMPANY_NAME', data.user.factory);
      localStorage.setItem('STORE_NAME', data.user.store_name);
      localStorage.setItem('COMPANY_ID', data.user.company_id);
    }
    // if(getUserType() === "admin") {
    //     return props.history.push("/table");
    // }
    console.log(props);
    return props.history.push('/');
  });
};

export const resetPassword = (account, code, props, enqueueSnackbar) => {
  console.log(account);
  // call api if success then save crdential
  fetch(`${settings.server}/auth/reset-password`, {
    method: 'POST',
    body: JSON.stringify({ ...account, code }),
    headers: new Headers({ 'Content-Type': 'application/json' }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.statusCode && res.statusCode !== 200) {
        enqueueSnackbar('Reset fails');
        console.log('reset fails');
      } else {
        console.log('reset password successful');
        enqueueSnackbar('Reset password successful', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        if (isMobile) {
          return props.history.push('./scanner');
        }
        props.history.push('./signin');
      }
      console.log(res);
    });
};

export const newUser = (account, enqueueSnackbar) => {
  console.log(account);
  // call api if success then save crdential
  return fetch(`${settings.server}/users`, {
    method: 'POST',
    body: JSON.stringify({
      ...account,
      vendor_name: account.vendor.name,
      vendor_id: account.vendor.id,
    }),
    headers: new Headers({
      'Content-Type': 'application/json',
      Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.statusCode && res.statusCode !== 200) {
        enqueueSnackbar('Error: ' + res.error, 'error');
        return false;
      } else {
        console.log(res);
        enqueueSnackbar('User created successful', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        return true;
      }
    });
};
export const changePasswordScanner = (account, enqueueSnackbar) => {
  console.log(account);
  // call api if success then save crdential
  fetch(`${settings.scanner}/users/${localStorage.getItem('USER_ID')}`, {
    method: 'PUT',
    body: JSON.stringify({ ...account }),
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      'Content-Type': 'application/json',
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.statusCode && res.statusCode !== 200) {
        enqueueSnackbar('User update fails', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        console.log('User update fails');
      } else {
        console.log('User update successful');
        enqueueSnackbar('User update successfully!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    });
};

export const changePassword = (account, enqueueSnackbar) => {
  console.log(account);
  // call api if success then save crdential
  fetch(`${settings.server}/users/${localStorage.getItem('USER_ID')}`, {
    method: 'PUT',
    body: JSON.stringify({ ...account }),
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      'Content-Type': 'application/json',
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.statusCode && res.statusCode !== 200) {
        enqueueSnackbar('User update fails', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        console.log('User update fails');
      } else {
        console.log('User update successful');
        enqueueSnackbar('User update successfully!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    });
};

export const updateUser = (id, account, enqueueSnackbar) => {
  console.log(account);
  // call api if success then save crdential
  fetch(`${settings.server}/users/${id}`, {
    method: 'PUT',
    body: JSON.stringify({ ...account }),
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      'Content-Type': 'application/json',
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.statusCode && res.statusCode !== 200) {
        enqueueSnackbar('User update fails', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        console.log('User update fails');
      } else {
        console.log('User update successful');
        enqueueSnackbar('User update successfully!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    });
};

export const deleteUser = (id, enqueueSnackbar) => {
  // call api if success then save crdential
  return fetch(`${settings.server}/users/${id}`, {
    method: 'DELETE',
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      'Content-Type': 'application/json',
    }),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.statusCode && res.statusCode !== 200) {
        enqueueSnackbar('Delete fails', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        console.log('Delete fails');
      } else {
        console.log('Delete successful');
        enqueueSnackbar('Delete successfully!', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
    });
};

export const logout = () => {
  localStorage.removeItem(TOKEN_KEY);
};

export const isLogin = () => {
  let token = localStorage.getItem(TOKEN_KEY);
  // let email_alert = !localStorage.getItem('EMAIL_ALERT');
  // console.log(email_alert);

  // TODO Enable later
  // if (email_alert){
  //     alert('Please login again, no alert email assigned');
  //     return false
  // }

  // check expire token
  if (token) {
    let decoded = jwt_decode(token);
    var current_time = new Date().getTime() / 1000;
    if (current_time > decoded.exp) {
      /* expired */
      alert('Session expires');
      localStorage.removeItem(TOKEN_KEY);
      return false;
    }
    return true;
  } else {
    return false;
  }
};

export const header = () => {
  return {
    Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
  };
};

export const getUserType = () => {
  return localStorage.getItem('USER_TYPE');
};
export const userId = () => {
  return localStorage.getItem('USER_ID');
};
export const vendor_id = () => {
  return localStorage.getItem('VENDOR_ID');
};
export const company_id = () => {
  return localStorage.getItem('COMPANY_ID');
};
export const company_oper_Id = () => {
  return localStorage.getItem('COMPANY_OPER_ID');
};
export const vendor_name = () => {
  return localStorage.getItem('VENDOR_NAME');
};
export const username = () => {
  return localStorage.getItem('USER_NAME');
};
export const company_name = () => {
  return localStorage.getItem('COMPANY_NAME');
};
export const user_email = () => {
  // TODO change to email_alert
  return localStorage.getItem('EMAIL');
};
export const alert_email = () => {
  // TODO change to email_alert
  return localStorage.getItem('EMAIL_ALERT');
};
