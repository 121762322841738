import React from 'react';
import styled from 'styled-components';
import cx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Toolbar from '@material-ui/core/Toolbar';
import Joyride, { CallBackProps, STATUS, Step } from 'react-joyride';
import {
  Root,
  getHeader,
  getDrawerSidebar,
  getSidebarTrigger,
  getSidebarContent,
  getCollapseBtn,
  getContent,
  getFooter,
  getFixedScheme,
} from '@mui-treasury/layout';
import { HeaderMockUp, FooterMockUp } from '../../layout';
import { TextSidebar } from '../../sidebars';
import { Redirect, useLocation } from 'react-router-dom';
import settings from '../../config';
import { isLogin } from '../../utils';
import { useSnackbar } from 'notistack';
import { isMobile } from 'react-device-detect';
import { Button, Typography } from '@material-ui/core';
import { mainPage } from '../../Manual';
import { DataContext } from '../../ContextData';

const Header = getHeader(styled);
const DrawerSidebar = getDrawerSidebar(styled);
const SidebarTrigger = getSidebarTrigger(styled);
const SidebarContent = getSidebarContent(styled);
const CollapseBtn = getCollapseBtn(styled);
const Content = getContent(styled);
const Footer = getFooter(styled);

const fixedScheme = getFixedScheme();

const useStyles = makeStyles(() => ({
  header: {
    backgroundColor: '#fff',
    borderBottom: '1px solid hsl(210, 32%, 93%)',
  },
  collapseBtn: {
    color: '#fff',
    minWidth: 0,
    width: 40,
    borderRadius: '50%',
    border: 'none',
    backgroundColor: 'rgba(0,0,0,0.24)',
    margin: '0 auto 16px',
    '&:hover': {
      backgroundColor: 'rgba(0,0,0,0.38)',
    },
  },
  sidebar: {
    backgroundColor: '#4065E0',
    border: 'none',
  },
  content: {
    backgroundColor: '#f9f9f9',
  },
}));

const CustomStylesDemo = (props) => {
  const location = useLocation();
  const [state, setState] = React.useContext(DataContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [steps, setSteps] = React.useState(mainPage);
  const [stepIndex, setStepIndex] = React.useState(0);
  const validateJWT = async () => {
    let token = localStorage.getItem('jwt');
    await fetch(`${isMobile ? settings.scanner : settings.server}/users/me`, {
      method: 'GET',
      headers: new Headers({
        Authorization: `Bearer ${token}`,
        'Content-Type': 'application/json',
      }),
    })
      .then((res) => res.json())
      .then((res) => {
        if (res.statusCode && res.statusCode !== 200) {
          localStorage.removeItem('jwt');
          enqueueSnackbar('User have been blocked', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
          return props.children.props.history.push('/signin');
          // return setUserBlock(true);
        }
      });
  };

  const handleJoyrideCallback = (data) => {
    const { status, type } = data;
    const finishedStatuses = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setState((state) => ({ ...state, startTour: false }));
    }
  };

  React.useLayoutEffect(() => {
    if (isLogin()) validateJWT();
  });

  React.useEffect(() => {
    if (location.pathname === '/') {
      setSteps(mainPage);
    } else {
      setSteps([]);
    }
  }, []);

  const styles = useStyles();
  return (
    <Root scheme={fixedScheme}>
      <Joyride
        callback={handleJoyrideCallback}
        run={state.startTour}
        spotlightClicks={true}
        showProgress={true}
        steps={steps}
        continuous
        styles={{
          options: {
            zIndex: 1000,
          },
        }}
      />
      <CssBaseline />
      <Header className={styles.header}>
        <Toolbar>
          {/* {props.children.props.location.pathname !== '/scanner' ? ( */}
          <SidebarTrigger sidebarId={'primarySidebar'} />
          {/* ) : null} */}
          <HeaderMockUp />
          {/* <div className="ml-3">
            <Button
              className="user-profile"
              onClick={() =>
                setState((state) => ({ ...state, startTour: true }))
              }
            >
              Tour
            </Button>
          </div> */}
        </Toolbar>
      </Header>
      {/* {!isMobile && ( */}
      <DrawerSidebar
        sidebarId={'primarySidebar'}
        PaperProps={{ className: styles.sidebar }}
      >
        <SidebarContent>
          <TextSidebar />
        </SidebarContent>
        <CollapseBtn className={cx(styles.collapseBtn)} />
        <Typography
          align="center"
          style={{ fontFamily: 'Bai JamJuree', color: '#11fedc' }}
        >
          {state.version}
          {/* v.1.1.5 */}
        </Typography>
        {/* 20220211 */}
      </DrawerSidebar>
      {/* )} */}
      <Content className={styles.content}>
        {/* <ContentMockUp /> */}
        {props.children}
      </Content>
      {/* <Footer>
        <FooterMockUp />
      </Footer> */}
    </Root>
  );
};

// add 0 package
export default CustomStylesDemo;
