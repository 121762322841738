import React, { createContext, useState, useEffect } from 'react';
import { isLogin } from './utils';
import { Redirect } from 'react-router-dom';

export const DataContextMobile = createContext([{}, () => {}]);

const ContextProvider = (props) => {
  // TODO qrCodeMain is [] & qrCodeItem is {}
  const [state, setState] = useState({
    companies: null,
    vendors: null,
    sites: [{ VENDOR_SITE_CODE: '', ADDR: null }],
    userForm: null,
    poSelect: [],
    delAmount: [],
    lotSup: [],
    qtyLot: [],
    lotDetails: [],
    heatDetails: [],
    open: false,
    selectedLot: null,
    invoiceNo: null,
    invoiceDate: null,
    isInvoice: true,
    site: null,
    deliveryDate: null,
    currentTransits: null,
    searchTerm: '',
    selectedValue: 'all',
    qrCodeMain: [],
    receiveNo: [],
    qrCodeMainChange: '',
    qrCodeItem: {},
    warning: null,
    deleted: false,
    employeeID: null,
    items: [],
  });

  if (!isLogin) {
    return <Redirect to="/signin" />;
  }

  return (
    <DataContextMobile.Provider value={[state, setState]}>
      {props.children}
    </DataContextMobile.Provider>
  );
};

export default ContextProvider;
