import React from 'react';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import DeliveryNote from './DeliveryPSC';
import { DataContext } from '../../ContextData';
import { createDelivery, transits } from '../../utils/apis';
import { getUserType, username, vendor_id, vendor_name } from '../../utils';
import _ from 'lodash';
import ReactLoading from 'react-loading';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
const SweetAlert = withReactContent(Swal);

export default function AlertDialog(props) {
  const [open] = React.useState(props.print);
  const [state, setState] = React.useContext(DataContext);
  const [companyName, setCompanyName] = React.useState(null);
  const [disable, setDisable] = React.useState(false);
  // console.log(!props.data);
  // console.log(props);
  const getCompanyName = async () => {
    if (!props.data) {
      let result = await _.filter(state.companies, {
        id: state.poSelect[0].INV_ORGID,
      });
      setCompanyName(result[0].name);
    } else {
      setCompanyName(props.data.company);
    }
  };

  // console.log(props);
  const CallSweetAlert = () => {
    // are you sure???
    console.log(props);
    if (props.from !== 'status') {
      SweetAlert.fire({
        title: 'คุณแน่ใจไหม?',
        text: 'การยกเลิกจะไม่สามารถนำ QR Code ใบนี้ใช้ในการส่งของได้',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'ตกลงจะยกเลิก',
        cancelButtonText: 'ย้อนกลับ',
      }).then((result) => {
        if (result.value) {
          SweetAlert.fire('ยกเลิก!', 'ยกเลิกการจัดส่งแล้ว.', 'error');
          props.handleClose();
          // For more information about handling dismissals please visit
          // https://sweetalert2.github.io/#handling-dismissals
        } else if (result.dismiss === SweetAlert.DismissReason.cancel) {
          SweetAlert.fire(
            'ไม่มีการยกเลิก',
            'กรุณาคลิกยืนยันเพื่อบันทึกการจัดส่ง',
            'success'
          );
        }
      });
    } else {
      props.handleClose();
    }
  };

  const handleConfirm = async () => {
    setDisable(true);
    let check_transit_qry = await props.check_transit_job();
    if (check_transit_qry) {
      return SweetAlert.fire(
        'ตรวจบพการทำรายการซ้ำ',
        'โปรดตวจสอบที่หน้า Delivery',
        'error'
      );
    }
    // save delivery to database
    // console.log(state);
    let toSubmit = { ...state };
    delete toSubmit.companies;
    delete toSubmit.sites;
    delete toSubmit.userForm;
    delete toSubmit.selectedLot;
    delete toSubmit.vendors;
    delete toSubmit.currentTransits;
    delete toSubmit.qrCodeItem;
    delete toSubmit.qrCodeMain;
    delete toSubmit.searchTerm;
    delete toSubmit.searchValue;
    delete toSubmit.open;
    delete toSubmit.qrCodeMainChange;
    delete toSubmit.deleted;
    // TODO remove demo
    await createDelivery({
      user: username(),
      details: { ...toSubmit, vendorName: vendor_name() },
      status: getUserType() !== 'demo' ? 'transit' : 'transit_demo',
      vendor_id: vendor_id(),
      company: companyName,
    });
    // enable button back
    setDisable(false);

    getTransits();
    props.history.push('/table');
  };

  // recalculate transit
  const getTransits = async () => {
    await transits().then(async (res) => {
      let result = await res.json();
      setState({ ...state, currentTransits: result || [] });
    });
  };

  React.useEffect(() => {
    if (!companyName) getCompanyName();
  });
  React.useEffect(() => {}, [props.print]);
  // console.log(state);

  return (
    <div>
      <Grid container justify={'flex-end'}>
        <Grid item xs={12}>
          {!companyName ? (
            <ReactLoading
              type={'bubbles'}
              color={'#444'}
              className="preloader"
            />
          ) : (
            <DeliveryNote
              vendor_name={
                props.from === 'status'
                  ? props.data.details.vendorName
                  : vendor_name()
              }
              vendor_id={
                props.from === 'status' ? props.data.vendor_id : vendor_id()
              }
              state={props.from === 'status' ? props.data.details : state}
              issueDate={props.from === 'status' ? props.data.createdAt : null}
              company={companyName}
              print={props.from === 'status' ? true : false}
              id={props.from === 'status' ? props.data.id : ''}
            />
          )}
        </Grid>
        <Grid item>
          <Grid container justify={'flex-end'} spacing={2}>
            <Grid
              item
              style={{
                position: 'fixed',
                bottom: 0,
                margin: 10,
                marginRight: 30,
              }}
            >
              {props.from === 'status' ? null : (
                <Button
                  style={{ marginRight: 10 }}
                  onClick={handleConfirm}
                  autoFocus
                  variant="contained"
                  color="primary"
                  disabled={disable}
                >
                  {disable ? 'กำลังส่ง...' : 'ยืนยัน'}
                </Button>
              )}
              <Button
                onClick={CallSweetAlert}
                variant="contained"
                color="secondary"
              >
                {props.from === 'status' ? 'ปิด' : 'ยกเลิก'}
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
}
