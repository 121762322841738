import React, { useState } from 'react';

export default function Toggle({ handleToggle }) {
  const [enabled, setEnabled] = useState(false);

  React.useEffect(() => {
    handleToggle(enabled);
  }, [enabled]);
  return (
    <div className={`relative flex flex-col items-center justify-center overflow-hidden h-10 rounded-md ${!enabled ? 'border-2 border-gray-600 bg-gray-200' : 'border-2 border-green-600 bg-green-200'}`}>
      <div className="flex p-2">
        <label class="inline-flex relative items-center mr-5 cursor-pointer font-semibold">
          <input
            type="checkbox"
            class="w-5 h-5 accent-pink-500 mr-3"
            checked={enabled}
            onChange={() => setEnabled(!enabled)}
          />{' '}
          {enabled ? 'Show Not Confirm only' : 'Show All'}
        </label>
      </div>
    </div>
  );
}
