const companiesList = [
  {
    INVENTORY_ORG_CODE: 'AAA',
    INVENTORY_ORG_ID: '310',
    OPERATING_UNIT_ORG_ID: '309',
    COMPANY_NAME: 'Aapico Amata Legal',
    NAME: 'AA - Aapico Amata',
  },
  {
    INVENTORY_ORG_CODE: 'AF1',
    INVENTORY_ORG_ID: '199',
    OPERATING_UNIT_ORG_ID: '197',
    COMPANY_NAME: 'Aapico Forging Legal',
    NAME: 'Aapico Forging Production',
  },
  {
    INVENTORY_ORG_CODE: 'AF2',
    INVENTORY_ORG_ID: '200',
    OPERATING_UNIT_ORG_ID: '197',
    COMPANY_NAME: 'Aapico Forging Legal',
    NAME: 'Aapico Forging ',
  },
  {
    INVENTORY_ORG_CODE: 'AFD',
    INVENTORY_ORG_ID: '201',
    OPERATING_UNIT_ORG_ID: '197',
    COMPANY_NAME: 'Aapico Forging Legal',
    NAME: 'Aapico Forging Dies',
  },
  {
    INVENTORY_ORG_CODE: 'AHA',
    INVENTORY_ORG_ID: '433',
    OPERATING_UNIT_ORG_ID: '413',
    COMPANY_NAME: 'Aapico Hyojin Autotech Legal',
    NAME: 'Aapico Hyojin Autotech Jigs',
  },
  {
    INVENTORY_ORG_CODE: 'AHD',
    INVENTORY_ORG_ID: '352',
    OPERATING_UNIT_ORG_ID: '195',
    COMPANY_NAME: 'Aapico Hitech Tooling',
    NAME: 'Aapico Hitech Tooling Dies',
  },
  {
    INVENTORY_ORG_CODE: 'AHP',
    INVENTORY_ORG_ID: '175',
    OPERATING_UNIT_ORG_ID: '176',
    COMPANY_NAME: 'Aapico Hitech Parts',
    NAME: 'AHP - Aapico Hitech Parts',
  },
  {
    INVENTORY_ORG_CODE: 'AHR',
    INVENTORY_ORG_ID: '222',
    OPERATING_UNIT_ORG_ID: '101',
    COMPANY_NAME: 'AH - Aapico Hitech',
    NAME: 'AHR - Aapico Hitech Rayong',
  },
  {
    INVENTORY_ORG_CODE: 'AHT',
    INVENTORY_ORG_ID: '196',
    OPERATING_UNIT_ORG_ID: '195',
    COMPANY_NAME: 'Aapico Hitech Tooling',
    NAME: 'Aapico Hitech Tooling',
  },
  {
    INVENTORY_ORG_CODE: 'AL',
    INVENTORY_ORG_ID: '330',
    OPERATING_UNIT_ORG_ID: '329',
    COMPANY_NAME: 'Aapico Lemtech Legal',
    NAME: 'AL - Aapico Lemtech',
  },
  {
    INVENTORY_ORG_CODE: 'AM',
    INVENTORY_ORG_ID: '376',
    OPERATING_UNIT_ORG_ID: '373',
    COMPANY_NAME: 'Able Motors Legal',
    NAME: 'Able Motors',
  },
  {
    INVENTORY_ORG_CODE: 'AMK',
    INVENTORY_ORG_ID: '244',
    OPERATING_UNIT_ORG_ID: '243',
    COMPANY_NAME: 'Aapico Mitsuike Legal',
    NAME: 'Aapico Mitsuike',
  },
  {
    INVENTORY_ORG_CODE: 'AMT',
    INVENTORY_ORG_ID: '224',
    OPERATING_UNIT_ORG_ID: '223',
    COMPANY_NAME: 'A Maction Legal',
    NAME: 'A Maction Legal',
  },
  {
    INVENTORY_ORG_CODE: 'APB',
    INVENTORY_ORG_ID: '287',
    OPERATING_UNIT_ORG_ID: '285',
    COMPANY_NAME: 'Aapico Plastics Legal',
    NAME: 'APB - Aapico Plastics Bangplee',
  },
  {
    INVENTORY_ORG_CODE: 'APC',
    INVENTORY_ORG_ID: '354',
    OPERATING_UNIT_ORG_ID: '353',
    COMPANY_NAME: 'Aapico Precision Legal',
    NAME: 'Aapico Precision',
  },
  {
    INVENTORY_ORG_CODE: 'APD',
    INVENTORY_ORG_ID: '103',
    OPERATING_UNIT_ORG_ID: '101',
    COMPANY_NAME: 'AH - Aapico Hitech',
    NAME: 'AH - Aapico Hitech Ayutthaya',
  },
  {
    INVENTORY_ORG_CODE: 'APR',
    INVENTORY_ORG_ID: '288',
    OPERATING_UNIT_ORG_ID: '285',
    COMPANY_NAME: 'Aapico Plastics Legal',
    NAME: 'Aapico Plastics Rayong',
  },
  {
    INVENTORY_ORG_CODE: 'ASP',
    INVENTORY_ORG_ID: '291',
    OPERATING_UNIT_ORG_ID: '289',
    COMPANY_NAME: 'Aapico Structural Legal',
    NAME: 'ASP - Aapico Structural',
  },
  {
    INVENTORY_ORG_CODE: 'ATD',
    INVENTORY_ORG_ID: '104',
    OPERATING_UNIT_ORG_ID: '101',
    COMPANY_NAME: 'AH - Aapico Hitech',
    NAME: 'AH - Aapico Hitech Tooling',
  },
  {
    INVENTORY_ORG_CODE: 'ATS',
    INVENTORY_ORG_ID: '203',
    OPERATING_UNIT_ORG_ID: '202',
    COMPANY_NAME: 'Aapico ITS Legal',
    NAME: 'Aapico ITS',
  },
  {
    INVENTORY_ORG_CODE: 'EA',
    INVENTORY_ORG_ID: '350',
    OPERATING_UNIT_ORG_ID: '349',
    COMPANY_NAME: 'Edscha Aapico Automotive Legal',
    NAME: 'EA - Edscha Aapico Automotive',
  },
  {
    INVENTORY_ORG_CODE: 'ERP',
    INVENTORY_ORG_ID: '264',
    OPERATING_UNIT_ORG_ID: '263',
    COMPANY_NAME: 'A ERP Legal',
    NAME: 'A ERP',
  },
];

export default companiesList;
