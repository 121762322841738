import * as React from 'react';
import Box from '@material-ui/core/Box';
import Card from '@material-ui/core/Card';
import Grid from '@material-ui/core/Grid';
import Checkbox from '@material-ui/core/Checkbox';
import CardActions from '@material-ui/core/CardActions';
import CardContent from '@material-ui/core/CardContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import Chip from '@material-ui/core/Chip';
import NumberFormat from 'react-number-format';

const card = (amount, name, onCheck) => (
  <React.Fragment>
    <CardContent
    // style={{
    //   width: 275,
    // }}
    >
      <Grid container justify="space-between">
        <Typography
          style={{
            textAlign: 'left',
            fontSize: '20px',
            color: 'green',
            fontWeight: 'bold',
          }}
          color="text.secondary"
          gutterBottom
        >
          <p>{name.split(',')[0]}</p>
          <p>{name.split(',')[1]}</p>
        </Typography>
      </Grid>
      <Checkbox onChange={(e) => onCheck(e, name)} />
      <Typography
        color="primary"
        style={{
          fontFamily: 'Bai Jamjuree',
          fontWeight: 'bold',
          fontSize: 20,
          textAlign: 'end',
          marginTop: 5,
        }}
      >
        <NumberFormat
          value={amount}
          displayType={'text'}
          thousandSeparator={true}
          prefix={'฿ '}
        />
      </Typography>
    </CardContent>
  </React.Fragment>
);

export default function OutlinedCard({ amount, name, onCheck }) {
  return (
    <Box
      style={{
        minWidth: 400,
        transition: '0.3s',
        maxWidth: 400,
        margin: 'auto',
        boxShadow: '0 8px 40px -12px rgba(0,0,0,0.3)',
        '&:hover': {
          boxShadow: '0 16px 70px -12.125px rgba(0,0,0,0.3)',
        },
      }}
    >
      <Card variant="outlined">{card(amount, name, onCheck)}</Card>
    </Box>
  );
}
