import React from 'react';
import News from './news';
import Inform from './inform';
import { useHistory } from 'react-router-dom';
import { Grid, Typography, Divider, Chip, Button } from '@material-ui/core';
import { getAllInform } from '../../utils/apis';
import { vendor_id, getUserType } from '../../utils';
import Dialog from './dialog';
import _ from 'lodash';
import ReactLoading from 'react-loading';

const Information = () => {
  const history = useHistory();
  const [loading, setLoading] = React.useState(false);
  const [calendar, setCalendar] = React.useState();
  const [WHT, setWHT] = React.useState();
  const [news, setNews] = React.useState();
  const [maps, setMaps] = React.useState();
  const [show, setShow] = React.useState(false);

  const getInformation = async () => {
    setLoading(true);
    console.log(history.location.pathname);
    let allInform;
    if (history.location.pathname.includes('store')) {
      allInform = await getAllInform('&from=store');
    } else {
      allInform = await getAllInform('&from=account');
    }
    // add factory filter
    let filterNews = _.filter(allInform, (item) => {
      // console.log(item);
      if (
        item.vendor_id == 'all' ||
        getUserType().includes('admin') ||
        getUserType().includes('store')
      ) {
        return item.type == 'news';
      } else {
        return (
          item.type == 'news' && item.vendor_id.split(',').includes(vendor_id())
        );
      }
    });

    let filterCalendar = _.filter(allInform, (item) => {
      if (item.vendor_id == 'all' || getUserType().includes('admin')) {
        return item.type == 'calendar';
      } else {
        return (
          item.type == 'calendar' &&
          item.vendor_id.split(',').includes(vendor_id())
        );
      }
    });
    let filterWHT = _.filter(allInform, (item) => {
      if (item.vendor_id == 'all' || getUserType().includes('admin')) {
        return item.type == 'wht';
      } else {
        return (
          item.type == 'wht' && item.vendor_id.split(',').includes(vendor_id())
        );
      }
    });
    let filterMaps = _.filter(allInform, (item) => {
      if (item.vendor_id == 'all' || getUserType().includes('admin')) {
        return item.type == 'map';
      } else {
        return (
          item.type == 'map' && item.vendor_id.split(',').includes(vendor_id())
        );
      }
    });
    // console.log(allInform);
    setCalendar(filterCalendar);
    setWHT(filterWHT);
    setNews(filterNews);
    setMaps(filterMaps);
    setLoading(false);
  };

  const openNewForm = () => {};

  React.useEffect(() => {
    getInformation();
  }, []);

  if (loading) {
    return <ReactLoading type={'bars'} color={'#444'} className="preloader" />;
  }
  return (
    <div style={{ padding: '10px', margin: 10 }}>
      <Dialog open={show} setOpen={setShow} reload={getInformation} />
      <Grid container direction="row" spacing={3} style={{}} justify="center">
        <Grid item xs={12}>
          <Grid container xs={12} justify="space-between">
            <Typography variant="h5" style={{ fontFamily: 'Bai Jamjuree' }}>
              News
            </Typography>
            {(getUserType().includes('admin') ||
              getUserType().includes('store')) && (
              <Button
                variant={'contained'}
                color="primary"
                onClick={() => setShow(true)}
              >
                New
              </Button>
            )}
          </Grid>
          {/* <Divider style={{ marginBottom: '10px' }} /> */}
          {news && <News data={news} reload={() => getInformation()} />}
        </Grid>
      </Grid>
      {calendar && calendar.length !== 0 && WHT && (
        <Grid item xs={12} style={{ marginTop: '30px' }}>
          <Typography variant="h5" style={{ fontFamily: 'Bai Jamjuree' }}>
            information
          </Typography>
          <Divider style={{ marginBottom: '10px' }} />
          <Inform calendar={calendar} maps={maps} reload={getInformation} />
        </Grid>
      )}
    </div>
  );
};

export default Information;
