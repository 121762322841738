import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import { Grid, IconButton } from '@material-ui/core';
import VisibilityIcon from '@material-ui/icons/Visibility';
import CloseIcon from '@material-ui/icons/Close';
import Dropzone from './dropzone';
import MultiSelect from './MultiSelect';
import { DataContext } from '../../ContextData';
import {
  updateInform,
  uploadFiles,
  deleteFile,
  newInform,
} from '../../utils/apis';
import { useHistory } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import './form.css';

export default function MultilineTextFields({ data, handleClose, reload }) {
  let history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [state] = React.useContext(DataContext);
  const [title, setTitle] = React.useState(data ? data.title : '');
  const [description, setDescription] = React.useState(
    data ? data.description : ''
  );
  const [files, setFiles] = React.useState(data ? data.files : []);
  const [thumbnail, setThumbnail] = React.useState(
    data ? data.thumbnail : null
  );
  const [suppliers, setSuppliers] = React.useState(
    data ? data.vendor_id.split(',') : ['all']
  );
  const [newFiles, setNewFiles] = React.useState();
  const [newThumbnail, setNewThumbnail] = React.useState();

  const getValues = (values) => {
    console.log(values);
    let currentSuppliers = [];
    _.map(values, (item) => {
      currentSuppliers.push(item.id);
    });
    setSuppliers(currentSuppliers);
  };

  const handleChange = (event, type) => {
    switch (type) {
      case 'title':
        setTitle(event.target.value);
        break;
      case 'desc':
        setDescription(event.target.value);
        break;
      default:
        return null;
    }
  };

  const viewFile = (file) => {
    console.log(file);
    window.open(`https://smp.aapico.com/${file.url}`);
  };
  const handleDeleteFile = async (file) => {
    console.log(file);
    let afterDeleteFiles = _.filter(files, (item) => item._id !== file._id);
    setFiles(afterDeleteFiles);
    let deleteResult = await deleteFile(file._id);
    return enqueueSnackbar('Delete successfully', {
      variant: 'info',
      autoHideDuration: 3000,
      anchorOrigin: {
        vertical: 'bottom',
        horizontal: 'right',
      },
    });
  };

  const handleEdit = async () => {
    let formData = new FormData();
    // formData.append('files.files', item);
    formData.append(
      'data',
      JSON.stringify({
        title,
        description,
        type: data.type,
        vendor_id: suppliers.join(','),
      })
    );
    let updateResult = await updateInform(data.id, formData);
    // upload file
    let formDataFiles = new FormData();
    formDataFiles.append('ref', 'information');
    formDataFiles.append('refId', data.id);
    formDataFiles.append('field', 'files');
    newFiles.forEach((file) => {
      console.log(file);
      formDataFiles.append(`files`, file, file.name);
    });
    if (newFiles.length !== 0) {
      await uploadFiles(formDataFiles);
    }
    //////////////////////////////////////////////////////////////
    // uploade file preview (thumbnail)
    let formDataThumnail = new FormData();
    formDataThumnail.append('ref', 'information');
    formDataThumnail.append('refId', data.id);
    formDataThumnail.append('field', 'thumbnail');
    newThumbnail.forEach((file) => {
      console.log(file);
      formDataThumnail.append(`files`, file, file.name);
    });
    if (newThumbnail.length !== 0) { 
      await uploadFiles(formDataThumnail);
    }
    /////////////////////////////////////////////////////////////////

    reload();
    handleClose();
  };

  const handleCreate = async () => {
    console.log(
      'create',
      title,
      description,
      suppliers,
      files,
      newFiles,
      newThumbnail
    );
    let check = false;
    if (/^\s*$/.test(title)) {
      document.getElementById('title_danger').style.display = '';
      check = true;
    } else {
      document.getElementById('title_danger').style.display = 'none';
    }
    if (/^\s*$/.test(description)) {
      document.getElementById('detail_danger').style.display = '';
      check = true;
    } else {
      document.getElementById('detail_danger').style.display = 'none';
    }
    if (newThumbnail.length === 0) {
      document.getElementById('img_danger').style.display = '';
      check = true;
    } else {
      document.getElementById('img_danger').style.display = 'none';
    }
    if (suppliers.length === 0) {
      document.getElementById('tag_danger').style.display = '';
      check = true;
    } else {
      document.getElementById('tag_danger').style.display = 'none';
    }
    if (check) {
      return;
    }
    let formData = new FormData();
    formData.append(
      'data',
      JSON.stringify({
        title,
        description,
        type: 'news',
        vendor_id: suppliers.join(','),
        from: history.location.pathname.includes("store") ? "store" : "account"
      })
    );
    newFiles.forEach((file) => {
      formData.append(`files.files`, file, file.name);
    });
    newThumbnail.forEach((file) => {
      formData.append(`files.thumbnail`, file, file.name);
    });
    await newInform(formData);
    reload();
    handleClose();
  };

  console.log(newThumbnail);

  return (
    <form noValidate autoComplete="off">
      <Grid container direction="row" spacing={3}>
        <Grid item xs={12}>
          <Grid container fullWidth>
            <Grid item xs={3}>
              <p id="title_danger" style={{ color: 'red', display: 'none' }}>
                Required *
              </p>
            </Grid>
          </Grid>
          <TextField
            required
            fullWidth
            variant="outlined"
            id="standard-multiline-flexible"
            label="Title"
            value={title}
            defaultValue="title"
            onChange={(e) => handleChange(e, 'title')}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container fullWidth>
            <Grid item xs={3}>
              <p id="detail_danger" style={{ color: 'red', display: 'none' }}>
                Required *
              </p>
            </Grid>
          </Grid>
          <TextField
            required
            fullWidth
            id="outlined-multiline-static"
            label="Description"
            multiline
            rows={4}
            defaultValue="Description here"
            variant="outlined"
            value={description}
            onChange={(e) => handleChange(e, 'desc')}
          />
        </Grid>
        <Grid xs={12} container justify="center">
          {files.map((each) => {
            console.log(each);
            return (
              <Grid item>
                <div>
                  <div style={{ marginTop: 10, padding: 10 }}>
                    <img
                      className={'image'}
                      //   style={{ borderRadius: 10 }}
                      src={
                        each.ext === '.pdf'
                          ? 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAIVBMVEX09PS2trbMzMzo6OjPz8/b29vAwMDu7u7Dw8O4uLje3t6y659EAAABeUlEQVR4nO3c3U7CQBCAUYqI4Ps/sD8FExOdmNnZTjHnJNxu9utOS3rTwwEAAAAAAAAAAODLMuT6/ju/dDd8Gu247qVk7ESW/ZQUdOyj5NuGUiEf87WDktETueX0l9z2cco776NkvaLHgRVOyy6ma53zwZA9lJScyKq3pDCkt6QypLWkNKTzPikN6SypDWksqR2txpLi0eorKR6tvmfXhJCekhkhLSVTQjrukykhHSVzQhpK5oxWQ8mk0dq+ZNJofdi2ZDzk9bf3+OVctss/GA/5yXEdsOJVQzNDluJVQ0JCQvKEhI7rv2LxqiEnEhKSJyQkJE9ISEiekJCQPCEhIXlCQkLyhISE5AkJCckTEhKSJyQkJE9ISEiekJCQPCEhIXlCQkLyhISE5AkJCckTEhKSJyQkJE9ISEiekJCQPCEhIXlCQm0hl+dal2tTSLH7x7oePuRe8vghy/8JuXaFZL9BF+oIKbb9U+tppi1DAAAAAAAAAAAAtvMGsDkRBZH0SJMAAAAASUVORK5CYII='
                          : `https://smp.aapico.com/${each.url}`
                      }
                      width="100"
                      height="100"
                    />
                    <div className={'hide'}>
                      <IconButton
                        size="small"
                        onClick={() => viewFile(each)}
                        style={{
                          padding: 5,
                          position: 'relative',
                          marginTop: '-120px',
                          marginRight: '-10px',
                          float: 'right',
                          backgroundColor: 'aliceblue',
                        }}
                      >
                        <VisibilityIcon />
                      </IconButton>
                      <IconButton
                        size="small"
                        onClick={() => handleDeleteFile(each)}
                        style={{
                          padding: 5,
                          position: 'relative',
                          marginTop: '-30px',
                          marginRight: '-10px',
                          float: 'right',
                          backgroundColor: 'aliceblue',
                        }}
                      >
                        <CloseIcon />
                      </IconButton>
                    </div>
                  </div>
                </div>
              </Grid>
            );
          })}
        </Grid>

        <Grid item xs={12}>
          <p>File News</p>
          <Dropzone getFiles={(allFiles) => setNewFiles(allFiles)} />
        </Grid>
        <Grid xs={12} container justify="center">
          {thumbnail && (
            <Grid item>
              <div>
                <div style={{ marginTop: 10, padding: 10 }}>
                  <img
                    className={'image'}
                    //   style={{ borderRadius: 10 }}
                    src={
                      thumbnail
                        ? `https://smp.aapico.com/${thumbnail.url}`
                        : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAIVBMVEX09PS2trbMzMzo6OjPz8/b29vAwMDu7u7Dw8O4uLje3t6y659EAAABeUlEQVR4nO3c3U7CQBCAUYqI4Ps/sD8FExOdmNnZTjHnJNxu9utOS3rTwwEAAAAAAAAAAODLMuT6/ju/dDd8Gu247qVk7ESW/ZQUdOyj5NuGUiEf87WDktETueX0l9z2cco776NkvaLHgRVOyy6ma53zwZA9lJScyKq3pDCkt6QypLWkNKTzPikN6SypDWksqR2txpLi0eorKR6tvmfXhJCekhkhLSVTQjrukykhHSVzQhpK5oxWQ8mk0dq+ZNJofdi2ZDzk9bf3+OVctss/GA/5yXEdsOJVQzNDluJVQ0JCQvKEhI7rv2LxqiEnEhKSJyQkJE9ISEiekJCQPCEhIXlCQkLyhISE5AkJCckTEhKSJyQkJE9ISEiekJCQPCEhIXlCQkLyhISE5AkJCckTEhKSJyQkJE9ISEiekJCQPCEhIXlCQm0hl+dal2tTSLH7x7oePuRe8vghy/8JuXaFZL9BF+oIKbb9U+tppi1DAAAAAAAAAAAAtvMGsDkRBZH0SJMAAAAASUVORK5CYII='
                    }
                    width="100"
                    height="100"
                  />
                  <div className={'hide'}>
                    <IconButton
                      size="small"
                      onClick={() => viewFile(thumbnail)}
                      style={{
                        padding: 5,
                        position: 'relative',
                        marginTop: '-120px',
                        marginRight: '-10px',
                        float: 'right',
                        backgroundColor: 'aliceblue',
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                    <IconButton
                      size="small"
                      onClick={() => handleDeleteFile(thumbnail)}
                      style={{
                        padding: 5,
                        position: 'relative',
                        marginTop: '-30px',
                        marginRight: '-10px',
                        float: 'right',
                        backgroundColor: 'aliceblue',
                      }}
                    >
                      <CloseIcon />
                    </IconButton>
                  </div>
                </div>
              </div>
            </Grid>
          )}
        </Grid>
        <Grid item xs={12}>
          <Grid container fullWidth>
            <Grid item xs={3}>
              <p>Image for Preview</p>
            </Grid>
            <Grid item xs={3}>
              <p id="img_danger" style={{ color: 'red', display: 'none' }}>
                Required *
              </p>
            </Grid>
          </Grid>
          <Dropzone
            getFiles={(allFiles) => setNewThumbnail(allFiles)}
            single={true}
          />
        </Grid>
        <Grid item xs={12}>
          <Grid container fullWidth>
            <Grid item xs={3}>
              <p id="tag_danger" style={{ color: 'red', display: 'none' }}>
                Required *
              </p>
            </Grid>
          </Grid>
          <MultiSelect
            data={data ? data.vendor_id.split(',') : ['all']}
            allData={state.vendors}
            getValues={getValues}
          />
        </Grid>
        <div style={{ margin: 10, marginLeft: 'auto' }}>
          <Button
            autoFocus
            onClick={data ? handleEdit : handleCreate}
            variant="contained"
            color="primary"
          >
            Save changes
          </Button>
        </div>
      </Grid>
    </form>
  );
}
