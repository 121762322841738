import React, { useState } from 'react';
import Avatar from '@material-ui/core/Avatar';
import Button from '@material-ui/core/Button';
import CssBaseline from '@material-ui/core/CssBaseline';
import TextField from '@material-ui/core/TextField';
import Link from '@material-ui/core/Link';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import LockOutlinedIcon from '@material-ui/icons/LockOutlined';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import DialogEmail from '../components/ForgotPassword';
import Welcome from '../components/Welcome';
import { useSnackbar } from 'notistack';
import { login, loginScanner } from '../utils';
import stepSvgUrl from './welcome_page.svg';
import { isMobile } from 'react-device-detect';

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">

      Contact us at: 035-350880 ext 1234 or 1233
      <br />
      {'Copyright © '}
      <Link color="inherit" href="https://powermap.in.th/">
        POWERMAP
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
  );
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    zoom: '111.11%',
  },
  image: {
    backgroundImage: 'url("/login.png")',
    backgroundRepeat: 'no-repeat',
    backgroundColor:
      theme.palette.type === 'light'
        ? theme.palette.grey[50]
        : theme.palette.grey[900],
    backgroundSize: 'none',
    backgroundPosition: 'center',
  },
  paper: {
    margin: theme.spacing(8, 4),
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  avatar: {
    margin: theme.spacing(1),
    backgroundColor: theme.palette.secondary.main,
  },
  form: {
    width: '100%', // Fix IE 11 issue.
    marginTop: theme.spacing(1),
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
  },
}));

export default function SignIn(props) {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [account, setAccount] = useState({
    identifier: '',
    password: '',
  });

  const [openEmail, setOpenEmail] = useState(false);

  const handleLogin = (e) => {
    e.preventDefault();
    console.log('login', isMobile);
    if (isMobile) {
      return loginScanner(account, props, enqueueSnackbar);
    }
    login(account, props, enqueueSnackbar);
  };

  const handleChange = (e) => {
    setAccount({ ...account, [e.target.name]: e.target.value });
  };

  const close = () => {
    setOpenEmail(false);
  };

  return (
    <div>
      <Grid container component="main" className={classes.root}>
        <CssBaseline />
        {/* TODO */}
        <Grid item xs={false} sm={4} md={8} className={classes.image}>
          {/* <Welcome /> */}
        </Grid>
        <Grid item xs={12} sm={8} md={4} component={Paper} elevation={6} square>
          <div className={classes.paper}>
            {/* <Avatar className={classes.avatar}>
              <LockOutlinedIcon />
            </Avatar> */}
            <img
              src="https://www.aapico.com/wp-content/uploads/2019/07/cropped-logo-Aapico-1.png"
              alt=""
            />
            <Typography component="h1" variant="h5">
              Sign in
            </Typography>
            <form className={classes.form} noValidate onSubmit={handleLogin}>
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                id="identifier"
                label="Username"
                name="identifier"
                autoComplete="identifier"
                autoFocus
                onChange={handleChange}
              />
              <TextField
                variant="outlined"
                margin="normal"
                required
                fullWidth
                name="password"
                label="Password"
                type="password"
                id="password"
                autoComplete="current-password"
                onChange={handleChange}
              />
              {!isMobile && (
                <Grid container>
                  <Grid item xs>
                    <Link
                      variant="body2"
                      onClick={() => {
                        setOpenEmail(true);
                      }}
                    >
                      Forgot password?
                    </Link>
                  </Grid>
                </Grid>
              )}
              {/* <FormControlLabel
            control={<Checkbox value="remember" color="primary" />}
            label="Remember me"
          /> */}
              <Grid container justify={'flex-end'} spacing={2}>
                <Grid item xs={6} text={'right'}>
                  <Button
                    fullWidth
                    type="submit"
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                  >
                    Sign In
                  </Button>
                </Grid>
              </Grid>
            </form>
          </div>
          <Box mt={8}>
            <Copyright />
          </Box>
        </Grid>
      </Grid>
      <DialogEmail open={openEmail} close={close} />
    </div>
  );
}
