import React from "react";

function Loading() {
    return (
        <div className="flex justify-center w-full my-10 items-center">
            <div
                style={{ borderTopColor: "transparent" }}
                className="w-16 h-16 border-4 border-blue-400 border-dotted rounded-full animate-spin"></div>
        </div>
    );
}

export default Loading;