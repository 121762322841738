import React from 'react';
import { Chip } from '@material-ui/core';
import MaterialTable from 'material-table';
import NumberFormat from 'react-number-format';
import _ from 'lodash';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';

const Table = ({ data, month, dataExport }) => {
  return (
    <MaterialTable
      title={
        <p
          style={{
            fontFamily: 'Bai Jamjuree',
            fontSize: 16,
            fontWeight: 'bold',
          }}
        >
          Payment Summary [{month}]
        </p>
      }
      columns={[
        { title: 'Cheque No', field: 'CHECK_NUMBER', width: 100 },
        {
          title: 'Total Amount',
          field: 'AMOUNT',
          width: 150,
          cellStyle: { fontWeight: 'bold', fontFamily: 'Bai Jamjuree' },
          render: (rowData) => {
            if (rowData.parentId) {
              return (
                <NumberFormat
                  value={rowData.BASE_AMOUNT}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'฿ '}
                />
              );
            } else {
              return (
                <NumberFormat
                  value={rowData.TOTAL_AMOUNT}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'฿ '}
                />
              );
            }
          },
        },
        {
          title: 'WHT Amount',
          field: 'WHT_AMT',
          type: 'numeric',
          width: 70,
          header: { justify: 'center' },
          render: (rowData) => {
            return (
              <>
                <NumberFormat
                  value={rowData.WHT_AMT}
                  displayType={'text'}
                  thousandSeparator={true}
                  prefix={'฿ '}
                />
              </>
            );
          },
        },
        {
          title: 'Org. ',
          field: 'ORG_CODE',
          type: 'numeric',
          width: 70,
          header: { justify: 'center' },
          render: (rowData) => {
            if (Array.isArray(rowData.ORG_CODE)) {
              return (
                <>
                  {rowData.ORG_CODE.map((item) => (
                    <Chip color="primary" label={item}></Chip>
                  ))}
                </>
              );
            } else {
              return <Chip label={rowData.ORG_CODE} color="primary" />;
            }
          },
        },
        {
          title: 'Bank Name',
          field: 'BANK_NAME',
          width: 10,
          render: (rowData) => {
            if (rowData.BANK_NAME === 'CLEARING') {
              return 'SETTLE';
            } else {
              return rowData.BANK_NAME;
            }
          },
        },
        {
          title: 'Invoice No',
          field: 'INVOICE_NUM',
          width: '100%',
          render: (rowData) => {
            if (Array.isArray(rowData.INVOICE_NUM)) {
              return (
                <>
                  {rowData.INVOICE_NUM.map((item) => (
                    <Chip color="primary" label={item}></Chip>
                  ))}
                </>
              );
            } else {
              return <Chip color="primary" label={rowData.INVOICE_NUM}></Chip>;
            }
          },
        },
        {
          title: 'Account Description',
          field: 'BANK_ACCOUNT_NAME',
        },
        {
          title: 'Invoice Description',
          field: 'INV_DESCRIPTION',
        },
        { title: 'Invoice Date', field: 'INVOICE_DATE' },
      ]}
      data={data}
      parentChildData={(row, rows) => rows.find((a) => a.id === row.parentId)}
      options={{
        cellStyle: { fontFamily: 'Bai Jamjuree', textAlign: 'left' },
        pageSize: 20,
        pageSizeOptions: [5, 10, 20, 50, 100],
        headerStyle: {
          fontWeight: 'bold',
          fontFamily: 'Bai Jamjuree',
          textAlign: 'left',
        },
        exportAllData: true,
        exportButton: true,
        exportButton: {
          csv: true,
          pdf: false,
        },
        exportCsv: (columns, data) => {
          const filename = `invoice-${month}.xlsx`;
          const fileType =
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
          const ws = XLSX.utils.json_to_sheet(dataExport);
          const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
          const excelBuffer = XLSX.write(wb, {
            bookType: 'xlsx',
            type: 'array',
          });
          const dataOutput = new Blob([excelBuffer], { type: fileType });
          FileSaver.saveAs(dataOutput, filename);
          return;
        },
        exportFileName: `invoice-${month}`,
      }}
    />
  );
};

export default Table;
