/* eslint-disable no-use-before-define */
import React from 'react';
import Chip from '@material-ui/core/Chip';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    '& > * + *': {
      marginTop: theme.spacing(3),
    },
  },
}));

export default function Tags({ data, allData, getValues }) {
  const classes = useStyles();
  // console.log(allData);
  let selected = _.filter(
    [
      {
        name: 'all',
        id: 'all',
      },
      ...allData,
    ],
    (item) => data.includes(item.id)
  );

  return (
    <div className={classes.root}>
      <Autocomplete
        multiple
        id="tags-outlined"
        options={[
          {
            name: 'all',
            id: 'all',
          },
          ...allData,
        ]}
        onChange={(e, value) => getValues(value)}
        getOptionLabel={(option) => option.name}
        defaultValue={selected}
        filterSelectedOptions
        renderInput={(params) => (
          <TextField
            {...params}
            variant="outlined"
            label="Suppliers"
            placeholder="add supplier"
          />
        )}
      />
    </div>
  );
}
