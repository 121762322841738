import * as React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';
import { updateUserAlert } from '../utils/apis';
import { IoAdd } from "react-icons/io5";

const ConfirmDialog = ({
  open = false,
  onConfirm,
  children,
  onClose
}) => {


  return (
        <Dialog
        open={open}
        PaperProps={{
            style: {
            minHeight: '200px',
            width:'300px',
            boxShadow: 'none',
            fontFamily: 'Bai Jamjuree',
            borderRadius: '12px',
            },
            
        }}
        >
        <DialogTitle className='text-2xl font-semibold bg-blue-800 text-white'>Confirm Delete </DialogTitle>
        <DialogContent className=' font-semibold'>Are you sure Delete file ?  </DialogContent>
        <DialogActions >
          <Button
            className='font-semibold'
            variant='outlined'
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            className='font-medium bg-blue-600 text-white hover:bg-blue-800'
            variant='contained'
            onClick={onConfirm}
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
  )
}
export default ConfirmDialog
