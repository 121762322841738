import React from 'react';
import { BrowserRouter, Switch } from 'react-router-dom';
import './App.css';
import PrivateRoute from './components/PrivateRoute';
import PublicRoute from './components/PublicRoute';
import { SnackbarProvider } from 'notistack';
import Blog from './routes/Blog';
import Table from './routes/StatusTable';
import SignIn from './routes/SignIn';
import Form from './components/Forms';
import ResetPassword from './components/ResetPassword';
import ChangePassword from './components/ChangePassword';
import Home from './routes/home/Home';
import Invoice_Admin from './routes/invoices/admin';
import Payment_Admin from './routes/payments/admin';
import Forecast from './routes/forecast/Forecast';
import Scanner from './routes/Scanner_box';
import Page404 from './routes/Page404';
import User from './routes/user/User';
import PrintReceiveNo from './routes/PrintReceiveNo';
import PDSStatus from './routes/PDSStatus';
import Emails from './emails/template';
import ConfirmPO from './routes/confirmOrder';
import Purchase from './routes/purchase';
import CodeOfConduct from './routes/codeOfconduct';
import ConfirmASN from './routes/confirmASN';
import { ConfirmProvider } from 'material-ui-confirm';
import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import { ThemeProvider } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import MomentUtils from '@date-io/moment';
import AapicoTheme from './AapicoTheme';
import Dashboard from './routes/dashboard/ChartDashboard';
import DataContextProvider from './ContextData';
import Information from './routes/information';
import EmailGroup from "./routes/emailGroup";
import Manual from './routes/manual';
import {
  QueryClient,
  QueryClientProvider,
  useQuery,
} from "@tanstack/react-query";
const queryClient = new QueryClient();

function App() {
  const notiRef = React.useRef();
  const onClickDismiss = (key) => () => {
    notiRef.current.closeSnackbar(key);
  };

  return (
    <ThemeProvider theme={AapicoTheme}>
      <MuiPickersUtilsProvider utils={MomentUtils}>
        <SnackbarProvider
          maxSnack={3}
          ref={notiRef}
          action={(key) => (
            <Button
              onClick={onClickDismiss(key)}
              variant="contained"
              color="primary"
            >
              Dismiss
            </Button>
          )}
        >
          <QueryClientProvider client={queryClient}>
          <ConfirmProvider>
            <Routes />
            </ConfirmProvider>
          </QueryClientProvider>
        </SnackbarProvider>
      </MuiPickersUtilsProvider>
    </ThemeProvider>
  );
}

function Routes() {
  const email = () => (
    <div dangerouslySetInnerHTML={{ __html: `<strong>${Emails()}</strong>` }} />
  );
  return (
    <BrowserRouter basename="#">
      <Switch>
        <PublicRoute
          restricted={true}
          component={SignIn}
          path="/signin"
          exact
        />
        <PublicRoute
          restricted={true}
          component={ResetPassword}
          path="/reset-password"
          exact
        />
        <PublicRoute restricted={true} component={Form} path="/form" exact />
        <PublicRoute
          restricted={true}
          component={Scanner}
          path="/scanner"
          exact
        />

        <PrivateRoute path="/" component={Home} exact />
        <PrivateRoute path="/dashboard" component={Dashboard} exact />
        <PrivateRoute
          path="/change-password"
          component={ChangePassword}
          exact
        />
        <PrivateRoute path="/blog" component={Blog} />
        <PrivateRoute path="/table" component={Table} />
        <PrivateRoute path="/user" component={User} />
        <PrivateRoute path="/forecast" component={Forecast} />
        <PrivateRoute path="/scanner" component={Scanner} />
        <PrivateRoute path="/print-received" component={PrintReceiveNo} />
        <PrivateRoute path="/pds-status" component={PDSStatus} />
        <PrivateRoute path="/email-template" component={email} />
        <PrivateRoute path="/invoice-admin" component={Invoice_Admin} />
        <PrivateRoute path="/payment-admin" component={Payment_Admin} />
        <PrivateRoute path="/scan-received" component={Scanner} />
        <PrivateRoute path="/account-info" component={Information} />
        <PrivateRoute path="/store-info" component={Information} />
        <PrivateRoute path="/confirm-po" component={ConfirmPO} />
        <PrivateRoute path="/codeOfconduct" component={CodeOfConduct} />
        <PrivateRoute path="/purchase" component={Purchase} />
        <PrivateRoute path="/confirm-asn" component={ConfirmASN} />
        <PrivateRoute path="/email-group" component={EmailGroup} />
        <PrivateRoute path="/manual" component={Manual} />
        <PrivateRoute component={Page404} />
      </Switch>
    </BrowserRouter>

    // For mobile only
    // <BrowserRouter basename="/scanner_v2/#">
    //   <Switch>
    //     <PublicRoute
    //       restricted={true}
    //       component={SignIn}
    //       path="/signin"
    //       exact
    //     />
    //     <PrivateRoute
    //       path="/change-password"
    //       component={ChangePassword}
    //       exact
    //     />
    //     <PrivateRoute component={Scanner} path="/" exact />
    //     <PrivateRoute component={Page404} />
    //   </Switch>
    // </BrowserRouter>
  );
}

export default App;
