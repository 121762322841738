import React from "react";
import {
  BiChevronRight,
  BiChevronsRight,
  BiChevronsLeft,
  BiChevronLeft,
} from 'react-icons/bi';

function Pagination({
  setActivePage,
  selectablePage,
  activePage,
  previousPage,
  nextPage,
  gotoLastPage,
  gotoFirstPage,
}) {
  return (
    <ol className="flex justify-end text-xs font-medium space-x-1">
      <li>
        <button
          onClick={gotoFirstPage}
          className="inline-flex items-center justify-center w-8 h-8 border bg-white border-gray-100 rounded-full"
        >
          <BiChevronsLeft className="w-5 h-5" />
        </button>
      </li>
      <li>
        <button
          onClick={previousPage}
          className="inline-flex items-center justify-center w-8 h-8 border bg-white border-gray-100 rounded-full"
        >
          <BiChevronLeft className="w-5 h-5" />
        </button>
      </li>

      {selectablePage.map((page, index) => (
        <li key={index}>
          <button
            onClick={() => {
              setActivePage(page);
            }}
            className={`block w-8 h-8 text-center border ${page == activePage && 'bg-indigo-600 text-white'
              } bg-white border-gray-300 rounded-md leading-8`}
          >
            {' '}
            {page}{' '}
          </button>
        </li>
      ))}

      <li>
        <button
          onClick={nextPage}
          className="inline-flex items-center justify-center w-8 h-8 border bg-white border-gray-100 rounded-full"
        >
          <BiChevronRight className="w-5 h-5" />
        </button>
      </li>
      <li>
        <button
          onClick={gotoLastPage}
          className="inline-flex items-center justify-center w-8 h-8 border bg-white border-gray-100 rounded-full"
        >
          <BiChevronsRight className="w-5 h-5" />
        </button>
      </li>
    </ol>
  );
}

export default Pagination;
