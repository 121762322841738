import React from "react";
import Table from "../../components/TailwindTable";
import { useQuery } from '@tanstack/react-query';
import Pagination from '../../components/Pagination';
import { AiOutlineCloseCircle, AiOutlinePlusCircle, AiOutlineMinusCircle, AiOutlineDelete, AiOutlineEdit } from 'react-icons/ai';
import {
    getGroupEmail,
    getVendors,
    createGroupEmail,
  deleteGroupEmail,
  countGroupEmail
} from '../../utils/apis';
import ListBox from '../../components/ListBox';
import {
    BsEyeFill,
    BsClipboardPlus,
    BsPlus
} from 'react-icons/bs';
import { getUserType, company_name } from "../../utils"
import Loading from '../../components/Loading';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import EmailDialog from './EmailDialog';
const SweetAlert = withReactContent(Swal);

const pageSizeList = [
    { id: 1, name: 5 },
    { id: 2, name: 10 },
    { id: 3, name: 20 },
    { id: 4, name: 50 },
    { id: 5, name: 100 },
];

function EmailGroupTable() {
    const [selectablePage, setSelectablePage] = React.useState([1, 2, 3]);
    const [activePage, setActivePage] = React.useState(1);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [show, setShow] = React.useState(false);
    const [runningNumber, setRunningNumber] = React.useState("");
    const [totalPages, setTotalPages] = React.useState();
    const [totalEmailgroup, setTotalEmailGroup] = React.useState();
    const [pageSize, setPageSize] = React.useState(50);
    const [emailGroups, setEmailGroups] = React.useState([]);
  const [desc, setDesc] = React.useState('');
    const [gname, setGName] = React.useState("");
    const [emails, setEmails] = React.useState([""]);
    const [searchSupplier, setSearchSupplier] = React.useState("");
    const [pageSizeSelected, setPageSizeSelected] = React.useState(
        pageSizeList[3]
    );
    const [vendors, setVendors] = React.useState([]);
    const [resultVendors, setResultVendors] = React.useState([]);
    const [selectedVendors, setSelectedVendors] = React.useState('');
    const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [showEmailDialog, setShowEmailDialog] = React.useState(false);
  const [selectedeGroup, setSelectedGroup] = React.useState([]);

    const columns = React.useMemo(
        () => [
            {
                Header: 'Actions',
                Cell: ({ row }) => {
                    return (
                        <div className="space-x-2">
                            {getUserType() === 'store' && (
                                <button
                                    className="bg-gray-100 p-2 rounded-lg shadow-md"
                                    onClick={() => handleDelete(row.original.id)}
                                >
                                    <AiOutlineDelete className={'text-blue-600 w-5 h-5'} />
                                </button>
                            )}
                        </div>
                    );
                },
            },
            {
                Header: 'Group Name',
                Cell: ({ row }) => {
                    return (
                        <span className={`text-sm font-jamjuree`}>
                            {row.original.name}
                        </span>
                    );
                },
            },
            {
                Header: 'Email List',
                Cell: ({ row }) => {
                    return (
                      <div className="flex h-auto space-x-2">
                        <div className={`break-all h-auto`}>
                        {row.original.email_list.split(",").map(each => (
                          <div className={``}><span className={`text-sm w-auto font-jamjuree space-y-1 p-1 text-white bg-indigo-600 rounded-lg`}>{each}</span></div>
                        ))}
                      </div>
                        <button className="" onClick={() => {
                          setSelectedGroup(row.original);
                          setShowEmailDialog(true)
                        }}>
                          <AiOutlineEdit className="w-7 h-7 bg-indigo-400 text-white p-1 rounded-lg" />
                        </button>
                      </div>);
                },
            },
            {
                Header: 'Supplier Name',
                Cell: ({ row }) => {
                    return (
                        <span className={`text-sm font-jamjuree`}>
                            {row.original.supplier_name}
                        </span>
                    );
                },
            },
            {
                Header: 'Factory',
                Cell: ({ row }) => {
                    return (
                        <span className={`text-sm font-jamjuree`}>
                            {row.original.factory}
                        </span>
                    );
                },
        },
        {
          Header: 'Description',
          Cell: ({ row }) => {
            return (
              <span className={`text-sm font-jamjuree`}>
                {row.original.description}
              </span>
            );
          },
        },
        {
                Header: 'Created At',
                Cell: ({ row }) => {
                    return (
                        <span className={`text-sm font-jamjuree`}>
                            {new moment(row.original.createdAt).format('YYYY-MM-DD HH:mm')}
                        </span>
                    );
                },
            },
        ],
        []
    );

    const getAllVendors = async () => {
        let data = await getVendors();
        setVendors(data[0].data.Output);
    };
    const handleChangeEmail = (email, index) => {
        console.log(email, index)
        let newEmails = [...emails];
        newEmails[index] = email;
        setEmails(newEmails)
    }

  const handleDelete = async (id) => {
    SweetAlert.fire({
      title: 'Do you want to delete this group email?',
      showCancelButton: true,
      confirmButtonText: 'Confirm',
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        let deleteResult = await deleteGroupEmail(id);
        if (deleteResult.status === 200) {
          refetch()
          return enqueueSnackbar('Group Email have been deleted', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            autoHideDuration: 300000,
          });
        } else {
          return enqueueSnackbar('Fail to delete Group Email', {
            variant: 'error',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
            autoHideDuration: 300000,
          });
        }
      }
    })
  }

    const handleSubmit = async () => {
      console.log('create new email group');
      // check email array
      if (
        _.some(
          emails,
          (item) =>
            !item.match(
              /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            )
        )
      ) {
        return enqueueSnackbar('Please fill email', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          autoHideDuration: 300000,
        });
      }
      // check no vendor selected

      if (!selectedVendors) {
        return enqueueSnackbar('Please selected supplier', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          autoHideDuration: 300000,
        });
      }

      if (!desc) {
        return enqueueSnackbar('Please add description', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          autoHideDuration: 300000,
        });
      }

      let dupCheck = await countGroupEmail(`${gname}-${runningNumber}`);
      if (dupCheck > 0) {
        return enqueueSnackbar('Group Name already exists', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          autoHideDuration: 300000,
        });
      }

      // submit data to
      let submitData = {
        factory: company_name(),
        supplier_name: selectedVendors.VENDOR_NAME,
        email_list: emails.join(),
        name: `${gname}-${runningNumber}`,
        vendor_id: selectedVendors.VENDOR_ID,
        description: desc
      };
      console.log(submitData);
      let resultCreate = await createGroupEmail(submitData);
      if (resultCreate.status === 200) {
        refetch();
        setShow(false);
        setSelectedVendors();
        setGName();
        setDesc("");
        setEmails([""]);
        setResultVendors([]);
        setSearchTerm('');
        setEmailGroups([]);

        return enqueueSnackbar('Group Email created successfully', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          autoHideDuration: 300000,
        });
      } else {
        return enqueueSnackbar('fail to create group emails', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          autoHideDuration: 300000,
        });
      }
    };

    const previousPage = () => {
      if (activePage - 1 > 0) {
        setActivePage(activePage - 1);
      }
    };
    const nextPage = () => {
      if (totalPages >= activePage + 1) {
        setActivePage(activePage + 1);
      }
    };
    const gotoLastPage = () => {
      setActivePage(totalPages == 0 ? 1 : totalPages);
    };
    const gotoFirstPage = () => {
      setActivePage(1);
    };

    const handlePageSizeSelected = (pageSizeSelect) => {
      setPageSize(pageSizeSelect.name);
      setPageSizeSelected(pageSizeSelect);
    };

    const formatData = (data) => {
      let GroupEmailData = [];
      _.map(data.data, (item) => {
        GroupEmailData.push({
          ...item,
        });
      });
      console.log(data, GroupEmailData);
      setEmailGroups(GroupEmailData);
      setTotalEmailGroup(data.total);
    };

    const handleVendorClick = (each) => {
      if (!each.VENDOR_NAME_ALT) {
        return enqueueSnackbar('Vendor has no alternative name in ORACLE', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          autoHideDuration: 300000,
        });
      }
      console.log(each, emailGroups);
      setSearchTerm(each.VENDOR_NAME);
      setSelectedVendors(each);
      // update running number
      let existing = _.filter(emailGroups, (group) => {
        return group.vendor_id === each.VENDOR_ID;
      });
      let newRunningNumber = String(existing.length + 1).padStart(4, '0');
      setRunningNumber(newRunningNumber);
      setGName(
        `${company_name()}-${each.VENDOR_NAME_ALT || ''}`
      );
      setResultVendors([]);
    };

    const handleSearch = async (e) => {
      let resultDrops =
        searchTerm === ''
          ? ''
          : vendors.filter((vendor) =>
              vendor.VENDOR_NAME.toLowerCase()
                .replace(/\s+/g, '')
                .includes(searchTerm.toLowerCase().replace(/\s+/g, ''))
            );
      if (
        selectedVendors &&
        resultDrops.length !== 0 &&
        resultDrops[0].VENDOR_NAME === selectedVendors.VENDOR_NAME
      ) {
        setResultVendors([]);
      } else {
        setGName('');
        setResultVendors(resultDrops);
      }
    };

    // console.log(selectedVendors, getUserType());

    const { isLoading, error, data, isFetching, refetch } = useQuery(
      ['emailGroups', activePage],
      () => getGroupEmail(activePage, pageSize, searchSupplier)
    );
    React.useEffect(() => {
      if (data) {
        formatData(data);
      }
    }, [data]);

    React.useEffect(() => {
      refetch();
    }, [
      pageSize,
      activePage,
      selectablePage,
      pageSizeSelected,
      searchSupplier,
    ]);

    React.useEffect(() => {
      handleSearch();
    }, [searchTerm]);

    React.useEffect(() => {
      getAllVendors();
    }, []);

    React.useEffect(() => {
      if (totalPages === 0) {
        setSelectablePage([1]);
      } else {
        let newSelecteablePages = _.filter(
          [activePage - 1, activePage, activePage + 1],
          (item) => item !== 0 && item !== totalPages + 1
        );
        setSelectablePage(newSelecteablePages);
      }
    }, [activePage, totalPages]);

    if (isLoading) {
      return <Loading />;
    }

    if (emailGroups) {
      return (
        <div className="flex-col overflow-hidden w-full">
          {showEmailDialog && <EmailDialog open={showEmailDialog} setOpen={setShowEmailDialog} data={selectedeGroup} reload={refetch} />}
          {show && (
            <div className="m-2 flex space-x-2 bg-white rounded-md p-2 pr-5 pt-4 pb-6">
              {/* input supplier name */}
              <button
                onClick={() => setShow(false)}
                className="absolute bg-white p-1 rounded-full right-0 mr-0 -mt-6 shadow-lg"
              >
                <AiOutlineCloseCircle className="w-6 h-6 bg-gray-50" />
              </button>
              <div className="flex w-1/2">
                <div className="flex flex-col w-64">
                  <label for="due">Supplier Name</label>
                  <input
                    type="text"
                    name="supplier name"
                    id="supplier"
                    value={searchTerm}
                    placeholder="Supplier name"
                    className=" w-64 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                    required
                    onChange={async (e) => setSearchTerm(e.target.value)}
                  ></input>
                  {resultVendors.length !== 0 && (
                    <div className="flex absolute z-50 w-84 max-h-72 rounded shadow bg-white overflow-hidden peer-checked:flex flex-col mt-12 border border-gray-200">
                      {resultVendors.map((each) => (
                        <div class="cursor-pointer group">
                          <button
                            onClick={() => handleVendorClick(each)}
                            className="block w-full p-2 border-transparent border-l-4 hover:border-blue-600 hover:bg-gray-100 text-left"
                          >
                            {each.VENDOR_NAME}
                          </button>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              </div>
              <div className="flex justify-end w-1/2 space-x-3">
                <div className="flex space-x-2">
                  {/* PO number */}
                  <div>
                    <label for="name" className="flex w-full ">
                      G-Name [prefix: {gname}]
                    </label>
                    <input
                      type="number"
                      name="name"
                      id="name"
                      // disabled={true}
                      value={runningNumber}
                      placeholder="Running Number"
                      className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white w-44"
                      required
                      onChange={e => {
                        let running;
                        if (parseFloat(e.target.value) > 9999) {
                          return enqueueSnackbar('Sequence error')
                        } else {
                          running = parseFloat(e.target.value).toString().padStart(4, '0')
                        }
                        setRunningNumber(running)
                      }}
                    ></input>
                  </div>
                  {/* Rel number */}
                  <div className="ml-3 space-y-2">
                    {emails &&
                      emails.map((email, index) => (
                        <div className="space-x-2">
                          {index === 0 && <label for="due">Email List</label>}
                          <div className="flex">
                            <input
                              type="text"
                              name="emails"
                              id="emails"
                              value={emails[index]}
                              placeholder="Emails List"
                              className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-60 p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                              required
                              onChange={async (e) =>
                                handleChangeEmail(e.target.value, index)
                              }
                            ></input>
                            {index == 0 && (
                              <button
                                className="p-2 ml-2 rounded-lg bg-indigo-500"
                                onClick={() => {
                                  let newEmails = [...emails];
                                  newEmails.push('');
                                  setEmails(newEmails);
                                }}
                              >
                                <AiOutlinePlusCircle className="w-5 h-5  text-white" />
                              </button>
                            )}
                            {index == 0 && emails.length > 1 && (
                              <>
                                {' '}
                                <button
                                  className="p-2 ml-1 rounded-lg bg-gray-500"
                                  onClick={() => {
                                    let newEmails = [...emails];
                                    newEmails.pop();
                                    setEmails(newEmails);
                                  }}
                                >
                                  <AiOutlineMinusCircle className="w-5 h-5 text-white" />
                                </button>
                              </>
                            )}
                          </div>
                        </div>
                      ))}
                  </div>
                </div>
                <div>
                  <label for="name" className="flex w-full ">
                    Description:
                  </label>
                  <input
                    type="text"
                    name="desc"
                    id="desc"
                    value={desc}
                    placeholder="Description"
                    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white w-72"
                    required
                    onChange={e => setDesc(e.target.value)}
                  ></input>
                </div>
                <div className="flex mt-5 h-10 shadow-lg">
                  <button
                    onClick={handleSubmit}
                    className="bg-indigo-500 text-white rounded-md p-2 "
                  >
                    Submit
                  </button>
                </div>
              </div>
            </div>
          )}
          <div className="flex w-full">
            <div className="flex w-1/2 mx-5 space-x-2 mt-3">
              <input
                type="text"
                name="supplier"
                id="supplier"
                placeholder="Search Supplier Name"
                className="bg-gray-50 border w-60 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) => setSearchSupplier(e.target.value)}
              ></input>
            </div>
            <div className="flex flex-col w-1/2 right-0 mr-5 z-10 pl-2 pt-3 bg-slate-700 ">
              <div className="flex space-x-2 w-full justify-end">
                <div>
                  <Pagination
                    selectablePage={selectablePage}
                    activePage={activePage}
                    previousPage={previousPage}
                    nextPage={nextPage}
                    gotoLastPage={gotoLastPage}
                    gotoFirstPage={gotoFirstPage}
                    setActivePage={setActivePage}
                  />
                </div>
                <div className="flex space-x-2 justify-end">
                  <div className="flex mt-1 mr-2 text-gray-500 font-jamjuree">
                    Total Record: {totalEmailgroup}
                  </div>
                  <ListBox
                    width={'w-20'}
                    data={pageSizeList}
                    type="event pagination"
                    multiple={false}
                    handleSelected={handlePageSizeSelected}
                    defaultValue={pageSizeSelected}
                  />
                </div>
                {(getUserType() == 'store' || getUserType() == 'admin') && (
                  <div className="flex h-9 shadow-md">
                    <button
                      onClick={() => setShow(true)}
                      className="rounded-md bg-green-500 p-2"
                    >
                      <BsClipboardPlus className="w-5 h-5" />
                    </button>
                  </div>
                )}
              </div>
            </div>
          </div>
          {loading && <Loading />}
          <div className="mt-20 mx-5 w-auto overflow-hidden">
            <Table
              columns={columns}
              data={emailGroups}
              manualPagination={true}
              showSelect={false}
              handleSelect={(selected) => console.log(selected)}
              detailComponents={() => {}}
            />
          </div>
        </div>
      );
    }
    return (
        <div className="flex justify-center w-auto h-full items-center overflow-hidden">
            <div
                style={{ borderTopColor: 'transparent' }}
                className="w-16 h-16 border-4 border-blue-400 border-dotted rounded-full animate-spin"
            ></div>
        </div>
    );
}

export default EmailGroupTable;
