import React from 'react';
// import Radio from '@material-ui/core/Radio';
// import Grid from '@material-ui/core/Grid';
// import FormControlLabel from '@material-ui/core/FormControlLabel';
import Received from './Receive_box';
import Stockout from './Stockout';
import DoToInv from './DoToInv';
// import useKeyboardEvent from './useKeyboardEvent';
import { DataContextMobile } from '../ContextData_Mobile';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import AppBar from '@material-ui/core/AppBar';
import _, { map } from 'lodash';
import BarcodeReader from 'react-barcode-reader';
import NetworkDetector from '../utils/NetworkDetector';
import { checkSubInv, checkValidHeader } from '../utils/apis';

// TODO -- create array of box scanned data
/*
  {
      invoice_vendor_id:
          [
            {
              name: item_name, 
              scannedValue: count_num_scan,
              totalValue: total,
              scannedTag: [seq_array],
              compare: to_compare
            },
            {
              name: item_name, 
              scannedValue: count_num_scan,
              totalValue: total,
              scannedTag: [seq_array],
              compare: to_compare
            }
        ],
      invoice_vendor_id:
        [
          {
            name: item_name, 
            scannedValue: count_num_scan,
            totalValue: total,
            scannedTag: [seq_array],
            compare: to_compare
          },
          {
            name: item_name, 
            scannedValue: count_num_scan,
            totalValue: total,
            scannedTag: [seq_array],
            compare: to_compare
          }
        ],
      }
    }
  }
*/

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      style={{ maxWidth: '600px', margin: 'auto' }}
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box p={2}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

function Scanner(props) {
  // console.log(props);
  // const [selectedValue, setSelectedValue] = React.useState('a');
  const mainRef = React.useRef();
  const [state, setState] = React.useContext(DataContextMobile);
  const [tempData, setTempData] = React.useState(null);
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const checkItem = async (PO, ORG, ITEM, REL) => {
    let result = await checkSubInv(PO, ORG, ITEM, REL);
    console.log(result);
    return result.Error === 1 ? true : false;
  };

  const verifiedData = async (data) => {
    if (data) {
      if (data.split('|')[0] === '0') {
        // check data with server here
        if (data.split('|')[9]) {
          let result = await checkValidHeader(data.split('|')[9]);
          if (result.status !== 'transit') {
            return setState((state) => ({
              ...state,
              warning: 'PSC ไม่มีสถานะการขนส่ง',
            }));
          }
        }

        if (data.split('|')[3] !== localStorage.getItem('COMPANY_ID')) {
          return setState((state) => ({
            ...state,
            warning: `ใบกำกับสินค้านี้ไม่ได้เป็นของ ${localStorage.getItem(
              'COMPANY_NAME'
            )}`,
          }));
        }

        // append new item to item code array
        let newqrCodeItem = state.qrCodeItem;
        let newqrCodeMain = state.qrCodeMain;
        let newreceiveNo = state.receiveNo;
        if (!state.qrCodeMain.includes(data)) {
          newqrCodeMain.push(data);
          newreceiveNo.push('');
          newqrCodeItem[data] = [];
        } else {
          return setState((state) => ({ ...state, warning: 'สแกนซ้ำ' }));
        }

        // clear other data TODO qrcode main to be as array
        return setState((state) => ({
          ...state,
          qrCodeMain: newqrCodeMain,
          qrCodeMainChange: '',
          qrCodeItem: newqrCodeItem,
          items: [],
          warning: null,
        }));
      } else if (data.split('|')[0] === '2') {
        // This is change from DO to Invoice
        // set active to second radio
        setValue(1);
        return setState((state) => ({
          ...state,
          qrCodeMainChange: data,
          warning: null,
        }));
      } else if (data.split('|')[0] === '3') {
        let qrtagdata = data.split('|');
        // check if item inside existing main qrcode
        if (state.qrCodeMain.length === 0) {
          // check invoice_no & vendor_no && po_no && lot_no
          let exist = _.every(
            state.qrCodeMain,
            (item) =>
              item.split('|')[2] !== qrtagdata[9] &&
              item.split('|')[8] !== qrtagdata[12] &&
              item.split('|')[7] !== qrtagdata[3] &&
              item.split('|')[4] !== qrtagdata[10]
          );

          if (!exist) {
            return setState((state) => ({
              ...state,
              warning: 'สแกนส่วนหัวก่อน',
            }));
          }
        }
        // TO DO get the correct item array first
        state.qrCodeMain.map((mainItem) => {
          if (
            mainItem.split('|')[2] === qrtagdata[9] &&
            mainItem.split('|')[8] === qrtagdata[12] &&
            mainItem.split('|')[7] === qrtagdata[3]
          ) {
            _.map(state.qrCodeItem[mainItem], (item, index) => {
              // duplicated scann by check id with list of array
              if (item.scannedTag.includes(qrtagdata[1])) {
                return setState((state) => ({ ...state, warning: 'สแกนซ้ำ' }));
              }
              // check if tag not yet scanned & same item name
              if (
                item.compare ===
                  qrtagdata[6] + '|' + qrtagdata[11] + '|' + qrtagdata[5] &&
                !item.scannedTag.includes(qrtagdata[1])
              ) {
                console.log('here');
                setState((state) => ({ ...state, warning: '' }));
                let newqrCodeItem = state.qrCodeItem;
                newqrCodeItem[mainItem][index] = {
                  name: item.name,
                  scannedValue: parseInt(
                    parseFloat(item.scannedValue) + parseFloat(qrtagdata[8])
                  ),
                  totalValue: item.totalValue,
                  scannedTag: [...item.scannedTag, qrtagdata[1]],
                  compare: item.compare,
                  totalTag: item.totalTag,
                  itemData: item.itemData,
                };
                return setState((state) => ({
                  ...state,
                  waring: '',
                  qrCodeItem: newqrCodeItem,
                }));
              }
            });
          }
        });
      } else if (data.split('|')[0] === '1') {
        // qrcode item to be as json of key invoice_no_vendor_id

        // check if item inside existing main qrcode
        if (state.qrCodeMain.length !== 0) {
          // check invoice_no & vendor_no && po_no
          console.log(state.qrCodeMain, data);
          let exist = _.some(
            state.qrCodeMain,
            (item) =>
              item.split('|')[2] === data.split('|')[1] &&
              item.split('|')[8] === data.split('|')[13] &&
              item.split('|')[7] === data.split('|')[2]
          );

          if (!exist) {
            return setState((state) => ({
              ...state,
              warning: 'สแกนส่วนหัวก่อน',
            }));
          }

          // check duplicated if main QR code exist only
          let existItem = state.qrCodeMain.map((mainItem) => {
            let existingMain = _.map(state.qrCodeItem[mainItem], 'itemData');
            console.log(existingMain, data);
            console.log(existingMain.includes(data));
            return existingMain.includes(data);
          });

          console.log(existItem);
          if (existItem.includes(true)) {
            return setState((state) => ({ ...state, warning: 'สแกนซ้ำ' }));
          }
        } else {
          return setState((state) => ({
            ...state,
            warning: 'สแกนส่วนหัวก่อน',
          }));
        }

        async function checkInventoryAsyc() {
          // check only "LINE_TYPE_ID" = 1
          if (data.split('|')[14] && data.split('|')[14] === '1') {
            let valideItem = await checkItem(
              data.split('|')[2],
              data.split('|')[10],
              data.split('|')[5],
              data.split('|')[3]
            );
            console.log(valideItem);
            if (valideItem)
              return setState((state) => ({
                ...state,
                warning: 'Item Invalid, ไม่มี Sub-inventory !!!',
              }));
          }

          // TO DO get the correct item array first
          state.qrCodeMain.map((mainItem) => {
            console.log(mainItem, data);
            if (
              mainItem.split('|')[2] === data.split('|')[1] &&
              mainItem.split('|')[8] === data.split('|')[13] &&
              mainItem.split('|')[7] === data.split('|')[2]
            ) {
              let newqrCodeItem = state.qrCodeItem;
              newqrCodeItem[mainItem].push({
                name: data.split('|')[5],
                scannedValue: 0,
                totalValue: parseFloat(data.split('|')[6]),
                scannedTag: [],
                totalTag: data.split('|')[7],
                compare:
                  data.split('|')[5] +
                  '|' +
                  data.split('|')[12] +
                  '|' +
                  data.split('|')[4],
                itemData: data,
              });
              setState((state) => ({
                ...state,
                waring: '',
                qrCodeItem: newqrCodeItem,
              }));
            }
          });
        }
        // run above function
        checkInventoryAsyc();
      } else {
        return setState((state) => ({ ...state, employeeID: data }));
      }
    }
  };

  React.useEffect(() => {
    // console.log(tempData && tempData.split('|'));
    // check data accuracy
    verifiedData(tempData);
  }, [tempData]);

  console.log(state);

  return (
    <div>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Received" ref={mainRef} />
          <Tab label="DO TO INVOICE" />
        </Tabs>
      </AppBar>
      <Typography
        style={{
          marginRight: 20,
          color: 'gray',
          fontSize: 12,
          fontFamily: 'Bai Jamjuree',
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        * Pull to refresh on mobile (v20211110-01)
      </Typography>
      <TabPanel value={value} index={0}>
        <Received isDisconnected={props.isDisconnected} mainRef={mainRef} />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <DoToInv isDisconnected={props.isDisconnected} />
      </TabPanel>
      <BarcodeReader
        onError={(err) => alert('err', err)}
        onScan={(data) => {
          setTempData(data);
        }}
        timeBeforeScanTest={500}
      />
    </div>
  );
}

export default NetworkDetector(Scanner);
