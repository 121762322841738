import React from 'react';
import {
  useTable,
  useFilters,
  useGlobalFilter,
  useSortBy,
  usePagination,
  useRowSelect,
  useExpanded,
} from 'react-table';
// import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";

const IndeterminateCheckbox = React.forwardRef(
  ({ indeterminate, ...rest }, ref) => {
    const defaultRef = React.useRef();
    const resolvedRef = ref || defaultRef;

    React.useEffect(() => {
      resolvedRef.current.indeterminate = indeterminate;
    }, [resolvedRef, indeterminate]);

    return (
      <>
        <input
          type="checkbox"
          className="w-5 h-5 rounded"
          ref={resolvedRef}
          {...rest}
        />
      </>
    );
  }
);

function Table({ columns, data, showSelect, handleSelect, detailComponents }) {
  // Use the state and functions returned from useTable to build your UI
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    rows, // Instead of using 'rows', we'll use page,
    // which has only the rows for the active page
    state,
    selectedFlatRows,
    visibleColumns,
  } = useTable(
    {
      columns,
      data,
    },
    useFilters, // useFilters!
    useGlobalFilter,
    useSortBy,
    useExpanded,
    usePagination, // new
    useRowSelect,
    (hooks) => {
      showSelect &&
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            Header: ({ getToggleAllRowsSelectedProps }) => (
              <div>
                <IndeterminateCheckbox {...getToggleAllRowsSelectedProps()} />
              </div>
            ),
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => (
              <div>
                <IndeterminateCheckbox {...row.getToggleRowSelectedProps()} />
              </div>
            ),
          },
          ...columns,
        ]);
    }
  );

  React.useEffect(() => {
    handleSelect(selectedFlatRows);
  }, [selectedFlatRows]);

  // console.log(rows);
  // Render the UI for your table
  return (
    <>
      <div className="sm:flex sm:gap-x-2">
        {headerGroups.map((headerGroup) =>
          headerGroup.headers.map((column) =>
            column.Filter ? (
              <div
                className="mt-2 sm:mt-0 border-gray-200 border p-2 rounded-md"
                key={column.id}
              >
                {column.render('Filter')}
              </div>
            ) : null
          )
        )}
      </div>
      {/* table */}
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-z -mx-2 sm:-mx-2 lg:-mx-2">
          <div className="py-2 align-middle inline-block min-w-full sm:px-2 lg:px-2">
            <div className="shadow overflow-hidden border-b border-gray-200 sm:rounded-lg">
              <table
                {...getTableProps()}
                className="min-w-full divide-y divide-gray-200"
              >
                <thead className="bg-gray-50">
                  {headerGroups.map((headerGroup) => (
                    <tr {...headerGroup.getHeaderGroupProps()}>
                      {headerGroup.headers.map((column) => (
                        // Add the sorting props to control sorting. For this example
                        // we can add them into the header props
                        column.hidden
                        ? null
                        :<th
                          key={column.id}
                          scope="col"
                          className="group px-3 py-2 text-left text-xs font-medium font-jamjuree text-gray-500 uppercase tracking-wider"
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <div className="flex items-center justify-between">
                            {column.render('Header')}
                          </div>
                        </th>
                      ))}
                    </tr>
                  ))}
                </thead>
                <tbody
                  {...getTableBodyProps()}
                  className="bg-white divide-y divide-gray-200"
                >
                  {rows.map((row, i) => {
                    // new
                    prepareRow(row);
                    return (
                      <>
                        <tr {...row.getRowProps()}>
                          {row.cells.map((cell, cIndex) => {
                            return (
                              cell.column.hidden
                              ? null
                              : <td
                                key={cIndex}
                                {...cell.getCellProps()}
                                className="px-3 py-2 whitespace-nowrap"
                                role="cell"
                              >
                                {cell.column.Cell.name === 'defaultRenderer' ? (
                                  <div className="text-sm text-gray-500">
                                    {cell.render('Cell')}
                                  </div>
                                ) : (
                                  cell.render('Cell')
                                )}
                              </td>
                            );
                          })}
                        </tr>
                        {row.isExpanded ? (
                          <tr>
                            <td colSpan={visibleColumns.length}>
                              {detailComponents({ row })}
                            </td>
                          </tr>
                        ) : null}
                      </>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default Table;
