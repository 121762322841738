
import { createMuiTheme } from '@material-ui/core/styles';
import red from '@material-ui/core/colors/red';

const AapicoTheme = createMuiTheme({
    palette: {
        primary: {
          main: "#1D366D"
        },
        secondary: {
          main: "#2CC84D"
        },
        error: red,
        contrastThreshold: 3,
        tonalOffset: 0.2,
    },
    // Use the system font instead of the default Roboto font.
    typography: {
        fontFamily: "Bai Jamjuree"
    },
    // overrides default theme css
    overrides: {
        // targeting refresh button
        MuiInputLabel: { 
          root: {
            fontFamily: ['"Bai Jamjuree"', 'Arial'].join(','),
            fontSize: 14
          }
        },
        MuiInputBase: { 
          root: {
            fontFamily: ['"Bai Jamjuree"', 'Arial'].join(','),
            fontSize: 14
          }
        },
        // header of table in bold
        MuiTableRow: {
          head: {
            fontFamily: ['Bai Jamjuree', 'Arial'].join(','),
            backgroundColor: 'lightgray',
            "& > th ": {
              color: 'black',
              fontWeight: 'bold',
            }
          },
          root: {
            fontFamily: ['Bai Jamjuree', 'Arial'].join(','),
            '&:hover': {
              // color: '#bbdefb',
              backgroundColor: 'lightgray',
            },
          },
        }
      }
});

export default AapicoTheme;