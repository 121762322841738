import * as React from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';
import { updateUserAlert } from '../utils/apis';
import { IoAdd } from "react-icons/io5";

const Dialogs = ({
  open = false,
  onConfirm,
  children,
  onClose
}) => {
  const handleOnConfirm = () => {
    onConfirm()
    onClose()
  }
  return (
    <Dialog
      open={open}
      PaperProps={{
        style: {
          minHeight: '500px',
          boxShadow: 'none',
          fontFamily: 'Bai Jamjuree',
          borderRadius: '12px',
        },
      }}
    >
      <DialogTitle className='text-2xl font-bold font-jamjuree bg-blue-800 '>
      <div className={`flex justify-between`}
                onClick={onClose}>
                <div className={`flex items-center text-white`}>Upload File </div>
                <div className={`transform rotate-45 cursor-pointer text-white`}><IoAdd color={`#FFFFFF`} variant='Linear' size={`60px`} /></div>
      </div>
      </DialogTitle>
      <div>{children}</div>
      <DialogActions >
        <div
          className={`w-32 h-12 bg-indigo-500 rounded-md select-none cursor-pointer min-w-[120px] text-lg text-white font-semibold items-center flex justify-center
          hover:bg-indigo-700
          `}
          onClick={handleOnConfirm}
          
        >
          Confirm
        </div>
      </DialogActions>
    </Dialog>

    //     {/* dialog peelawit 08/01/67 */}
    //     <Dialog
    //     open={dialogUpload.open}
    //     PaperProps={{
    //       className: `min-w-[350px]`
    //     }}
    //   >
    //     <DialogTitle className='text-2xl font-semibold'>Confirm Import </DialogTitle>
    //     <DialogContent className=' font-semibold'>Are you sure Import file :  </DialogContent>
    //     <DialogActions >
    //       <Button
    //         className='font-semibold'
    //         variant='outlined'
    //         onClick={() => {
    //           setDialogUpload(prev => ({
    //             ...prev,
    //             open: false
    //           }))
    //         }}
    //       >
    //         Cancel
    //       </Button>
    //       <Button
    //         className='font-medium'
    //         variant='contained'
    //         onClick={() => {
    //           // importFile(fileName)
    //           setDialogUpload(prev => ({
    //             ...prev,
    //             open: false
    //           }))
    //         }}
    //       >
    //         Confirm
    //       </Button>
    //     </DialogActions>
    //   </Dialog>
  )
}
export default Dialogs
