import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import { isMobile } from 'react-device-detect';

const NotFound = () => (
  <>
    <img
      src={'/404-error.svg'}
      width={120}
      alt="Page Not Found"
      style={{ margin: 'auto', display: 'flex', marginTop: '30px' }}
    />
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        fontFamily: 'Bai Jamjuree',
      }}
    >
      <h1>404 - Not Found!</h1>
    </div>
    <div style={{ display: 'flex', justifyContent: 'center' }}>
      <Button variant="contained">
        <Link
          to={`${isMobile ? '/' : '/table'}`}
          style={{ textDecoration: 'none', fontFamily: 'Bai Jamjuree' }}
        >
          Go To Home Page
        </Link>
      </Button>
    </div>
  </>
);

export default NotFound;
