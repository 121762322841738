import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import IconButton from '@material-ui/core/IconButton';
import EditIcon from '@material-ui/icons/Edit';
import Dialog from './dialog';

import { getUserType, vendor_id } from '../../utils';

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: '0 8px 20px -6px rgba(0,0,0,0.3)',
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const Iframe = (props) => {
  return (
    <div
      style={{ width: '100%' }}
      onContextMenu={(e) => e.preventDefault()}
      dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }}
    />
  );
};

export default function CustomizedAccordions({ calendar, maps, reload }) {
  console.log(`Calendar`, calendar, maps);
  const [selected, setSelected] = React.useState();
  const [userType, setUserType] = React.useState(getUserType());
  const [expanded, setExpanded] = React.useState('panel1');
  const [show, setShow] = React.useState(false);

  const handleChange = (panel) => (event, newExpanded) => {
    setExpanded(newExpanded ? panel : false);
  };

  return (
    <div>
      <Dialog open={show} setOpen={setShow} data={selected} reload={reload} />
      <Accordion
        square
        onChange={handleChange('panel1')}
        style={{ borderRadius: 10 }}
      >
        <AccordionSummary aria-controls="panel1d-content" id="panel1d-header">
          <Grid container justify="space-between">
            <Typography>{calendar[0].title}</Typography>
            {(userType.includes('admin') ||
              getUserType().includes('store')) && (
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  setSelected(calendar[0]);
                  setShow(true);
                }}
                size="medium"
              >
                <EditIcon />
              </IconButton>
            )}
          </Grid>
        </AccordionSummary>
        <AccordionDetails>
          <Iframe
            iframe={`<iframe id="center-iframe" height="500" style="width: 100%;" src="https://smp.aapico.com/${calendar[0].files[0].url}"></iframe>`}
          />
        </AccordionDetails>
      </Accordion>
      {/* {maps && maps.map((item, index) => {
                    return <Accordion square expanded={expanded === 'panel2'} onChange={handleChange('panel2')} style={{ borderBottomLeftRadius: 10, borderBottomRightRadius: 10 }}>
                        <AccordionSummary aria-controls="panel2d-content" id="panel2d-header">
                            <Grid container justify="space-between">
                                <Typography>{item.title}</Typography>
                                {userType.includes("admin") && <IconButton onClick={(e) => {
                                    e.stopPropagation();
                                    setSelected(item);
                                    setShow(true)
                                }} size="medium">
                                    <EditIcon />
                                </IconButton>}
                            </Grid>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Iframe
                                iframe={`<iframe id="center-iframe" height="500" style="width: 100%;" src=${'http://www.pdf995.com/samples/pdf.pdf'
                                    }></iframe>`}
                            />
                        </AccordionDetails>
                    </Accordion>
                })} */}
    </div>
  );
}
