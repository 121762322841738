import React from "react";
import moment from "moment";
import { BsCloudUpload } from 'react-icons/bs';
import {
  uploadConfirmASN,
  sendingEmail,
  updateConfirmASN,
  getStoreEmail,
} from '../../utils/apis';
import { username } from '../../utils';
import Loading from '../../components/Loading';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

function ConfirmASN({ data, refetch, setShowConfirm }) {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);
  const [file, setFile] = React.useState();
  const [deliveryDate, setDeliveryDate] = React.useState(
    new moment().format('YYYY-MM-DD')
  );

  const handleFileUpload = async (e) => {
    const file = e.target.files[0];
    console.log(file);
    setFile(file);
  };

  const handleSubmit = async () => {
    if (!file) {
      return enqueueSnackbar('No upload file', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 300000,
      });
    }

    setLoading(true);
    let formData = new FormData();
    formData.append('files.attachment', file);
    formData.append(
      'data',
      JSON.stringify({
        delivery_date: deliveryDate,
        confirm_po: data.id,
        uploader: username(),
      })
    );
    let uploadResult = await uploadConfirmASN(formData);
    console.log(uploadResult);
    if (uploadResult.status !== 200) {
      return enqueueSnackbar('Upload fail', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'top',
          horizontal: 'right',
        },
        autoHideDuration: 300000,
      });
    }
    let uploadOutput = await uploadResult.json();
    // sending email notification and update record
    // get factory store email
    let store = await getStoreEmail('store', data.factory);
    let storeEmail = _.map(store, (each) => each.email_alert);
    console.log(storeEmail.join(), data.receiver_email);
    let emailResult = await sendingEmail({
      to: data.receiver_email,
      cc: storeEmail.join(),
      subject: 'Automatic PO Confirmation',
      templateReferenceId: 2, // template number
      data: {
        url: 'https://smp.aapico.com/#/confirm-po',
        supplier: data.supplier_code,
        po: data.po_number,
        rel: data.rel_number,
        deliveryDate: uploadOutput.delivery_date,
      },
    });

    if (emailResult.status !== 200) {
      setLoading(false);
      refetch();
      setShowConfirm(false);
      return enqueueSnackbar('Email not sent', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 300000,
      });
    } else {
      // update email sent status
      await updateConfirmASN(uploadOutput.id, { email_sent: true });
      setLoading(false);
      refetch();
      setShowConfirm(false);
      return enqueueSnackbar('Email sent successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 300000,
      });
    }
  };

  return (
    <div>
      <span className="flex justify-center text-lg font-bold w-full m-2">
        Confirm ASN
      </span>
      {loading && <Loading />}
      <div className="flex flex-col w-full p-5 space-y-2">
        <div className="bg-gray-50 p-5 space-y-2 rounded-md border border-gray-300">
          <div className="w-full space-y-2">
            <label for="due">PO. No.</label>
            <input
              type="text"
              name="po"
              id="po"
              disabled
              defaultValue={data.po_number}
              placeholder="Po No."
              className="w-full disabled:opacity-25 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) => {}}
            ></input>
          </div>
          <div className="w-full space-y-2">
            <label for="due">Rel. No.</label>
            <input
              type="text"
              name="rel"
              id="rel"
              disabled
              value={data.rel_number}
              placeholder="Rel No."
              className="w-full disabled:opacity-25 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) => {}}
            ></input>
          </div>
          <div className="w-full space-y-2">
            <label for="due">Due Date</label>
            <input
              type="text"
              name="due"
              id="due"
              disabled
              value={data.due_date}
              placeholder="Due Date"
              className="w-full disabled:opacity-25 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) => {}}
            ></input>
          </div>
        </div>
        <div className="border border-gray-200 bg-gray-50 rounded-md p-4">
          <div className="flex justify-between">
            <div>
              <label for="delivery">Delivery Date</label>
              <input
                type="date"
                name="delivery"
                id="delivery"
                value={deliveryDate}
                placeholder="Due Date"
                className="bg-gray-50 w-36 h-[32px]
               border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) => setDeliveryDate(e.target.value)}
              ></input>
            </div>
            <div className="flex space-x-2">
              <div className="flex flex-col space-y-2 mt-5">
                <div className="flex w-8 my-auto justify-center hover:cursor-pointer shadow-md ml-auto space-x-2 bg-indigo-500 rounded-lg h-10 text-white align-middle">
                  <label
                    for="uploadConfirm"
                    class="flex flex-col items-center cursor-pointer"
                  >
                    <div className="flex w-auto m-auto space-x-2 justify-center">
                      <BsCloudUpload className="w-4 h-4 m-auto" />
                    </div>
                  </label>
                  <input
                    id="uploadConfirm"
                    type="file"
                    class="hidden"
                    accept="application/pdf"
                    onChange={(e) => {
                      console.log('upload');
                      handleFileUpload(e);
                    }}
                    onClick={(event) => {
                      console.log('upload');
                      event.target.value = null;
                    }}
                  />
                </div>
                {file && (
                  <p className="text-white z-50 bg-indigo-500 px-2 rounded-md">
                    {file.name}
                  </p>
                )}
              </div>
              <button
                onClick={(e) => {
                  setFile();
                }}
                className="bg-gray-400 p-2 w-auto rounded-md h-10 mt-5 shadow-lg"
              >
                Clear
              </button>
            </div>
          </div>
          {/* upload button */}
          <div className="flex mt-5 h-10 justify-end">
            <button
              onClick={handleSubmit}
              className="bg-indigo-500 text-white rounded-md p-2 "
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmASN;