import React, { useState } from 'react';
import { Table } from '../components/Table';
import settings from '../config';
import moment from 'moment';
import { Typography, Button, Grid } from '@material-ui/core';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  header,
  vendor_id,
  getUserType,
  company_name,
  company_id,
} from '../utils';
import {
  deleteDelivery,
  doToInvoice,
  checkStatus,
  createReceived,
  receivedDelivery,
  receivedDeliveryInternal,
  transits,
  createReceivedInternal,
} from '../utils/apis';
import DeliveryPDF from './home/DelieveryPDF';
import DeliveryPDFOSP from './home/DelieveryPDFOSP';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import SendIcon from '@material-ui/icons/Send';
import SyncIcon from '@material-ui/icons/Sync';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import DoneIcon from '@material-ui/icons/Done';
import Dialog from '@material-ui/core/Dialog';
import Chip from '@material-ui/core/Chip';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import { useSnackbar } from 'notistack';
import { DataContext } from '../ContextData';
import _ from 'lodash';
import SearchBox from './SearchBox';
import ReactLoading from 'react-loading';
import { useConfirm } from 'material-ui-confirm';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import companiesList from '../utils/company_code';

function App() {
  const confirm = useConfirm();
  const [state, setState] = React.useContext(DataContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [show, setShow] = React.useState(false);
  const [deleteId, setDeleteId] = React.useState(null);
  const [showInvoice, setShowInvoice] = React.useState(false);
  const [showDelete, setShowDelete] = React.useState(false);
  const [selectedData, setSelectedData] = React.useState(null);
  const [invoiceNo, setInvoiceNo] = React.useState(null);
  const [newInvoiceDate, setNewInvoiceDate] = React.useState('');
  const [refresh, setRefresh] = React.useState(false);
  const handleClose = () => setShow(false);
  const handleCloseInvoice = () => setShowInvoice(false);
  const handleCloseDelete = () => setShowDelete(false);
  const handleDelete = async () => {
    // check status
    await deleteDelivery(deleteId, remarks);
    handleCloseDelete();
    setRefresh(!refresh);

    // confirm({ description: `This will permanently delete this delivery` })
    //   .then(() => {
    //     deleteDelivery(deleteId, remarks).then((res)=> {
    //       handleCloseDelete();
    //       setRefresh(!refresh);
    //     })
    //   })
    //   .catch(() => console.log("Deletion cancelled."));
  };

  // recalculate transit
  const getTransits = async () => {
    await transits().then(async (res) => {
      let result = await res.json();
      setState((state) => ({ ...state, currentTransits: result || [] }));
    });
  };

  const handleReceived = (original) => {
    let toSubmitData = [];
    original.details.poSelect.map((po, index) => {
      toSubmitData.push({
        po: po.PO_NUMBER,
        rel: po.RELEASE_NUM,
        line: po.LINE_NUM,
        inv: original.details.invoiceNo,
        qty: original.details.delAmount[index],
        supplier_lot:
          original.details.lotSup[index] ||
          moment(original.details.deliveryDate).format('YYYYMMDD'),
        aapico_lot: moment(original.details.deliveryDate).format('YYYYMMDD'),
        inv_orgid: po.INV_ORGID,
        delivery_date: moment(original.details.deliveryDate).format('YYYYMMDD'),
        invoice_date: moment(original.details.invoiceDate).format('YYYYMMDD'),
        site: original.details.site.VENDOR_SITE_CODE,
        isInvoice: original.details.isInvoice,
      });
    });
    // alert(JSON.stringify(toSubmitData));
    createReceivedInternal(toSubmitData).then((res) => {
      if (res.Status === 'ERROR') {
        return enqueueSnackbar(res.Message, {
          variant: 'error',
        });
      }
      //   receivedDelivery(original.id, res).then(res => setRefresh(!refresh));
      receivedDeliveryInternal(
        original.id,
        original.details.isInvoice,
        'system',
        res
      ).then(async (result) => {
        if (result.status === 200) {
          setRefresh(!refresh);
          enqueueSnackbar('Received successfully', {
            variant: 'success',
            anchorOrigin: {
              vertical: 'bottom',
              horizontal: 'right',
            },
          });
        } else {
          let data = await result.json();
        }
      });
    }); // this will forward data to ORACLE
  };
  const handleToInvoice = () => {
    if (!invoiceNo)
      return enqueueSnackbar('No invoice number provide', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    if (!newInvoiceDate)
      return enqueueSnackbar('Please input invoice date', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    let data = selectedData.details;
    doToInvoice(
      data,
      invoiceNo,
      selectedData.id,
      data.poSelect[0].INV_ORGID,
      newInvoiceDate
    ).then((res) => {
      if (res.Status === 'ERROR') {
        enqueueSnackbar(res.Message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      } else {
        enqueueSnackbar('Change Success', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
      }
      setRefresh(!refresh);
      // TODO change QR code in POSC
      handleCloseInvoice();
    });
  };

  const columns = [
    {
      Header: 'User',
      accessor: 'user',
      canSort: false,
    },
    {
      Header: 'Invoice',
      accessor: 'invoiceNo',
      width: 150,
      Cell: ({ row: { original } }) => {
        if (original.details.isInvoice)
          return (
            <Button
              color={'primary'}
              style={{ fontFamily: 'Bai Jamjuree', fontSize: 14 }}
              onClick={() => {
                setShow(true);
                setSelectedData(original);
              }}
            >
              {original.details.invoiceNo}
            </Button>
          );
        return null;
      },
    },
    {
      Header: 'DO',
      accessor: 'do',
      Cell: ({ row: { original } }) => {
        if (!original.details.isInvoice)
          return (
            <Button
              color={'primary'}
              style={{ fontFamily: 'Bai Jamjuree', fontSize: 14 }}
              onClick={() => {
                setShow(true);
                setSelectedData(original);
              }}
            >
              {original.details.invoiceNo}
            </Button>
          );
        if (original.details.isInvoice)
          return (
            <Button
              color={'primary'}
              style={{ fontFamily: 'Bai Jamjuree', fontSize: 14 }}
              onClick={() => {
                setShow(true);
                setSelectedData(original);
              }}
            >
              {original.details.doNo}
            </Button>
          );
        return null;
      },
    },
    {
      Header: 'PO No.',
      accessor: 'po_no',
      Cell: ({ row: { original } }) => {
        return original.details.poSelect[0].PO_NUMBER;
      },
    },
    {
      Header: 'REL No.',
      accessor: 'release_no',
      Cell: ({ row: { original } }) => {
        return original.details.poSelect[0].RELEASE_NUM
          ? original.details.poSelect[0].RELEASE_NUM
          : '';
      },
    },
    {
      Header: 'Delivery QTY',
      accessor: 'delivery_qty',
      Cell: ({ row: { original } }) => {
        return _.sum(original.details.delAmount.map((item) => parseInt(item)));
      },
    },
    {
      Header: 'Delivery Date',
      accessor: 'delivery_date',
      Cell: ({ row: { original } }) => {
        return original.details.deliveryDate;
      },
    },
    {
      Header: 'Created At',
      Cell: ({ row: { original } }) => {
        // console.log(original);
        return new moment(original.createdAt).format('YYYY-MM-DD HH:mm');
      },
    },
    // TODO create local database or hard-code it here.
    {
      Header: 'Company',
      accessor: 'company',
      // this can remove after if company already unified
      Cell: ({ row: { original } }) => {
        let destCompany = _.filter(companiesList, (item) => {
          return (
            item.INVENTORY_ORG_ID ===
            original.details.poSelect[0].INV_ORGID.toString()
          );
        });
        return destCompany[0].NAME || '';
      },
      width: 200,
    },
    {
      Header: 'Status',
      accessor: 'status',
      Cell: ({ row: { original } }) => {
        if (original.status === 'deleted') {
          return (
            <Chip
              color="secondary"
              size="small"
              label="Deleted"
              icon={<DeleteForeverIcon />}
            />
          );
        }
        if (original.status === 'transit') {
          return (
            <Chip
              color="primary"
              size="small"
              label="Transit"
              icon={<SendIcon />}
            />
          );
        }
        if (original.status === 'transit_demo') {
          return (
            <Chip
              color="primary"
              size="small"
              label="Transit Demo"
              icon={<SendIcon />}
            />
          );
        }
        if (original.status === 'received') {
          return (
            <Chip
              color="default"
              size="small"
              label="Received"
              icon={<DoneIcon />}
            />
          );
        }
        if (original.status === 'completed') {
          return (
            <Chip
              style={{ backgroundColor: '#28df99', color: 'white' }}
              size="small"
              label="Completed"
              icon={<DoneAllIcon />}
            />
          );
        }
        return <Typography>{original.status}</Typography>;
      },
    },
    {
      Header: 'Delete',
      Cell: ({ row: { original } }) => {
        // TODO remove demo
        if (
          original.status === 'transit' ||
          original.status === 'transit_demo'
        ) {
          return (
            <IconButton
              style={{ padding: 2 }}
              onClick={async () => {
                // check status
                let status = await checkStatus(original.id);
                // TODO remove demo
                if (
                  status &&
                  (status === 'transit' || status === 'transit_demo')
                ) {
                  setDeleteId(original.id);
                  // handleDelete(original.id)
                } else {
                  enqueueSnackbar('Cannot delete, PSC already received.', {
                    variant: 'success',
                    anchorOrigin: {
                      vertical: 'bottom',
                      horizontal: 'right',
                    },
                  });
                  setRefresh(!refresh);
                }
              }}
            >
              <DeleteIcon />
            </IconButton>
          );
        }
        return null;
      },
      width: 60,
    },
    {
      Header: 'Rec. No.',
      accessor: 'receivedNo',
    },
    {
      Header: 'Rec. Date',
      accessor: 'timeStamp',
    },
    {
      Header: 'To Inv.',
      Cell: ({ row: { original } }) => {
        // complete and
        if (original.status === 'received' && !original.details.isInvoice) {
          return (
            <IconButton
              onClick={() => {
                setSelectedData(original);
                setShowInvoice(true);
              }}
            >
              <SyncIcon />
            </IconButton>
          );
        }
        if (original.status === 'transit' && !original.details.isInvoice) {
          return (
            <IconButton
              onClick={() => {
                setSelectedData(original);
                setShowInvoice(true);
                // console.log('clicked');
              }}
            >
              <SyncIcon />
            </IconButton>
          );
        }
        return null;
      },
      width: 60,
    },
    {
      Header: 'Created At',
      accessor: 'created_date',
      Cell: ({ row: { original } }) => {
        return moment(original.createdAt).format('YYYY-MM-DD');
      },
    },
    {
      Header: 'Remarks',
      accessor: 'remarks',
    },
    {
      Header: 'Header Tags',
      Cell: ({ row: { original } }) => {
        let totalTag = original.details.lotDetails.length + 1;
        return totalTag;
      },
    },
    {
      Header: 'Pack Tags',
      Cell: ({ row: { original } }) => {
        let totalTag = original.details.lotDetails.length + 1;
        original.details.lotDetails.map((item) => {
          totalTag = totalTag + item.length;
        });
        return totalTag;
      },
    },
  ];

  // remove delete column
  if (getUserType() === 'store') {
    columns.splice(-8, 1);
  }

  // We'll start our table without any data
  const [data, setData] = React.useState([]);
  const [remarks, setRemarks] = React.useState(null);
  const [remoteData, setRemoteData] = React.useState([]);
  const [downloadData, setDownloadData] = React.useState([]);
  const [allData, setAllData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const fetchIdRef = React.useRef(0);
  const [from, setFrom] = React.useState(
    new moment().add(-1, 'month').startOf('month').format('yyyy-MM-DD')
  );
  const [to, setTo] = React.useState(new moment().format('yyyy-MM-DD'));

  // console.log(allData);
  const getRemoteData = () => {
    console.log(getUserType());

    // default data is from last month
    setLoading(true);
    // TODO remote demo account
    if (getUserType() === 'demo') {
      // deliveries?user_ncontains=demo&vendor_id
      return fetch(
        `${
          settings.server
        }/deliveries?vendor_id=${vendor_id()}&_limit=-1&_sort=details.deliveryDate:DESC&createdAt_gte=${moment(
          from
        )
          .startOf('day')
          .format()}&createdAt_lte=${moment(to).endOf('day').format()}`,
        {
          method: 'GET',
          headers: header(),
        }
      ).then(async (res) => {
        let data = await res.json();
        console.log('vendor');
        setRemoteData(data);
        setAllData(data);
        setDownloadData(data);
        setLoading(false);
      });
    }

    // TODO try to exclude demo
    if (getUserType() === 'vendor') {
      // deliveries?user_ncontains=demo&vendor_id
      return fetch(
        `${
          settings.server
        }/deliveries?vendor_id=${vendor_id()}&_limit=-1&_sort=details.deliveryDate:DESC&createdAt_gte=${moment(
          from
        )
          .startOf('day')
          .format()}&createdAt_lte=${moment(to).endOf('day').format()}`,
        {
          method: 'GET',
          headers: header(),
        }
      ).then(async (res) => {
        let data = await res.json();
        console.log('vendor');
        setRemoteData(data);
        setAllData(data);
        setDownloadData(data);
        setLoading(false);
      });
    } else {
      //if (getUserType() === 'store')
      return fetch(
        `${
          settings.server
        }/deliveries?_limit=-1&_sort=details.deliveryDate:DESC&createdAt_gte=${moment(
          from
        )
          .startOf('day')
          .format()}&createdAt_lte=${moment(to).endOf('day').format()}`,
        {
          method: 'GET',
          headers: header(),
        }
      ).then(async (res) => {
        let data = await res.json();
        console.log('vendor');
        let factory_id = company_id();
        let filterData = _.filter(
          data,
          (item) => item.details.poSelect[0].INV_ORGID === factory_id
        );
        setRemoteData(filterData);
        setAllData(filterData);
        setDownloadData(filterData);
        setLoading(false);
      });
    }
    // else {
    //   return fetch(
    //     `${
    //       settings.server
    //     }/deliveries?_limit=-1&_sort=details.deliveryDate:DESC&createdAt_gte=${moment(
    //       from
    //     )
    //       .startOf('day')
    //       .format()}&createdAt_lte=${moment(to).endOf('day').format()}`,
    //     {
    //       method: 'GET',
    //       headers: header(),
    //     }
    //   ).then(async (res) => {
    //     let data = await res.json();

    //     // add filter for difference company
    //     let factory_id = company_id();
    //     let filterData = _.filter(
    //       data,
    //       (item) => item.details.poSelect[0].INV_ORGID === factory_id
    //     );
    //     setRemoteData(filterData);
    //     setAllData(filterData);
    //     setDownloadData(data);
    //     setLoading(false);
    //   });
    // }
  };

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex, sortBy }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.

      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setLoading(true);
      // console.log(fetchId, fetchIdRef.current);
      // get real data
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;
        // console.log(sortBy);
        if (sortBy.length === 0) {
          setData(remoteData.sort().slice(startRow, endRow));
        } else {
          setData(
            remoteData
              .sort((a, b) => {
                const field = sortBy[0].id;
                const desc = sortBy[0].desc;
                // console.log(sortBy[0].id)
                switch (sortBy[0].id) {
                  case 'po_no':
                    if (
                      parseInt(a.details.poSelect[0].PO_NUMBER) <
                      parseInt(b.details.poSelect[0].PO_NUMBER)
                    ) {
                      return desc ? -1 : 1;
                    }
                    if (
                      parseInt(a.details.poSelect[0].PO_NUMBER) >
                      parseInt(b.details.poSelect[0].PO_NUMBER)
                    ) {
                      return desc ? 1 : -1;
                    }
                    return 0;
                  case 'release_no':
                    if (
                      parseInt(a.details.poSelect[0].RELEASE_NUM) <
                      parseInt(b.details.poSelect[0].RELEASE_NUM)
                    ) {
                      return desc ? -1 : 1;
                    }
                    if (
                      parseInt(a.details.poSelect[0].RELEASE_NUM) >
                      parseInt(b.details.poSelect[0].RELEASE_NUM)
                    ) {
                      return desc ? 1 : -1;
                    }
                    return 0;
                  case 'delivery_qty':
                    if (
                      _.sum(a.details.delAmount.map((item) => parseInt(item))) <
                      _.sum(b.details.delAmount.map((item) => parseInt(item)))
                    ) {
                      return desc ? -1 : 1;
                    }
                    if (
                      _.sum(a.details.delAmount.map((item) => parseInt(item))) >
                      _.sum(b.details.delAmount.map((item) => parseInt(item)))
                    ) {
                      return desc ? 1 : -1;
                    }
                    return 0;
                  case 'invoiceNo':
                    if (a.details.invoiceNo < b.details.invoiceNo) {
                      return desc ? -1 : 1;
                    }
                    if (a.details.invoiceNo > b.details.invoiceNo) {
                      return desc ? 1 : -1;
                    }
                    return 0;
                  case 'delivery_date':
                    if (
                      new moment(a.details.deliveryDate) <
                      new moment(b.details.deliveryDate)
                    ) {
                      return desc ? -1 : 1;
                    }
                    if (
                      new moment(a.details.deliveryDate) >
                      new moment(b.details.deliveryDate)
                    ) {
                      return desc ? 1 : -1;
                    }
                    return 0;
                  case 'created_date':
                    if (new moment(a.createdAt) < new moment(b.createdAt)) {
                      return desc ? -1 : 1;
                    }
                    if (new moment(a.createdAt) > new moment(b.createdAt)) {
                      return desc ? 1 : -1;
                    }
                    return 0;
                  default:
                    // console.log(a[field]);
                    if (a[field] < b[field]) {
                      return desc ? -1 : 1;
                    }
                    if (a[field] > b[field]) {
                      return desc ? 1 : -1;
                    }
                    return 0;
                }
              })
              .slice(startRow, endRow)
          );
        }
        // setData(remoteData.slice(startRow, endRow))
        // console.log(startRow);
        // console.log(endRow);
        // Your server could send back total page count.
        // For now we'll just fake it, too
        setPageCount(remoteData.length);
        setLoading(false);
      }
    },
    [remoteData]
  );

  const handleFilter = () => {
    if (!state.searchTerm) {
      // checkbox change
      if (state.selectedValue !== 'all') {
        let filterData = _.filter(
          allData,
          (e) => e.status === state.selectedValue && e.status !== 'deleted'
        );
        setRemoteData(filterData);
        setPageCount(filterData.length);
        return null;
      } else {
        let filterData = _.filter(allData, (e) => e.status !== 'deleted');
        setRemoteData(filterData);
        setPageCount(filterData.length);
        return null;
      }
    } else {
      // console.log(allData);
      let filterData = _.filter(allData, (e) => {
        let filter_data = [];
        filter_data.push(e.details.invoiceNo.includes(state.searchTerm.inv_do));
        let check_po = false;
        _.map(e.details.poSelect, (item) => {
          if (item.PO_NUMBER.includes(state.searchTerm.po)) {
            if (!item.RELEASE_NUM) {
              if (state.searchTerm.rel !== '') {
                check_po = false;
              } else {
                check_po = true;
              }
            } else if (item.RELEASE_NUM.includes(state.searchTerm.rel)) {
              check_po = true;
            }
          }
        });
        filter_data.push(check_po);
        // console.log();
        const check_filter = (currentValue) => currentValue === true;
        return filter_data.every(check_filter);
        // return e.details.invoiceNo.includes(state.searchTerm.inv);
      });

      // console.log(filterData);

      // checkbox change
      if (state.selectedValue !== 'all') {
        let allFilterData = _.filter(
          filterData,
          (e) => e.status === state.selectedValue && e.status !== 'deleted'
        );
        setRemoteData(allFilterData);
        setPageCount(allFilterData.length);
        return null;
      } else {
        let allFilterData = _.filter(filterData, (e) => e.status !== 'deleted');
        setRemoteData(allFilterData);
        setPageCount(allFilterData.length);
        return null;
      }
    }
  };

  const handleFilterDelete = () => {
    if (state.deleted) {
      let filterData = _.filter(allData, (e) => e.status === 'deleted');
      setRemoteData(filterData);
      setPageCount(filterData.length);
      return null;
    } else {
      handleFilter();
      return null;
    }
  };

  const downloadXLSX = (deliveries) => {
    let array = Object.assign([], deliveries);
    let forDownload = [];
    // clear some field
    // for(var i = 0; i < array.length; i++) {
    array.forEach((arr) => {
      let details = arr['details'];
      let headerTag = details.lotDetails.length + 1;
      let totalTag = headerTag;
      details.lotDetails.map((item) => {
        totalTag = totalTag + item.length;
      });
      forDownload.push({
        To: arr['company'],
        Invoice_no: details.isInvoice ? details.invoiceNo : '',
        DO: details.doNo
          ? details.doNo
          : !details.isInvoice
          ? details.invoiceNo
          : '',
        Invoice_date: details.invoiceDate,
        Site: details.site.VENDOR_SITE_CODE,
        Address: details.site.ADDR,
        PO: details.poSelect[0].PO_NUMBER,
        Release_no: details.poSelect[0].RELEASE_NUM,
        Item: _.map(details.poSelect, 'ITEM_CODE').join('|'),
        QTY: details.delAmount.join('|'),
        Received_date: arr['timeStamp'],
        Received_no: arr['receivedNo'],
        Status: arr['status'],
        Created_date: new Date(arr['createdAt']).toLocaleString(),
      });
      // 'TagCount': totalTag,
      // 'HeaderTag': headerTag
    });

    const filename = `deliveries_from_${new Date(
      from
    ).toLocaleDateString()}_to_${new Date(to).toLocaleDateString()}.xlsx`;
    const fileType =
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const ws = XLSX.utils.json_to_sheet(forDownload);
    const wb = { Sheets: { data: ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], { type: fileType });
    FileSaver.saveAs(data, filename);
  };

  React.useEffect(() => {
    // filter data
    handleFilter();
  }, [state.searchTerm, state.selectedValue, allData]);

  React.useEffect(() => {
    handleFilterDelete();
  }, [state.deleted]);

  React.useEffect(() => {
    getTransits();
  }, [refresh]);

  React.useEffect(() => {
    getRemoteData();
  }, [refresh]);

  React.useEffect(() => {
    if (deleteId) setShowDelete(true);
  }, [deleteId]);

  if (show && !selectedData.job_id)
    return (
      <DeliveryPDF
        print={show}
        from="status"
        data={selectedData}
        handleClose={handleClose}
      />
    );

  if (show && selectedData.job_id) {
    return (
      <DeliveryPDFOSP
        print={show}
        from="status"
        data={selectedData}
        handleClose={handleClose}
      />
    );
  }
  return (
    <div style={{ margin: 15, maxHeight: '100vh' }}>
      <Grid container justify={'flex-start'}>
        <Grid container xs={12} md={6} spacing={1}>
          <Grid item xs={5}>
            <TextField
              fullWidth
              id="date"
              label="From"
              type="date"
              variant="outlined"
              value={from}
              readOnly={true}
              onChange={(date) => {
                console.log(date);
                // retrieve new data
                setFrom(date.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              style={{
                marginBottom: 10,
              }}
              size="small"
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              id="date"
              label="To"
              type="date"
              variant="outlined"
              value={to}
              readOnly={true}
              onChange={(date) => {
                console.log(date);
                // retrieve new data
                setTo(date.target.value);
              }}
              InputLabelProps={{
                shrink: true,
              }}
              size="small"
              style={{
                marginLeft: 10,
                marginBottom: 10,
              }}
            />
          </Grid>
          <Grid item xs={2}>
            <Button
              onClick={getRemoteData}
              style={{ marginLeft: 10, height: 40 }}
              variant="contained"
              color="primary"
            >
              Search
            </Button>
          </Grid>
        </Grid>
        <Grid
          item
          xs={12}
          md={6}
          style={{ display: 'flex', justifyContent: 'flex-end' }}
        >
          <FormControlLabel
            control={
              <Checkbox
                checked={state.deleted}
                onChange={(e) =>
                  setState((state) => ({ ...state, deleted: e.target.checked }))
                }
                name="Delete"
              />
            }
            label="Deleted Only"
          />
          <IconButton
            aria-label="delete"
            color="primary"
            onClick={() => downloadXLSX(downloadData)}
          >
            <CloudDownloadIcon />
          </IconButton>
        </Grid>
      </Grid>

      <SearchBox />

      {loading ? (
        <ReactLoading type={'bars'} color={'#444'} className="preloader" />
      ) : (
        <Table
          columns={columns}
          data={data}
          fetchData={fetchData}
          loading={loading}
          pageCount={pageCount}
        />
      )}

      <Dialog
        open={showDelete}
        onClose={handleCloseDelete}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Why?</DialogTitle>
        <DialogContent>
          <DialogContentText>Please provide reason to delete</DialogContentText>
          <TextField
            autoFocus
            value={remarks}
            margin="dense"
            id="remarks"
            label="Remarks"
            type="text"
            fullWidth
            onChange={(e) => setRemarks(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDelete} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleDelete}
            disabled={remarks ? false : true}
            color="primary"
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        open={showInvoice}
        onClose={handleCloseInvoice}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">Change DO to Invoice?</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please provide invoice number below
          </DialogContentText>
          <TextField
            autoFocus
            value={invoiceNo}
            margin="dense"
            id="name"
            label="Invoice Number"
            type="text"
            fullWidth
            onChange={(e) => setInvoiceNo(e.target.value)}
          />
          <TextField
            autoFocus
            value={newInvoiceDate}
            margin="dense"
            id="name"
            label="Invoice Date"
            type="date"
            fullWidth
            onChange={(e) => setNewInvoiceDate(e.target.value)}
            readOnly={true}
            InputLabelProps={{ shrink: true }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseInvoice} color="primary">
            Cancel
          </Button>
          <Button onClick={handleToInvoice} color="primary">
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default App;
