import React from 'react';
import { Route, Redirect, Link } from 'react-router-dom';
import Dashboard from '../routes/dashboard/Dashboard';
import { isLogin } from '../utils';
import DataContextProvider from '../ContextData';
import DataContextProviderMobile from '../ContextData_Mobile';
import { isMobile } from 'react-device-detect';

const PrivateRoute = ({ component: Component, ...rest }) => {
  return (
    // Show the component only when the user is logged in
    // Otherwise, redirect the user to /signin page
    <Route
      {...rest}
      render={(props) =>
        isLogin() ? (
          isMobile ? (
            <DataContextProviderMobile>
              <Dashboard>
                <Component {...props} />
              </Dashboard>
            </DataContextProviderMobile>
          ) : (
            <DataContextProvider history={props.history}>
              <Dashboard>
                <Component {...props} />
              </Dashboard>
            </DataContextProvider>
          )
        ) : (
          <Redirect to="/signin" />
        )
      }
    />
  );
};

export default PrivateRoute;
