import React from 'react';
import TableView from './TableView';
import { BsCloudUpload } from 'react-icons/bs';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import {
  getEmailTo,
  getVendors,
  uploadConfirmPO,
  updateConfirmPO,
  sendingEmail,
  deleteConfirmPO,
  getSupplierEmail,
  getAllGroupEmail,
} from '../../utils/apis';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { company_name, username, getUserType, alert_email } from '../../utils/';
import moment from 'moment';
import Loading from '../../components/Loading';
import { SettingsPowerSharp } from '@material-ui/icons';

function ConfirmPO() {
  const [vendorCode, setVendorCode] = React.useState('');
  const [vendors, setVendors] = React.useState([]);
  const [emailGroups, setEmailGroups] = React.useState([]);
  const [resultEmails, setResultEmails] = React.useState([]);
  const [selectedEmails, setSelectedEmails] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [POs, setPOs] = React.useState('');
  const [rels, setRels] = React.useState('');
  const [supplierCodes, setSupplierCodes] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [files, setFiles] = React.useState();
  const [dueDates, setDueDates] = React.useState([]);
  // const [dueDate, setDueDate] = React.useState(moment().format('YYYY-MM-DD'));
  const [reload, setReload] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();

  const handleFileAsync = async (e) => {
    console.log(e.target.files, company_name());
    if (
      _.some(e.target.files, (file) => {
        return file.name.split('-').length !== 6;
      })
    ) {
      return enqueueSnackbar(
        `File format not support. Fiel name should be 
        "FAC-SUPPLIER-SEQ-DATE-PO-REL.pdf"
        [i.e AHP-JSA-XXX-20221011-4300001-165.pdf]`,
        {
          variant: 'info',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          autoHideDuration: 300000,
        }
      );
    }
    if (
      _.some(e.target.files, (file) => {
        if (company_name() !== 'AH') {
          return file.name.split('-')[0] !== company_name();
        } else {
          return file.name.split('-')[0] !== 'APD';
        }
      })
    ) {
      return enqueueSnackbar(`File name and user's factory is not same.`, {
        variant: 'info',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 300000,
      });
    }
    let newPOs = [];
    let newRels = [];
    let newDueDates = [];
    let newSupplierCodes = [];
    let newSupplierName = [];
    _.map(e.target.files, (each) => {
      let nameData = each.name.split('-');
      newDueDates.push(nameData[3]);
      newPOs.push(nameData[4]);
      newRels.push(nameData[5].split('.')[0]);
      newSupplierCodes.push(nameData[1]);
    });

    console.log(newPOs, newRels, newDueDates);
    setPOs(newPOs);
    setRels(newRels);
    setDueDates(newDueDates);
    setSupplierCodes(newSupplierCodes);
    const files = e.target.files;
    setFiles(files);
  };

  const getAllGroup = async () => {
    let data = await getAllGroupEmail();
    setEmailGroups(data);
  };

  const handleView = async (e) => {
    // update last download & counter & download
    window.open('https://smp.aapico.com' + e.attachment[0].url, '_blank');
    // no count if user no vendor
    if (getUserType() === 'vendor') {
      await updateConfirmPO(e.id, {
        download_count: e.download_count + 1,
        downloaded_by: username(),
        downloaded_at: new moment().format('YYYY-MM-DD HH:mm'),
      });
      setReload((reload) => reload + 1);
    }
  };
  const handleDelete = async (e) => {
    // update last download & counter & download by
    // if have ANS cannot delete
    if (e.confirm_asns.length !== 0) {
      return enqueueSnackbar('Cannot delete, Confirm ANS exist', {
        variant: 'info',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 300000,
      });
    }
    let deleteResult = await deleteConfirmPO(e.id);
    if (deleteResult.status == 200) {
      setReload((reload) => reload + 1);
      return enqueueSnackbar('Delete successfully', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 300000,
      });
    }
  };
  const handleSearch = async (e) => {
    console.log(emailGroups, e);
    let resultDrops =
      searchTerm === ''
        ? ''
        : emailGroups.filter((group) =>
            group.name
              .toLowerCase()
              .replace(/\s+/g, '')
              .includes(searchTerm.toLowerCase().replace(/\s+/g, ''))
          );
    if (
      selectedEmails &&
      resultDrops.length !== 0 &&
      resultDrops[0].name === selectedEmails.name
    ) {
      setResultEmails([]);
    } else {
      setResultEmails(resultDrops);
    }
  };
  const handleSubmit = async () => {
    if (!files) {
      return enqueueSnackbar('No upload file', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 300000,
      });
    }

    if (selectedEmails.length === 0) {
      return enqueueSnackbar('Please selected email group', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 300000,
      });
    }

    // check file name and selected groupif (
    if (
      _.some(files, (file) => {
        console.log(file.name.split('-'), selectedEmails.name.split('-'));
        if (selectedEmails.name.split('-')[0] !== 'AH') {
          return (
            file.name.split('-')[0] !== selectedEmails.name.split('-')[0] ||
            file.name.split('-')[1] !== selectedEmails.name.split('-')[1]
          );
        } else {
          return (
            file.name.split('-')[0] !== 'APD' ||
            file.name.split('-')[1] !== selectedEmails.name.split('-')[1]
          );
        }
      })
    ) {
      return enqueueSnackbar(`File name and email group is not same.`, {
        variant: 'info',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 300000,
      });
    }
    _.map([...files], async function (file, index) {
      console.log(file);
      // start prepare form for submit
      // add sending email status
      setLoading(true);
      let formData = new FormData();
      formData.append('files.attachment', file);
      formData.append(
        'data',
        JSON.stringify({
          factory: company_name(),
          supplier_name: selectedEmails.supplier_name,
          supplier_code: supplierCodes[index], //take from file name
          vendor_id: selectedEmails.vendor_id,
          receiver_email: selectedEmails.email_list,
          uploader: username(),
          po_number: POs[index],
          rel_number: rels[index],
          due_date: dueDates[index],
        })
      );

      let uploadResult = await uploadConfirmPO(formData);

      if (uploadResult.status !== 200) {
        return enqueueSnackbar('PO uploaded fails', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 300000,
        });
      }

      let uploadOutput = await uploadResult.json();
      // TODO sending email notification and update record
      let emailResult = await sendingEmail({
        to: selectedEmails.email_list,
        cc: alert_email(),
        subject: 'Automatic PO Confirmation',
        templateReferenceId: 2, // template number
        data: {
          url: 'https://smp.aapico.com/#/confirm-po',
          factory: company_name(),
          po: POs[index],
          rel: rels[index],
        },
      });

      if (emailResult.status !== 200) {
        setShow(false);
        setLoading(false);
        setReload((reload) => reload + 1);
        return enqueueSnackbar('Email not sent', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 300000,
        });
      } else {
        // update email sent status
        await updateConfirmPO(uploadOutput.id, { mail_sent: true });
        setLoading(false);
        setShow(false);
        setReload((reload) => reload + 1);
        return enqueueSnackbar('Email sent successfully', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 300000,
        });
      }
    });
  };

  React.useEffect(() => {
    getAllGroup();
  }, []);

  React.useEffect(() => {
    // perform
    handleSearch();
  }, [searchTerm]);

  return (
    <div>
      {loading && <Loading />}
      {show && (
        <div className="m-2 flex space-x-2 bg-white rounded-md p-2 pr-5 pt-4 pb-6">
          {/* input supplier name */}
          <button
            onClick={() => setShow(false)}
            className="absolute bg-white p-1 rounded-full right-0 mr-0 -mt-6 shadow-lg"
          >
            <AiOutlineCloseCircle className="w-6 h-6 bg-gray-50" />
          </button>
          <div className="flex w-1/2">
            <div className="flex flex-col w-64">
              <label for="due">Supplier Name</label>
              <input
                type="text"
                name="Group mail"
                id="groupMail"
                value={searchTerm}
                placeholder="Group Mail"
                className=" w-64 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) => setSearchTerm(e.target.value)}
              ></input>
              {resultEmails.length !== 0 && (
                <div className="flex absolute z-50 w-84 max-h-72 rounded shadow bg-white overflow-hidden peer-checked:flex flex-col mt-12 border border-gray-200">
                  {resultEmails.map((each) => (
                    <div class="cursor-pointer group">
                      <button
                        onClick={() => {
                          setSearchTerm(each.name);
                          setSelectedEmails(each);
                          setResultEmails([]);
                        }}
                        className="block w-full p-2 border-transparent border-l-4 hover:border-blue-600 hover:bg-gray-100 text-left"
                      >
                        {each.name} [{each.description}]
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* input email group */}
            <div className="flex w-full mx-2 space-x-2">
              {selectedEmails &&
                selectedEmails.email_list.split(',').map((email) => {
                  return (
                    <span className="flex p-2 bg-indigo-500 mt-5 rounded-md break-normal text-white">
                      {email}
                    </span>
                  );
                })}
            </div>
          </div>
          <div className="flex justify-between w-1/2">
            <div className="flex space-x-2">
              {/* Due Date */}
              {/* <div>
                <label for="due">Due Date</label>
                <input
                  type="date"
                  name="due"
                  id="due"
                  value={dueDate}
                  placeholder="Due Date"
                  className="bg-gray-50 w-36 h-[32px]
               border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  required
                  onChange={async (e) => setDueDate(e.target.value)}
                ></input>
              </div> */}
            </div>
            <div className="flex mt-5 h-10 space-x-2">
              {/* upload button */}
              <div className="flex flex-col space-y-2">
                <div className="flex w-8 my-auto justify-center hover:cursor-pointer shadow-md ml-auto space-x-2 bg-indigo-500 rounded-lg h-10 text-white align-middle">
                  <label
                    for="upload"
                    class="flex flex-col items-center cursor-pointer"
                  >
                    <div className="flex w-auto m-auto space-x-2 justify-center h-10">
                      <BsCloudUpload className="w-4 h-4 m-auto" />
                    </div>
                  </label>
                  <input
                    id="upload"
                    type="file"
                    multiple="true"
                    class="hidden"
                    accept="application/pdf"
                    onChange={(e) => {
                      console.log('upload');
                      handleFileAsync(e);
                    }}
                    onClick={(event) => {
                      console.log('upload');
                      event.target.value = null;
                    }}
                  />
                </div>
                {files && (
                  <div className="p-2 bg-gray-200 rounded-md z-50 space-y-2 shadow-lg">
                    {[...files].map((file) => (
                      <p className="text-gray-800 border-2 border-gray-300 p-1 rounded-md">
                        {file.name}
                      </p>
                    ))}
                  </div>
                )}
              </div>
              <button
                onClick={(e) => {
                  setFiles();
                }}
                className="bg-gray-400 p-2 w-auto rounded-md h-10 shadow-lg"
              >
                Clear
              </button>
              <button
                onClick={handleSubmit}
                className="bg-indigo-500 text-white rounded-md p-2 "
              >
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      <div>
        <TableView
          show={show}
          reload={reload}
          setShow={setShow}
          handleDelete={handleDelete}
          handleView={handleView}
        />
      </div>
    </div>
  );
}

export default ConfirmPO;
