import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { IconButton, Chip } from '@material-ui/core';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import EmailIcon from '@material-ui/icons/Email';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  download,
  deleteForecast,
  getSupplierEmail,
  email,
  updateForecast,
} from '../../utils/apis';
import { useSnackbar } from 'notistack';
import { getUserType, user_email, vendor_id } from '../../utils';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import emailTemplate from '../../emails';
import _ from 'lodash';
import ReactLoading from 'react-loading';
import moment from 'moment';

const SweetAlert = withReactContent(Swal);

const PDSStatus = (props) => {
  const [data, setData] = React.useState(props.data);

  const sendEmail = async (data) => {
    let supplierEmail = await getSupplierEmail(data.vendor_id);
    console.log(data, supplierEmail.email_alert);
    // TODO use correct email it's array
    let emailStatus = await email(
      'auto-email@aapico.com',
      `Order Forecast alert`,
      'soknath.m@aapico.com,warit.w@aapico.com',
      `${data.forecast.name}`,
      emailTemplate(data),
      `${user_email()}`
    );
    if (emailStatus.emailsend == 'Complete') {
      SweetAlert.fire('Email Sent!', 'Sent Successfully.', 'success');
    } else {
      SweetAlert.fire('Email Error!', 'Sent Fails.', 'error');
    }
    // update forecast record
    updateForecast(data.id);

    // reload page
    props.reload();
  };

  React.useEffect(() => {
    setData(props.data);
  }, [props.data]);

  if (props.loading) {
    return (
      <ReactLoading type={'bubbles'} color={'#444'} className="preloader" />
    );
  }
  return (
    <div>
      <MaterialTable
        columns={[
          {
            title: 'Company',
            field: 'company',
            width: 100,
          },
          {
            title: 'Vendor',
            field: 'vendor',
            width: 150,
          },
          {
            title: 'File Name',
            render: (rowData) => rowData.forecast.name,
            width: 150,
          },
          {
            title: 'Date',
            field: 'forecast_date',
            width: 150,
          },
          {
            title: 'Created Date',
            // field: 'createdAt',
            render: (rowData) => new moment(rowData.createdAt).format('LLL'),
            width: 150,
          },
          {
            title: 'Email Group',
            render: (rowData) => {
              return (
                <div className={`flex flex-col`}>{rowData.receiver_email && rowData.receiver_email.split(",").map(each => < span className={`text-sm font-jamjuree`} >
                  {each}
                </span >
                )} </div>
              )
            },
            width: 150,
          },
          {
            title: 'Download',
            width: 100,
            render: (rowData) => {
              // console.log(rowData);
              return (
                <IconButton
                  aria-label="download "
                  color="primary"
                  style={{ padding: 0 }}
                  onClick={async () => {
                    await download(
                      rowData.forecast.url,
                      getUserType(),
                      rowData.down_count || 0,
                      rowData.id
                    );
                    props.reload();
                  }}
                >
                  <CloudDownloadIcon />
                </IconButton>
              );
            },
          },
          {
            title: 'Email',
            width: 80,
            render: (rowData) => (
              <IconButton
                aria-label="sent email"
                disabled={rowData.email_status}
                color="primary"
                style={{ padding: 0 }}
                onClick={() => {
                  sendEmail(rowData);
                  console.log('sending email', rowData);
                }}
              >
                <EmailIcon />
              </IconButton>
            ),
          },
          {
            title: 'Download Count',
            width: 100,
            render: (rowData) => <Chip label={rowData.down_count || 0} />,
          },
        ]}
        data={data}
        title={' '}
        options={{
          columnsButton: true,
          cellStyle: { fontFamily: 'Bai Jamjuree', textAlign: 'center' },
          pageSize: 20,
          headerStyle: {
            fontWeight: 'bold',
            fontFamily: 'Bai Jamjuree',
            textAlign: 'center',
          },
          exportAllData: true,
          exportButton: true,
          padding: 'dense',
          exportFileName: `PDS Status ${new Date().toISOString()}`,
          // fixedColumns: {
          //   left: 1
          // }
        }}
      />
    </div>
  );
};

export default PDSStatus;
