import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { Paper, Grid, Button } from '@material-ui/core';
import { getUserType, vendor_id } from '../../utils';
import { getPayments, updatePayments } from '../../utils/apis';
import Table from './Table';
import ReactLoading from 'react-loading';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import Card from '../../components/Card';

const Payments_Admin = () => {
  const [month, setMonth] = React.useState(new moment());
  const [userType, setUserType] = React.useState(getUserType());
  const [data, setData] = React.useState();
  const [dataExport, setDataExport] = React.useState();
  const [monthData, setMonthData] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [selectedOrg, setSelectedOrg] = React.useState([]);
  const [groupData, setGroupData] = React.useState({});
  const [sumBy, setSumBy] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleSearchDateChange = (date) => {
    setMonth(date);
  };

  const refreshData = async () => {
    // console.log(month.format('MMM-YYYY'));
    setLoading(true);
    let result = await updatePayments({
      Month: month.format('MMM-YYYY'),
    });
    setLoading(false);
    if (result.status === 'success') {
      await getData();
      return enqueueSnackbar('Payment data updated successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    } else {
      return enqueueSnackbar('Payment data updated fail', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  const summarizeData = (input) => {
    // console.log(input);
    return _.map(_.groupBy(input, 'ORG_CODE'), (o, idx) => {
      return {
        id: idx,
        total: _.sumBy(o, (item) => {
          if (item.BANK_NAME !== 'CLEARING') {
            return parseFloat(item.AMOUNT);
          }
          return 0;
        }).toFixed(2),
      };
    });
  };

  const getGroupData = async () => {
    setLoading(true);

    let allData = await getPayments();
    // default select to null
    let groupData = _.groupBy(allData[0].data, 'ORG_CODE');
    setMonthData(allData[0].month);
    setGroupData(groupData);
    console.log(groupData);

    // summaryData
    let vendorID = vendor_id();
    if (vendorID !== 'undefined') {
      allData[0].data = _.filter(allData[0].data, (item) => {
        return item.VENDOR_ID == vendorID;
      });
    }
    let sumData = summarizeData(allData[0].data);
    setSumBy(sumData);
    setLoading(false);
  };

  const getData = async () => {
    let vendorID = vendor_id();
    let currentData = [];
    _.filter(selectedOrg, (each) => {
      currentData = [...currentData, ...groupData[each]];
    });
    console.log(selectedOrg, currentData, groupData);

    let dataOnly = [];
    // filter vendor first

    if (currentData.length > 0) {
      _.chain(currentData)
        .groupBy('CHECK_NUMBER')
        .map((value) => {
          _.map(value, (item, index) => {
            dataOnly.push({
              ...item,
              id: item['CHECK_NUMBER'] + '-' + index,
              parentId: item['CHECK_NUMBER'],
            });
          });
          // main character
          let allInvoices = [];
          _.map(value, (item) => allInvoices.push(item['INVOICE_NUM']));

          let allCompanies = [];
          _.map(value, (item) => {
            if (!allCompanies.includes(item['ORG_CODE'])) {
              allCompanies.push(item['ORG_CODE']);
            }
          });
          // display only WHT not equal zero
          dataOnly.push({
            id: value[0]['CHECK_NUMBER'],
            ORG_CODE: allCompanies, // TODO when have many ORG

            CHECK_NUMBER: value[0]['CHECK_NUMBER'],
            BANK_NAME: value[0]['BANK_NAME'],
            INVOICE_NUM: allInvoices,
            DATA_TYPE: 'summary',
            VENDOR_ID: value[0]['VENDOR_ID'],
            TOTAL_AMOUNT: _.sumBy(value, (item) => {
              if (item.DATA_TYPE !== 'summary') {
                return parseFloat(item.AMOUNT);
              }
              return 0;
            }).toFixed(2),
            WHT_AMT: _.sumBy(value, (item) => {
              if (item.DATA_TYPE !== 'summary') {
                return parseFloat(item.WHT_AMT);
              }
              return 0;
            }).toFixed(2),
          });
          return;
        })
        .value();

      let allDataNoFilter = [...currentData];
      if (vendorID !== 'undefined') {
        let filterAlldata = _.filter(currentData, (item) => {
          return item.VENDOR_ID == vendorID;
        });
        // Remove WHT Zero
        let allDataNoFilterExcludeWHTZero = _.filter(
          filterAlldata,
          (item) => item.AMOUNT !== '0' && item.AMOUNT !== '0.00'
        );
        setDataExport(allDataNoFilterExcludeWHTZero);
        let allDataFilter = _.filter(dataOnly, (item) => {
          return item.VENDOR_ID == vendorID;
        });
        // Remove WHT Zero
        let dataOnlyFilter = _.filter(
          allDataFilter,
          (item) => item.AMOUNT !== '0' && item.AMOUNT !== '0.00'
        );
        return setData(dataOnlyFilter);
      }
      // Remove WHT Zero
      let dataOnlyFilter = _.filter(
        dataOnly,
        (item) => item.AMOUNT !== '0' && item.AMOUNT !== '0.00'
      );
      let allDataNoFilterExcludeWHTZero = _.filter(
        allDataNoFilter,
        (item) => item.AMOUNT !== '0' && item.AMOUNT !== '0.00'
      );
      setDataExport(allDataNoFilterExcludeWHTZero);
      return setData(dataOnlyFilter);
    } else {
      return enqueueSnackbar('No Invoice data found', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  console.log(data);

  React.useEffect(() => {
    getGroupData();
  }, []);

  React.useEffect(() => {
    console.log(selectedOrg, sumBy);
    setData([]);
    if (selectedOrg.length > 0) {
      getData();
    }
  }, [selectedOrg]);

  if (!data) {
    return <ReactLoading type={'bars'} color={'#444'} className="preloader" />;
  }
  return (
    <div>
      {userType.includes('admin') && (
        <Paper>
          <Grid
            container
            fullWidth
            direction="row"
            spacing={1}
            alignItems={'center'}
          >
            <Grid
              item
              xs={2}
              style={{
                margin: 10,
              }}
            >
              <DatePicker
                size="small"
                defaultValue={null}
                views={['year', 'month']}
                label="Year and Month"
                value={month}
                onChange={handleSearchDateChange}
                inputVariant="outlined"
                clearable={true}
              />
            </Grid>
            <Button variant="contained" color="primary" onClick={refreshData}>
              Update
            </Button>
          </Grid>
        </Paper>
      )}
      {!loading && (
        <Grid
          container
          direction="row"
          spacing={2}
          fullWidth
          style={{ margin: 10 }}
          justify="center"
        >
          {sumBy.map((item) => (
            <Grid item>
              <Card
                amount={item.total}
                name={'PAYMENT,' + item.id}
                onCheck={(e, name) => {
                  let newSelect;
                  if (e.target.checked) {
                    // add to list
                    newSelect = [...selectedOrg, name.replace('PAYMENT,', '')];
                  } else {
                    newSelect = _.filter(
                      selectedOrg,
                      (e) => e !== name.replace('PAYMENT,', '')
                    );
                  }
                  setSelectedOrg(newSelect);
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Grid container style={{ marginTop: 10 }}>
        {loading ? (
          <ReactLoading type={'bars'} color={'#444'} className="preloader" />
        ) : (
          <Table data={data} month={monthData} dataExport={dataExport} />
        )}
      </Grid>
    </div>
  );
};

export default Payments_Admin;
