import React from "react";
import Table from "../../components/TailwindTable";
import { useQuery } from '@tanstack/react-query';
import Pagination from '../../components/Pagination';
import {
  getConfirmPO,
  getStoreEmail,
  sendingEmail,
  updateConfirmASN,
} from '../../utils/apis';
import { getUserType, user_email } from '../../utils';
import ListBox from '../../components/ListBox';
import {
  BsFillEyeFill,
  BsClipboardPlus,
  BsCheckAll,
  BsEyeFill,
} from 'react-icons/bs';
import Loading from '../../components/Loading';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import _ from 'lodash';

const pageSizeList = [
  { id: 1, name: 5 },
  { id: 2, name: 10 },
  { id: 3, name: 20 },
  { id: 4, name: 50 },
  { id: 5, name: 100 },
];

function ASNTable() {
  const [showConfirm, setShowConfirm] = React.useState(false);
  const [selectablePage, setSelectablePage] = React.useState([1, 2, 3]);
  const [activePage, setActivePage] = React.useState(1);
  const [totalPages, setTotalPages] = React.useState();
  const [totalConfirmPO, setTotalConfirmPO] = React.useState();
  const [pageSize, setPageSize] = React.useState(50);
  const [confirmPO, setConfirmPO] = React.useState([]);
  const [selectedPO, setSelectedPO] = React.useState('');
  const [searchPO, setSearchPO] = React.useState('');
  const [searchREL, setSearchREL] = React.useState('');
  const [pageSizeSelected, setPageSizeSelected] = React.useState(
    pageSizeList[3]
  );
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  const resentEmail = async (e) => {
    console.log('sent mail');
    setLoading(true);
    let store = await getStoreEmail('store', e.factory);
    let storeEmail = _.map(store, (each) => each.email_alert);
    console.log(storeEmail.join(), e.receiver_email);
    let emailResult = await sendingEmail({
      to: storeEmail.join(),
      cc: e.receiver_email,
      subject: 'Automatic PO Confirmation',
      templateReferenceId: 3, // template number
      data: {
        url: 'https://smp.aapico.com/#/confirm-po',
        supplier: e.supplier_code,
        po: e.po_number,
        rel: e.rel_number,
        deliveryDate: e.delivery_date,
      },
    });

    if (emailResult.status !== 200) {
      setLoading(false);
      refetch();
      return enqueueSnackbar('Email not sent', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 300000,
      });
    } else {
      // update email sent status
      await updateConfirmASN(e.id, { email_sent: true });
      setLoading(false);
      refetch();
      return enqueueSnackbar('Email sent successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 300000,
      });
    }
  };

  const columns = React.useMemo(
    () => [
      {
        Header: 'Delivery Date',
        Cell: ({ row }) => {
          return (
            <div className="flex space-x-2 justify-center items-center">
              <button
                onClick={() =>
                  window.open("https://smp.aapico.com" + row.original.attachment[0].url, '_blank')
                }
                className="flex shadow-md rounded-md w-6 h-6 bg-green-500 justify-center items-center"
              >
                <BsEyeFill className="w-4 h-4 m-auto text-white rounded-md" />
              </button>
              <span
                className={`text-sm font-jamjuree bg-indigo-500 text-white p-1 rounded-md shadow-md`}
              >
                {row.original.delivery_date}
              </span>
            </div>
          );
        },
      },
      {
        Header: 'PO No.',
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.po_number}
            </span>
          );
        },
      },
      {
        Header: 'REL No.',
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.rel_number}
            </span>
          );
        },
      },
      {
        Header: 'Supplier Name',
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.supplier_name}
            </span>
          );
        },
      },
      {
        Header: 'Supplier Code',
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.supplier_code}
            </span>
          );
        },
      },
      {
        Header: 'Upload by',
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {row.original.uploader}
            </span>
          );
        },
      },
      {
        Header: 'Mail Sent',
        Cell: ({ row }) => {
          let disabled = row.original.email_sent || getUserType() !== 'vendor';
          return (
            <span className={`text-sm font-jamjuree`}>
              <button
                onClick={() => resentEmail(row.original)}
                className={` ${
                  disabled ? 'bg-gray-400 cursor-not-allowed' : 'bg-indigo-500'
                }  p-2 rounded-md text-white shadow-md`}
                disabled={disabled}
              >
                {row.original.email_sent ? (
                  <BsCheckAll className={'w-4 h-4 '} />
                ) : (
                  'Send'
                )}
              </button>
            </span>
          );
        },
      },
      {
        Header: 'Created At',
        Cell: ({ row }) => {
          return (
            <span className={`text-sm font-jamjuree`}>
              {new moment(row.original.createdAt).format('YYYY-MM-DD HH:mm')}
            </span>
          );
        },
      },
    ],
    []
  );

  const previousPage = () => {
    if (activePage - 1 > 0) {
      setActivePage(activePage - 1);
    }
  };
  const nextPage = () => {
    if (totalPages >= activePage + 1) {
      setActivePage(activePage + 1);
    }
  };
  const gotoLastPage = () => {
    setActivePage(totalPages == 0 ? 1 : totalPages);
  };
  const gotoFirstPage = () => {
    setActivePage(1);
  };

  const handlePageSizeSelected = (pageSizeSelect) => {
    setPageSize(pageSizeSelect.name);
    setPageSizeSelected(pageSizeSelect);
  };

  const formatData = (data) => {
    let ASNdata = [];
    _.map(data.data, (item) => {
      item.confirm_asns.map((each) => {
        ASNdata.push({
          ...item,
          ...each,
        });
      });
    });
    console.log(ASNdata);
    let sortASNdata = _.orderBy(
      ASNdata,
      (item) => moment(item.delivery_date).valueOf(),
      ['desc']
    );

    // filter ouput by email if result hase email
    let filterASNData = _.filter(sortASNdata, each => {
      if (each.receiver_email) {
        // check if it belong to user
        return each.receiver_email.includes(user_email());
      } else {
        return true;
      }
    })
    setConfirmPO(filterASNData);
    setTotalConfirmPO(filterASNData.length);
  };

  const { isLoading, error, data, isFetching, refetch } = useQuery(
    ['confirmPO', activePage],
    () => getConfirmPO(activePage, pageSize, searchPO, searchREL)
  );
  React.useEffect(() => {
    if (data) {
      formatData(data);
    }
  }, [data]);

  React.useEffect(() => {
    refetch();
  }, [
    pageSize,
    activePage,
    selectablePage,
    pageSizeSelected,
    searchPO,
    searchREL,
  ]);

  React.useEffect(() => {
    if (totalPages === 0) {
      setSelectablePage([1]);
    } else {
      let newSelecteablePages = _.filter(
        [activePage - 1, activePage, activePage + 1],
        (item) => item !== 0 && item !== totalPages + 1
      );
      setSelectablePage(newSelecteablePages);
    }
  }, [activePage, totalPages]);

  if (isLoading) {
    return <Loading />;
  }

  if (confirmPO) {
    return (
      <div className="flex-col overflow-hidden w-full">
        <div className="flex w-full">
          <div className="flex w-1/2 mx-5 space-x-2 mt-3">
            <input
              type="text"
              name="pono"
              id="pono"
              placeholder="Search PO No."
              className="bg-gray-50 border w-40 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) => setSearchPO(e.target.value)}
            ></input>
            <input
              type="text"
              name="rel"
              id="rel"
              placeholder="Search REL No."
              className="bg-gray-50 border w-40 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) => setSearchREL(e.target.value)}
            ></input>
          </div>
          <div className="flex flex-col w-1/2 right-0 mr-5 z-10 pl-2 pt-3 fixed bg-slate-700 ">
            <div className="flex space-x-2 w-full justify-end">
              <div>
                <Pagination
                  selectablePage={selectablePage}
                  activePage={activePage}
                  previousPage={previousPage}
                  nextPage={nextPage}
                  gotoLastPage={gotoLastPage}
                  gotoFirstPage={gotoFirstPage}
                  setActivePage={setActivePage}
                />
              </div>
              <div className="flex space-x-2 justify-end">
                <div className="flex mt-1 mr-2 text-gray-500 font-jamjuree">
                  Total Record: {totalConfirmPO}
                </div>
                <ListBox
                  width={'w-20'}
                  data={pageSizeList}
                  type="event pagination"
                  multiple={false}
                  handleSelected={handlePageSizeSelected}
                  defaultValue={pageSizeSelected}
                />
              </div>
            </div>
          </div>
        </div>
        {loading && <Loading />}
        <div className="block mt-20 mx-5 w-full">
          <Table
            columns={columns}
            data={confirmPO}
            manualPagination={true}
            showSelect={false}
            handleSelect={(selected) => console.log(selected)}
            detailComponents={() => {}}
          />
        </div>
      </div>
    );
  }
  return (
    <div className="flex justify-center w-auto h-full items-center overflow-hidden">
      <div
        style={{ borderTopColor: 'transparent' }}
        className="w-16 h-16 border-4 border-blue-400 border-dotted rounded-full animate-spin"
      ></div>
    </div>
  );
}

export default ASNTable;
