import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import { isWidthUp } from '@material-ui/core/withWidth';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import MoreVert from '@material-ui/icons/MoreVert';
import Favorite from '@material-ui/icons/Favorite';
import Phone from '@material-ui/icons/Phone';
import Camera from '@material-ui/icons/Camera';
import Avatar from '@material-ui/core/Avatar';
import Popover from '@material-ui/core/Popover';
import ClickAwayListener from '@material-ui/core/ClickAwayListener';
import ContextUser from './ContextUser';
import { useGradientAvatarStyles } from '@mui-treasury/styles/avatar/gradient';
import { Grid } from '@material-ui/core';
import { vendor_name, username, getUserType, company_name, user_email, alert_email } from "../utils";
import {
  isMobile
} from "react-device-detect";

const styles = ({
  direction,
  spacing,
  transitions,
  breakpoints,
  palette,
  shape,
}) => ({
  header: {
    fontWeight: 900,
    minWidth: 0,
    fontSize: 18,
    fontFamily: "Bai Jamjuree"
  },
  grow: {
    flexGrow: 1,
  },
  search: {
    position: 'relative',
    marginRight: 8,
    borderRadius: shape.borderRadius,
    background:
      palette.type === 'dark' ? palette.background.default : palette.grey[200],
    '&:hover': {
      background: palette.type === 'dark' ? palette.background.paper : palette.grey[300],
    },
    marginLeft: 0,
    width: '100%',
    [breakpoints.up('sm')]: {
      marginLeft: spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    width: spacing(9),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
    width: '100%',
  },
  inputInput: {
    borderRadius: 4,
    paddingTop: spacing(1),
    paddingRight: spacing(direction === 'rtl' ? 10 : 1),
    paddingBottom: spacing(1),
    paddingLeft: spacing(direction === 'rtl' ? 1 : 10),
    transition: transitions.create('width'),
    width: '100%',
    [breakpoints.up('sm')]: {
      width: 120,
      '&:focus': {
        width: 200,
      },
    },
  },
  popover: {
    zoom: "111.11%"
  },
  paper: {
    padding: spacing(1),
  },
});

// pointerEvents: 'none',

const HeaderEx = ({ classes, screen }) => {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [open, setOpen] = React.useState(false);
  const handleClickAway = () => {
    setOpen(false)
  }
  console.log(isMobile);
  const avatarStyles = useGradientAvatarStyles({
    size: 40,
    gap: 3,
    thickness: 3,
    gapColor: '#f4f7fa',
    color: 'linear-gradient(to bottom right, #feac5e, #c779d0, #4bc0c8)',
  });
  
  return(
  <>
    {/* <Grid container spacing={2} justify={"flex-start"} alignItems={"center"}>
      {!isMobile?<Typography noWrap color={'textSecondary'} className={classes.header}>
      SUPPLIER MANAGEMENT PORTAL
      </Typography>:null}
    </Grid> */}
    <Grid container spacing={2} justify={"flex-end"} alignItems={"center"}>
      <Grid item >
        {/* TODO update */}
          {isMobile ? <Typography class="user-name" noWrap color={'textSecondary'} className={classes.header}>{`${username()}`}</Typography>
            : getUserType() === 'vendor' || getUserType() === 'demo' || getUserType() === 'account' ?
              <Typography class="user-name" noWrap color={'textSecondary'} className={classes.header}>{`${username()} [${vendor_name()}]`}</Typography>
              : <Typography class="user-name" noWrap color={'textSecondary'} className={classes.header}>{`${username()} [${company_name()}]`}</Typography>}
          {getUserType() !== 'store' &&
            <Typography class="user-name" noWrap color={'textSecondary'} className={classes.header}>{`[${user_email()}]`}</Typography>
          }
          {getUserType() === 'store' &&
            <Typography class="user-name" noWrap color={'textSecondary'} className={classes.header}>{`[${alert_email()}]`}</Typography>
          }
        </Grid>
      <Grid item>
        <ClickAwayListener onClickAway={handleClickAway} touchEvent={false}>
        <div className={avatarStyles.root}>
        <Avatar src={"./user-circle-solid.svg"} onClick={(e)=> {
          setAnchorEl(e.currentTarget);
          setOpen(!open)
          console.log('yes')
          }}/>
        </div>
        </ClickAwayListener>
        </Grid>
    </Grid>
    <Popover 
      id="over-popover"
      className={classes.popover}
      classes={{
        paper: classes.paper,
      }}
      open={open}
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'center',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
    >
      <ContextUser />
    </Popover>
    {screen === 'xs' && (
      <IconButton>
        <MoreVert />
      </IconButton>
    )}
    {screen === 'sm' && (
      <>
        <IconButton>
          <Favorite />
        </IconButton>
        <IconButton>
          <MoreVert />
        </IconButton>
      </>
    )}
    {isWidthUp('md', screen) && (
      <>
        <IconButton>
          <Favorite />
        </IconButton>
        <IconButton>
          <Phone />
        </IconButton>
        <IconButton>
          <Camera />
        </IconButton>
      </>
    )}
  </>
)};

HeaderEx.propTypes = {
  screen: PropTypes.string,
  classes: PropTypes.shape({}).isRequired,
};
HeaderEx.defaultProps = {
  screen: null,
};

export default withStyles(styles)(HeaderEx);
