import React from 'react';
import {
  Typography,
  Grid,
  TextField,
  IconButton,
  Button,
} from '@material-ui/core';
import { DataContextMobile } from '../ContextData_Mobile';
import BarcodeScannerComponent from 'react-webcam-barcode-scanner';
import CropFreeIcon from '@material-ui/icons/CropFree';
import {
  doToInvoiceOracle,
  doToInvoiceInternal,
  checkExistRecord,
} from '../utils/apis';
import { useSnackbar } from 'notistack';
import ReactLoading from 'react-loading';

const DoToInv = (props) => {
  const [state, setState] = React.useContext(DataContextMobile);
  const [showScanEmpID, setShowScanEmpID] = React.useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = React.useState(false);

  console.log(state);

  const handleSubmit = async () => {
    setLoading(true);

    // check if transit deliveries exist
    let exist = await checkExistRecord(
      state.qrCodeMainChange.split('|')[2],
      state.qrCodeMainChange.split('|')[9],
      'received'
    );
    if (exist === 0) {
      setLoading(false);
      return enqueueSnackbar('ไม่พบใบจัดส่งนี้ในระบบ', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'center',
        },
      });
    }

    doToInvoiceOracle(
      state.qrCodeMainChange.split('|')[3],
      state.qrCodeMainChange.split('|')[2],
      state.qrCodeMainChange.split('|')[4],
      state.qrCodeMainChange.split('|')[5]
    ).then((res) => {
      // alert(JSON.stringify(res));
      setLoading(false);
      if (res.Status === 'ERROR') {
        enqueueSnackbar(res.Message, {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          autoHideDuration: 300000,
        });
      } else {
        // enqueueSnackbar("Change Success", {
        //   variant: "success",
        //   anchorOrigin: {
        //       vertical: 'bottom',
        //       horizontal: 'right',
        //   },
        //   autoHideDuration: 300000
        // })
        // send to chaange in backend
        doToInvoiceInternal({
          vendor_id: state.qrCodeMainChange.split('|')[9],
          invoiceNo: state.qrCodeMainChange.split('|')[2],
        }).then(async (response) => {
          if (response.status === 200) {
            enqueueSnackbar(`Do changes to invoice successfully.`, {
              variant: 'success',
              anchorOrigin: {
                vertical: 'bottom',
                horizontal: 'center',
              },
              autoHideDuration: 300000,
            });
          }
          return setState((state) => ({
            ...state,
            qrCodeMainChange: null,
            qrCodeItem: [],
            employeeID: '',
          }));
        });
      }
    });
  };

  if (showScanEmpID)
    return (
      <div
        style={{ margin: 'auto', alignContent: 'center', textAlign: 'center' }}
      >
        <BarcodeScannerComponent
          onUpdate={(err, result) => {
            if (typeof result.text === 'string') {
              let data = result.text;
              if (data.split('|')[0] === '2') {
                setState((state) => ({
                  ...state,
                  qrCodeMainChange: data,
                  warning: null,
                }));
                return setShowScanEmpID(false);
              }
              if (data.split('|')[0] === '1' || data.split('|')[0] === '0') {
                setState((state) => ({
                  ...state,
                  warning: 'Please scan Employee ID',
                }));
                return setShowScanEmpID(false);
              } else {
                // alert(data);
                setState((state) => ({ ...state, employeeID: data }));
                return setShowScanEmpID(false);
              }
            }
          }}
        />
        {/* <Button color="primary" onClick={() => setShowScanEmpID(false)}>Back</Button> */}
      </div>
    );

  if (loading)
    return <ReactLoading type={'bars'} color={'#444'} className="preloader" />;

  return (
    <Grid
      container
      justify={'space-around'}
      alignItems={'center'}
      direction="row"
    >
      <Typography style={{ margin: 'auto', color: 'red' }}>
        {state.warning}
      </Typography>
      <Grid item xs={12}>
        <TextField
          id="outlined-basic"
          disabled
          fullWidth
          size="small"
          label="Employee ID"
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          value={state.employeeID}
        />
      </Grid>
      {/* <Grid item xs={3} >
        <IconButton aria-label="delete" color="primary"  style={{marginLeft: 30}} onClick={()=> setShowScanEmpID(true)}>
          <CropFreeIcon />
        </IconButton>
        </Grid> */}
      <Grid>
        <Typography style={{ marginBottom: 20 }}>
          เปลี่ยน DO No.{' '}
          <b>
            {state.qrCodeMainChange
              ? state.qrCodeMainChange.split('|')[3]
              : '-'}
          </b>{' '}
          to INV. No.{' '}
          <b>
            {state.qrCodeMainChange
              ? state.qrCodeMainChange.split('|')[2]
              : '-'}
          </b>
        </Typography>
      </Grid>
      <br />
      <Grid container justify="center">
        <div>
          {!props.isDisconnected &&
          !!state.employeeID &&
          !!state.qrCodeMainChange ? (
            <Button
              variant="contained"
              size="small"
              color="primary"
              style={{ margin: 10 }}
              onClick={handleSubmit}
            >
              Submit
            </Button>
          ) : null}
          {/* <Button size="small" variant="contained" color="secondary" onClick={() => setState(state => ({...state, employeeID: "", qrCodeMain:"", warning: null}))}>
          Reset
          </Button> */}
        </div>
      </Grid>
    </Grid>
  );
};

export default DoToInv;
