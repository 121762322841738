import React from 'react';
import { Typography } from '@material-ui/core';
import { ResponsiveBullet } from '@nivo/bullet';
import { getPDSStatus } from '../../utils/apis';
import moment from 'moment';
import { header, company_name, company_id } from '../../utils';
import settings from '../../config';
import _, { first } from 'lodash';
import ReactLoading from 'react-loading';
import PDSChart from '../PDSChart';
import DeliveryChart from '../DeliveryChart';

// make sure parent container have a defined height when using
// responsive component, otherwise height will be 0 and
// no chart will be rendered.
// website examples showcase many properties,
// you'll often use just a few of them.
const MyResponsiveBullet = ({ data /* see data tab */ }) => (
  <ResponsiveBullet
    data={data}
    margin={{ top: 20, right: 20, bottom: 20, left: 50 }}
    spacing={30}
    titleAlign="start"
    titleOffsetX={-30}
    measureSize={0.5}
    measureColors="category10"
    animate={true}
  />
);

const ChartDashbaord = () => {
  const [data, setData] = React.useState(null);
  const [pdsData, setPDSData] = React.useState(null);
  const [loading1, setLoading1] = React.useState(true);
  const [loading2, setLoading2] = React.useState(true);

  const getData = () => {
    return fetch(
      `${
        settings.server
      }/deliveries?company=${company_name()}&_limit=-1&_sort=details.deliveryDate:DESC&createdAt_gte=${moment()
        .add(-7, 'days')
        .startOf('day')
        .format()}&createdAt_lte=${moment().endOf('day').format()}`,
      {
        method: 'GET',
        headers: header(),
      }
    ).then(async (res) => {
      let data = await res.json();
      // filter deliver in the future only TODO
      let filterData = _.filter(
        data,
        (deli) => new moment(deli.details.deliveryDate) <= new moment()
      );
      console.log(data);
      // goupe result by supplier first
      let groupData = sortObject(_.groupBy(filterData, 'user'));
      console.log(groupData);

      let formattedData = _.map(groupData, (value, key) => {
        console.log(key, value);
        if (value.length === 0) return null;
        return {
          supplier: key.substr(0, key.length - 2),
          Transit:
            _.countBy(value, (item) => item.status === 'transit').true || 0,
          Completed:
            _.countBy(value, (item) => item.status === 'completed').true || 0,
        };
      });
      setData(formattedData);
      setLoading1(false);
    });
  };

  const sortObject = (obj) => {
    return Object.keys(obj)
      .sort(function (a, b) {
        return obj[a].length - obj[b].length;
      })
      .reduce((a, v) => {
        a[v] = obj[v];
        return a;
      }, {});
  };

  const getPDSData = async () => {
    // input required inventory_org_id
    let data1 = await getPDSStatus(company_id());

    Promise.all([data1]).then((res) => {
      // console.log(res);
      let details1 = res[0].length !== 0 ? res[0][0].details : [];
      // console.log(details1, details2);
      let allData = [...details1];
      // group by supplier first
      let groupData = sortObject(_.groupBy(allData, 'VENDOR_NAME'));
      console.log(groupData);
      let formattedPDSData = _.map(groupData, (value, key) => {
        // console.log(key, value);
        if (value.length === 0) return null;
        return {
          supplier: key,
          Waiting: _.countBy(value, (item) => !item.STATUS).true || 0,
          Delayed:
            _.countBy(value, (item) => item.STATUS === 'Delayed').true || 0,
        };
      });
      // console.log(formattedPDSData);
      setPDSData(formattedPDSData);
      setLoading2(false);
    });
  };

  React.useEffect(() => {
    // get data
    getData();
    getPDSData();
  }, []);

  if (loading1 || loading2 || !data || !pdsData) {
    return <ReactLoading type={'bars'} color={'#444'} className="preloader" />;
  }

  return (
    <>
      <br />
      <Typography
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontFamily: 'Bai Jamjuree',
        }}
      >
        Last 7 Days Delivery Status
      </Typography>
      <div style={{ height: 30 * data.length }}>
        <DeliveryChart data={data} />
      </div>
      <Typography
        style={{
          textAlign: 'center',
          fontWeight: 'bold',
          fontFamily: 'Bai Jamjuree',
        }}
      >
        PDS Status
      </Typography>
      <div style={{ height: 30 * pdsData.length }}>
        <PDSChart data={pdsData} />
      </div>
    </>
  );
};

export default ChartDashbaord;
