import React from "react";
import moment from "moment";
import { BsCloudUpload } from 'react-icons/bs';
import {
    uploadConfirmASN,
    sendingEmail,
    updateConfirmASN,
    getStoreEmail,
    uploadConfirmSQM,
    uploadByRef,
    getPurchaseEmail,
    sendingEmailPurchase,
} from '../../utils/apis';
import { username } from '../../utils';
import Loading from '../../components/Loading';
import { useSnackbar } from 'notistack';
import _ from 'lodash';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { IoAdd } from "react-icons/io5";

function ConfirmCOC({ data, refetch, setShowConfirm,open }) {
    const { enqueueSnackbar } = useSnackbar();
    const [loading, setLoading] = React.useState(false);
    const [file, setFile] = React.useState();
    const [files, setFiles] = React.useState([]);
    const [fileName, setFilename] = React.useState('');
    const [deliveryDate, setDeliveryDate] = React.useState(
        new moment().format('YYYY-MM-DD')
    );

    const handleFileUpload = async (e) => {
        const file = e.target.files[0];
        console.log("file COC",file);
        setFile(file);
    };

    const handleSubmit = async () => {
        if (!file) {
            return enqueueSnackbar('No upload file', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                autoHideDuration: 5000,
            });
        }

        setLoading(true);

        // let formData = new FormData();
        // // formData.append('files.ConfirmAttachment', file);
        // formData.append(
        //   'data',
        //   JSON.stringify({
        //     // delivery_date: deliveryDate,
        //     // ConfirmAttachment: file,
        //     // uploader: username(),
        //   })
        // );
        // let uploadResult = await uploadConfirmSQM(formData);
        // const result = await uploadResult.json()
        // console.log("result", result.id);
        // console.log("58:", data);
        // console.log("files 60:", files);
        // return
        const myFormData = new FormData()
        myFormData.append("ref", "coc-table")
        // myFormData.append("refId", `${result.id}`)
        myFormData.append("refId", data._id)
        myFormData.append("field", "confirmAttachment")
        myFormData.append("files", file)
        const uploadResult = await uploadByRef(myFormData)
        // console.log("responseUploadAfterCreateRecord", responseUploadAfterCreateRecord.json());
        if (uploadResult.status !== 200) {
            return enqueueSnackbar('Upload fail', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'top',
                    horizontal: 'right',
                },
                autoHideDuration: 5000,
            });
        }
        let uploadOutput = await uploadResult.json();
        // sending email notification and update record
        // get factory store email
        
        let textOnConfirmEmail = `This is automatic generated email to inform that there is a Code of Conduct rev.${file.name.split('-')[1]} confirmed and uploaded by ${data.supplier_code}`
        let purchase = await getPurchaseEmail('purchase', data.factory);
        let purchaseEmail = _.map(purchase, (each) => each.email_alert);
        console.log(purchaseEmail.join(), data.receiver_email);
        let emailResult = await sendingEmailPurchase({
            to: data.receiver_email,
            cc: purchaseEmail.join(),
            subject: 'Automatic Code of Conduct Confirmation',
            templateReferenceId: 666, // template number
            data: {
                url: 'https://smp.aapico.com/#/codeOfconduct',
                supplier_name: data.supplier_name,
                email: username(),
                // supplier_code: supplier_code,
                rev:fileName.split('.')[0],
                text:textOnConfirmEmail,
                name:"COC"
            },
        });

        if (emailResult.status !== 200) {
            setLoading(false);
            refetch();
            setShowConfirm(false);
            return enqueueSnackbar('Email not sent', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                autoHideDuration: 5000,
            });
        } else {
            // update email sent status
            await updateConfirmASN(uploadOutput.id, { email_sent: true });
            setLoading(false);
            refetch();
            setShowConfirm(false);
            return enqueueSnackbar('Email sent successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
                autoHideDuration: 5000,
            });
        }
    };
    React.useEffect(() => {
        // cut string filename for sendEmail
        for (let i = 0; i < data.attachment.length; i++) {
            const file = data.attachment[i];
            console.log("check file per loop :",file );
            console.log("data :",data );
            if(file.type === "application/pdf"){
              setFilename(file.name.split('-')[1])
            }
        }
      }, []);
    return (
        <div>
            {console.log("file name state cut ",fileName)}
            <Dialog
                open={open}
                PaperProps={{
                    style: {
                        minHeight: '500px',
                        width:'700px',
                        boxShadow: 'none',
                        fontFamily: 'Bai Jamjuree',
                        borderRadius: '12px',
                    },
                }}
            >
                <DialogTitle className='text-2xl font-bold font-jamjuree bg-blue-800 '>
                    <div className={`flex justify-between`}
                        onClick={()=>setShowConfirm(false)}>
                        <div className={`flex items-center text-white text-2xl font-bold font-jamjuree`}>SIGN CONFIRM</div>
                        <div className={`transform rotate-45 cursor-pointer text-white`}><IoAdd color={`#FFFFFF`} variant='Linear' size={`60px`} /></div>
                    </div>
                </DialogTitle>


                <span className="flex justify-center text-lg font-bold items-center pt-2">
                    SUPPLIER SIGN CONFIRM
                </span>
                {loading && <Loading />}
                <div className="flex flex-col w-full p-5 space-y-2">
                    <div className="bg-gray-50 p-5 space-y-2 rounded-md border border-gray-300">
                        <div className="w-full space-y-2">
                            <label for="due">Code of Conduct ID</label>
                            <input
                                type="text"
                                name="po"
                                id="po"
                                disabled
                                defaultValue={data._id}
                                placeholder="Po No."
                                className="w-full disabled:opacity-25 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                required
                                onChange={async (e) => { }}
                            ></input>
                        </div>
                        <div className="w-full space-y-2">
                            <label for="due">SUPPLIER NAME</label>
                            <input
                                type="text"
                                name="rel"
                                id="rel"
                                disabled
                                value={data.supplier_name}
                                placeholder="Rel No."
                                className="w-full disabled:opacity-25 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                required
                                onChange={async (e) => { }}
                            ></input>
                        </div>
                        {/* <div className="w-full space-y-2">
            <label for="due">Due Date</label>
            <input
              type="text"
              name="due"
              id="due"
              disabled
              value={data.due_date}
              placeholder="Due Date"
              className="w-full disabled:opacity-25 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
              required
              onChange={async (e) => {}}
            ></input>
          </div> */}
                    </div>
                    <div className="border border-gray-200 bg-gray-50 rounded-md p-4">
                        <div className="flex justify-between">
                            <div>
                                <label for="delivery">Confirm Date</label>
                                <input
                                    type="date"
                                    name="delivery"
                                    id="delivery"
                                    value={deliveryDate}
                                    placeholder="Due Date"
                                    className="bg-gray-50 w-36 h-[32px]
               border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                                    required
                                    onChange={async (e) => setDeliveryDate(e.target.value)}
                                ></input>
                            </div>
                            <div className="flex space-x-2">
                                <div className="flex flex-col space-y-2 mt-5">
                                    <div className="flex w-8 my-auto justify-center hover:cursor-pointer shadow-md ml-auto space-x-2 bg-indigo-500 rounded-lg h-10 text-white align-middle">
                                        <label
                                            for="uploadConfirm"
                                            class="flex flex-col items-center cursor-pointer"
                                        >
                                            <div className="flex w-auto m-auto space-x-2 justify-center">
                                                <BsCloudUpload className="w-4 h-4 m-auto" />
                                            </div>
                                        </label>
                                        <input
                                            id="uploadConfirm"
                                            type="file"
                                            class="hidden"
                                            accept="application/pdf"
                                            onChange={(e) => {
                                                console.log('upload');
                                                handleFileUpload(e);
                                            }}
                                            onClick={(event) => {
                                                console.log('upload');
                                                event.target.value = null;
                                            }}
                                        />
                                    </div>
                                    {file && (
                                        <p className="text-white z-50 bg-indigo-500 px-2 rounded-md">
                                            {file.name}
                                        </p>
                                    )}
                                </div>
                                <button
                                    onClick={(e) => {
                                        setFile();
                                    }}
                                    className="bg-gray-400 p-2 w-auto rounded-md h-10 mt-5 shadow-lg"
                                >
                                    Clear
                                </button>
                            </div>
                        </div>
                    </div>
                </div>



                <DialogActions >
                    <div
                        className={`w-32 h-12 bg-indigo-500 rounded-md select-none cursor-pointer min-w-[120px] text-lg text-white font-semibold items-center flex justify-center
          hover:bg-indigo-700
          `}
                        onClick={handleSubmit}

                    >
                        Confirm
                    </div>
                </DialogActions>
            </Dialog>


        </div>
    );
}

export default ConfirmCOC;