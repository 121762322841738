import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Avatar from '@material-ui/core/Avatar';
import Grid from '@material-ui/core/Grid';
import { DataContextMobile } from '../ContextData_Mobile';

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    color: theme.palette.text.secondary,
  },
}));

export default function AutoGrid(props) {
  const [state, setState] = React.useContext(DataContextMobile);

  console.log(state.items);
  const classes = useStyles();
  const packs = new Array(parseInt(props.item.totalTag)).fill(0);
  // console.log(packs);
  return (
    <div className={classes.root}>
      <Grid container spacing={0} justify={'center'}>
        {packs.map((pack, index) => {
          // console.log(pack);
          let scanned = props.item.scannedTag.includes((index + 1).toString());
          return (
            <Grid
              item
              xs
              style={{
                display: 'flex',
                justifyContent: 'flex-start',
                maxWidth: 'fit-content',
              }}
            >
              <Avatar
                style={{
                  width: '30px',
                  height: '30px',
                  color: 'white',
                  fontSize: 'small',
                  alignItems: 'center',
                  justifyContent: 'center',
                  backgroundColor: scanned ? 'green' : 'grey',
                }}
              >
                {index + 1}
              </Avatar>
            </Grid>
          );
        })}
      </Grid>
    </div>
  );
}
