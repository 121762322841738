import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';
import _ from "lodash";
import { updateUserEmail } from '../../utils/apis';
import { BsTrash, BsPlusCircle } from "react-icons/bs";

const validateEmail = (email) => {
    return String(email)
        .toLowerCase()
        .match(
            /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
        );
};

function FormDialog({ open, setOpen, data, reload }) {
    console.log(data);
    const { enqueueSnackbar } = useSnackbar();
    const [email, setEmail] = React.useState([data.email]);
    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = async () => {
        // update email group with
        // check if any email invalid and
        if (_.some(email, item => !validateEmail(item))) {
            return enqueueSnackbar('Email have been change to invalid email format', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
        }
        console.log(email);

        let updateresult = await updateUserEmail({ email: email[0] }, data.id);
        if (updateresult.status === 200) {
            setOpen(false);
            reload();
            return enqueueSnackbar('Email Group have been update successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
        } else {
            return enqueueSnackbar('Email group is fail to update', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });

        }
    };

    // useEffect(() => {
    //     setOpen(props.open);
    // }, [props]);

    const handleChange = (e, index) => {
        console.log(e.target.value, index);
        let newEmail = [...email];
        newEmail[index] = e.target.value;
        setEmail(newEmail);
    };

    const handleAdd = () => {
        let newEmail = [...email];
        newEmail = [...newEmail, ""];
        setEmail(newEmail);
    }

    const handleDelete = (index) => {
        if (email.length === 1) {
            return enqueueSnackbar('Should exist at least one email', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right',
                },
            });
        }
        let newEmail = [...email];
        newEmail.splice(index, 1);
        console.log(newEmail)
        setEmail(newEmail);
    }

    return (
        <div>
            <Dialog
                open={open}
                onClose={handleClose}
                aria-labelledby="form-dialog-title"
            >
                <DialogTitle id="form-dialog-title">
                    Email group update...
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>

                        <div className="flex space-x-2 justify-between">
                            <p>Edit what you want to make change</p>
                            {/* <button className="w-7 h-7" onClick={handleAdd}>
                                <BsPlusCircle className="w-7 h-7 p-1 bg-indigo-500 text-white rounded-lg " />
                            </button> */}
                        </div>
                    </DialogContentText>
                    {email.map((each, index) => <div className="flex space-x-2"><TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        label="Email Address"
                        type="email"
                        fullWidth
                        defaultValue={each}
                        onChange={(e) => handleChange(e, index)}
                    />
                        {/* <button onClick={() => handleDelete(index)}>
                            <BsTrash className="w-7 h-7 rounded-lg  bg-gray-300 p-1 shadow-lg" />
                        </button> */}
                    </div>)}
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose} color="primary">
                        Cancel
                    </Button>
                    <Button onClick={handleSubmit} color="primary">
                        Submit
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    );
}

export default FormDialog;
