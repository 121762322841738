import React, { Component } from 'react';
import MaterialTable from 'material-table';
import { getPDSStatus } from '../utils/apis';
import { Chip } from '@material-ui/core';
import { vendor_id, getUserType, company_id } from '../utils';
import _ from 'lodash';
import moment from 'moment';
import CloseIcon from '@material-ui/icons/Close';
import HourglassEmptyIcon from '@material-ui/icons/HourglassEmpty';
import ReactLoading from 'react-loading';
import { CsvBuilder } from 'filefy';

let initColumns = [
  {
    title: 'PDS No.',
    field: 'PDS_NUM',
    width: 200,
    render: (rowData) => {
      return rowData.PDS_NUM || 'N/A';
    },
  },
  {
    title: 'Line.',
    field: 'PDS_LINE',
    width: 50,
    render: (rowData) => {
      return rowData.PDS_LINE || 'N/A';
    },
  },
  {
    title: 'Status',
    field: 'STATUS',
    width: 175,
    render: (rowData) => {
      return (
        <Chip
          icon={rowData.STATUS ? <CloseIcon /> : <HourglassEmptyIcon />}
          color={rowData.STATUS ? 'secondary' : 'primary'}
          size="small"
          label={rowData.STATUS ? rowData.STATUS : 'Waiting...'}
        ></Chip>
      );
    },
  },
  {
    title: 'Com.',
    field: 'ORGANIZATION_CODE',
    width: 60,
    render: (rowData) =>
      rowData.ORGANIZATION_CODE === 'APD'
        ? 'AH[APD]'
        : rowData.ORGANIZATION_CODE,
  },
  { title: 'Vendor', field: 'VENDOR_NAME', width: 120 },
  {
    title: 'PO No.',
    field: 'PO_NUM',
    width: 120,
    render: (rowData) => rowData.PO_NUM,
  },
  {
    title: 'REL No.',
    field: 'PO_REL_NUM',
    width: 120,
    render: (rowData) => rowData.PO_REL_NUM,
  },
  // { title: 'Rel.', field: 'PO_REL_NUM', width: 60 },
  { title: 'Item Code', field: 'ITEM_CODE', width: 150 },
  { title: 'Description', field: 'ITEM_DESCRIPTION', width: 300 },
  { title: 'Item Type', field: 'ITEM_TYPE', width: 160 },
  {
    title: 'Need by Date',
    field: 'NEED_BY_DATE',
    defaultSort: 'asc',
    render: (rowData) => new moment(rowData.NEED_BY_DATE).format('YYYYMMDD'),
    width: 130,
  },
  {
    title: 'QTY. Order',
    field: 'QTY_ORDER',
    width: 80,
  },
  {
    title: 'QTY Del.',
    width: 80,
    export: false,
    render: (rowData) =>
      parseFloat(rowData.QTY_ORDER) - parseFloat(rowData.QTY_REMAIN),
  },
  { title: 'QTY Remain', field: 'QTY_REMAIN', width: 80 },
];

const PDSStatus = () => {
  const [data, setData] = React.useState();
  const [timestamp, setTimestamp] = React.useState();
  const [delayedCount, setDelayedCount] = React.useState();
  const [loading, setLoading] = React.useState(true);
  const [columns, setColumns] = React.useState(initColumns);

  React.useEffect(() => {
    if (!data) getData();
  }, []);

  React.useEffect(() => {
    if (company_id() === '310' || company_id() === '291') {
      let newColumns = [...initColumns];
      newColumns.splice(0, 2);
      setColumns(newColumns);
    }
  }, []);

  const getData = async () => {
    if (getUserType() === 'vendor') {
      // only PDS
      let data1 = await getPDSStatus('103');
      let data2 = await getPDSStatus('175');
      let data5 = await getPDSStatus('350');
      // only standard PO
      let data3 = await getPDSStatus('310');
      let data4 = await getPDSStatus('291');
      let data6 = await getPDSStatus('287');
      // TODO Add ASP

      Promise.all([data1, data2, data3, data4, data5, data6]).then((res) => {
        // console.log(res);
        let details1 = res[0].length !== 0 ? res[0][0].details : [];
        let details2 = res[1].length !== 0 ? res[1][0].details : [];
        let details3 = res[2].length !== 0 ? res[2][0].details : [];
        let details4 = res[3].length !== 0 ? res[3][0].details : [];
        let details5 = res[4].length !== 0 ? res[4][0].details : [];
        let details6 = res[5].length !== 0 ? res[5][0].details : [];
        // console.log(details1, details2);
        let FilterPDS = _.filter(
          [...details1, ...details2],
          (item) => item.PDS_NUM
        );

        let allData = [
          ...FilterPDS,
          ...details3,
          ...details4,
          ...details5,
          ...details6,
        ];

        let sortedData = _.map(allData, function (o) {
          return {
            ...o,
            NEED_BY_DATE: new moment(o.NEED_BY_DATE).format('YYYY/MM/DD HH:mm'),
          };
        });

        setTimestamp(res[0][0].createdAt);

        setLoading(false);
        // if (getUserType() !== 'admin') {
        let rows = _.filter(
          sortedData,
          (item) => item.VENDOR_ID === vendor_id()
        );
        let count = _.countBy(rows, (item) => item.STATUS === 'Delayed');
        setDelayedCount(count.true || 0);
        return setData(rows);
        // }

        // let count = _.countBy(sortedData, (item) => item.STATUS === 'Delayed');
        // setDelayedCount(count.true || 0);
        // return setData(sortedData);
      });
    } else {
      let allData = await getPDSStatus(company_id());
      setLoading(false);
      setTimestamp(allData[0] ? allData[0].createdAt : '');
      let PDSData = allData[0] ? allData[0].details : [];
      let count = _.countBy(PDSData, (item) => item.STATUS === 'Delayed');
      setDelayedCount(count.true || 0);
      if (company_id() === '103' || company_id() === '175') {
        let FilterPDS = _.filter(allData[0].details, (item) => item.PDS_NUM);
        return setData(FilterPDS);
      }
      return setData(allData[0] ? allData[0].details : []);
    }
  };

  const handleExportCsv = (columns, data) => {
    console.log(data, columns);
    const columnTitles = columns.map((columnDef) => columnDef.title);

    const csvData = data.map((rowData) => {
      return columns.map((columnDef) => {
        console.log(columnDef);
        if (columnDef.field === 'PO_NUM') {
          return rowData['PO_NUM'];
        }
        if (columnDef.field === 'STATUS') {
          return rowData['STATUS'] ? rowData['STATUS'] : 'Waiting';
        }
        return rowData[columnDef.field] || '-';
      });
    });

    // update data
    console.log(csvData, columns, data);

    const builder = new CsvBuilder(
      `PO Export at ${new moment().format('YYYY-MM-DD')}.csv`
    )
      .setColumns(columnTitles)
      .addRows(csvData)
      .exportFile();
  };

  if (loading) {
    return (
      <ReactLoading type={'bubbles'} color={'#444'} className="preloader" />
    );
  }
  return (
    <div>
      <MaterialTable
        columns={columns}
        data={data}
        title={
          <h2 style={{ fontFamily: 'Bai Jamjuree' }}>
            PDS Status at: {new Date(timestamp).toLocaleString()} [Total
            Delayed: <b style={{ color: 'red' }}>{delayedCount}</b>]
          </h2>
        }
        options={{
          columnsButton: true,
          cellStyle: { fontFamily: 'Bai Jamjuree', textAlign: 'center' },
          pageSize: 100,
          pageSizeOptions: [5, 10, 20, 50, 100],
          headerStyle: {
            fontWeight: 'bold',
            fontFamily: 'Bai Jamjuree',
            textAlign: 'center',
          },
          exportAllData: true,
          exportButton: {
            csv: true,
          },
          padding: 'dense',
          exportFileName: `PDS Status ${new Date().toISOString()}`,
          fixedColumns: {
            left: 1,
          },
          exportCsv: true,
          exportCsv: handleExportCsv,
        }}
      />
    </div>
  );
};

export default PDSStatus;
