import {
  Box,
  Button,
  CircularProgress,
  Grid,
  Step,
  StepLabel,
  Stepper,
} from '@material-ui/core';
import { Field, Form, Formik } from 'formik';
import { CheckboxWithLabel, TextField } from 'formik-material-ui';
import { TextField as OriginalTextField, MenuItem } from '@material-ui/core';
import { mixed, number, object, string } from 'yup';
import React, { useState, useEffect, useCallback } from 'react';
import { Autocomplete } from 'formik-material-ui-lab';
import { useSnackbar } from 'notistack';
import { DataContext } from '../ContextData';
import { newUser } from '../utils';

import _ from 'lodash';
const sleep = (time) => new Promise((acc) => setTimeout(acc, time));

const usertypes = [
  { value: 'vendor', label: 'vendor' },
  { value: 'demo', label: 'demo' },
  { value: 'admin', label: 'admin' },
  { value: 'store', label: 'store' },
  { value: 'account', label: 'account' },
];
export default function Home(props) {
  const [state, setState] = React.useContext(DataContext);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  console.log(state);
  useEffect(() => {
    // let vendorList = vendors();
    // console.log(vendorList)
    // setVendor(vendorList)
  }, []);

  return (
    <div>
      <FormikStepper
        initialValues={{
          username: '',
          email: '',
          password: '',
          confirmPassword: '',
          user_type: '',
        }}
        onSubmit={async (values) => {
          // await sleep(3000);
          // console.log('values', values);
          let result = await newUser(values, enqueueSnackbar);
          // useCallback;
          // reload table using window.reload()
          // TEST
          if (result) {
            window.location.reload();
          }
          // sleep(2000);
          ///
          // return props.closeForm();
        }}
      >
        <FormikStep
          label="Vendor Data"
          validationSchema={object({
            vendor: string().required(),
          })}
        >
          <Box paddingBottom={2}>
            <Field
              name="vendor"
              component={Autocomplete}
              options={state.vendors}
              getOptionLabel={(option) => option.name}
              renderInput={(params) => (
                <OriginalTextField {...params} label="Vendor" fullWidth />
              )}
            />
          </Box>
        </FormikStep>
        <FormikStep
          label="Personal data"
          validationSchema={object({
            username: string().required(),
          })}
        >
          <Box paddingBottom={2}>
            <Field
              fullWidth
              name="username"
              component={TextField}
              label="First Name"
            />
          </Box>
          <Box paddingBottom={2}>
            <Field fullWidth name="email" component={TextField} label="Email" />
          </Box>
          <Box paddingBottom={2}>
            <Field
              fullWidth
              name="password"
              component={TextField}
              label="Password"
            />
          </Box>
          <Box paddingBottom={2}>
            <Field
              fullWidth
              name="confirmPassword"
              component={TextField}
              label="Confirm Password"
            />
          </Box>
          <Box paddingBottom={2}>
            <Field
              fullWidth
              name="user_type"
              component={TextField}
              select
              label="user_type [admin/store/vendor/demo/account]"
            >
              {props.userType.includes('account') ? (
                <MenuItem key={'account'} value={'account'}>
                  {'account'}
                </MenuItem>
              ) : (
                usertypes.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))
              )}
            </Field>
          </Box>
        </FormikStep>
        <FormikStep label="Verify Info">
          <Box paddingBottom={2}>
            {/* <Field fullWidth name="description" component={TextField} label="Description" /> */}
            <code>{JSON.stringify(state.userForm, null, 2)}</code>
          </Box>
        </FormikStep>
      </FormikStepper>
    </div>
  );
}

export function FormikStep({ children, ...props }) {
  console.log(props);
  return <>{children}</>;
}

export function FormikStepper({ children, ...props }) {
  const childrenArray = React.Children.toArray(children);
  const [step, setStep] = useState(0);
  const currentChild = childrenArray[step];
  const [completed, setCompleted] = useState(false);
  const [state, setState] = React.useContext(DataContext);

  function isLastStep() {
    return step === childrenArray.length - 1;
  }

  return (
    <Formik
      {...props}
      validationSchema={currentChild.props.validationSchema}
      onSubmit={async (values, helpers) => {
        setState((state) => ({ ...state, userForm: values }));
        if (isLastStep()) {
          await props.onSubmit(values, helpers);
          setCompleted(true);
        } else {
          setStep((s) => s + 1);
        }
      }}
    >
      {({ errors, isSubmitting }) => {
        return (
          <Form autoComplete="off">
            <Stepper alternativeLabel activeStep={step}>
              {childrenArray.map((child, index) => (
                <Step
                  key={child.props.label}
                  completed={step > index || completed}
                >
                  <StepLabel>{child.props.label}</StepLabel>
                </Step>
              ))}
            </Stepper>
            {currentChild}
            <Grid container spacing={2} justify={'flex-end'}>
              {step > 0 ? (
                <Grid item>
                  <Button
                    disabled={isSubmitting}
                    variant="contained"
                    color="primary"
                    onClick={() => setStep((s) => s - 1)}
                  >
                    Back
                  </Button>
                </Grid>
              ) : null}
              <Grid item>
                <Button
                  startIcon={
                    isSubmitting ? <CircularProgress size="1rem" /> : null
                  }
                  disabled={isSubmitting}
                  variant="contained"
                  color="primary"
                  type="submit"
                >
                  {isSubmitting
                    ? 'Submitting'
                    : isLastStep()
                    ? 'Submit'
                    : 'Next'}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
}
