import React, { Component } from 'react';
import { DropzoneArea } from 'material-ui-dropzone';

const DropzoneAreaExample = ({ getFiles, single }) => {
  const handleChange = (files) => {
    console.log(files);
    getFiles(files);
  };
  return (
    <DropzoneArea
      onChange={handleChange}
      filesLimit={single ? 1 : 3}
      acceptedFiles={single ? ['image/jpeg', 'image/png'] : []}
    />
  );
};

export default DropzoneAreaExample;
