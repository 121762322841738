import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Grid, IconButton } from '@material-ui/core';
import MuiAccordion from '@material-ui/core/Accordion';
import MuiAccordionSummary from '@material-ui/core/AccordionSummary';
import MuiAccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import Pagination from '@material-ui/lab/Pagination';
import EditIcon from '@material-ui/icons/Edit';
import TrashIcon from '@material-ui/icons/Delete';
import Dialog from './dialog';
import { deleteInform } from '../../utils/apis';
import { getUserType } from '../../utils';
import _ from 'lodash';
import Swal from 'sweetalert2';
import VisibilityIcon from '@material-ui/icons/Visibility';
import withReactContent from 'sweetalert2-react-content';
const SweetAlert = withReactContent(Swal);

const Accordion = withStyles({
  root: {
    border: '1px solid rgba(0, 0, 0, .125)',
    boxShadow: '0 8px 20px -6px rgba(0,0,0,0.3)',
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&$expanded': {
      margin: 'auto',
    },
  },
  expanded: {},
})(MuiAccordion);

const AccordionSummary = withStyles({
  root: {
    backgroundColor: 'rgba(0, 0, 0, .03)',
    marginBottom: -1,
    minHeight: 56,
    '&$expanded': {
      minHeight: 56,
    },
  },
  content: {
    '&$expanded': {
      margin: '12px 0',
    },
  },
  expanded: {},
})(MuiAccordionSummary);

const AccordionDetails = withStyles((theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiAccordionDetails);

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
    margin: 'auto',
    marginLeft: '0px',
  },
}));

export default function SimpleAccordion({ data, reload }) {
  console.log(data);
  const classes = useStyles();
  const [show, setShow] = React.useState(false);
  const [pageSize, setPageSize] = React.useState(10);
  const [pageCount, setPageCount] = React.useState(
    Math.ceil(data.length / pageSize)
  );
  const [currentPage, setCurrentPage] = React.useState(1);
  const [pageData, setPageData] = React.useState([]);
  const [selected, setSelected] = React.useState();
  const [userType, setUserType] = React.useState(getUserType());
  const [expand, setExpand] = React.useState(false);
  const toggleAcordion = () => {
    setExpand((prev) => !prev);
  };

  const pageClick = (e, value) => {
    console.log(value);
    setCurrentPage(value);
  };

  const handleDelete = (item) => {
    // delete this news & alert to confirm
    Swal.fire({
      title: 'Do you want to delete this news?',
      showDenyButton: true,
      confirmButtonText: 'Delete',
      denyButtonText: `Don't Delete`,
    }).then(async (result) => {
      /* Read more about isConfirmed, isDenied below */
      if (result.isConfirmed) {
        await deleteInform(item.id);
        Swal.fire('Deleted!', '', 'success');
        reload();
      } else if (result.isDenied) {
        Swal.fire('News is not deleted', '', 'info');
      }
    });
    reload();
  };

  const filterData = () => {
    let newPageData = _.filter(data, (item, index) => {
      return (
        index >= (currentPage - 1) * pageSize && index < currentPage * pageSize
      );
    });
    console.log(data, newPageData, currentPage);
    setPageData(newPageData);
  };

  const viewFile = (file) => {
    window.open(file);
  };

  React.useEffect(() => {
    filterData();
  }, [currentPage]);

  React.useEffect(() => {
    filterData();
  }, []);

  return (
    <div className={classes.root}>
      <Pagination
        count={pageCount}
        style={{ margin: 10 }}
        onChange={pageClick}
        variant="outlined"
        color="primary"
      />

      <Dialog open={show} setOpen={setShow} data={selected} reload={reload} />
      {pageData.map((item, index) => (
        <Accordion
          style={{
            borderTopLeftRadius: index === 0 ? 10 : 0,
            borderTopRightRadius: index === 0 ? 10 : 0,
            borderBottomRightRadius: index === data.length ? 10 : 0,
            borderBottomLeftRadius: index === data.length ? 10 : 0,
          }}
        >
          <AccordionDetails>
            <Grid container fullWidth justify="">
              <Grid item xs={2}>
                <img
                  src={
                    item.thumbnail
                      ? `https://smp.aapico.com/${item.thumbnail.url}`
                      : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAIVBMVEX09PS2trbMzMzo6OjPz8/b29vAwMDu7u7Dw8O4uLje3t6y659EAAABeUlEQVR4nO3c3U7CQBCAUYqI4Ps/sD8FExOdmNnZTjHnJNxu9utOS3rTwwEAAAAAAAAAAODLMuT6/ju/dDd8Gu247qVk7ESW/ZQUdOyj5NuGUiEf87WDktETueX0l9z2cco776NkvaLHgRVOyy6ma53zwZA9lJScyKq3pDCkt6QypLWkNKTzPikN6SypDWksqR2txpLi0eorKR6tvmfXhJCekhkhLSVTQjrukykhHSVzQhpK5oxWQ8mk0dq+ZNJofdi2ZDzk9bf3+OVctss/GA/5yXEdsOJVQzNDluJVQ0JCQvKEhI7rv2LxqiEnEhKSJyQkJE9ISEiekJCQPCEhIXlCQkLyhISE5AkJCckTEhKSJyQkJE9ISEiekJCQPCEhIXlCQkLyhISE5AkJCckTEhKSJyQkJE9ISEiekJCQPCEhIXlCQm0hl+dal2tTSLH7x7oePuRe8vghy/8JuXaFZL9BF+oIKbb9U+tppi1DAAAAAAAAAAAAtvMGsDkRBZH0SJMAAAAASUVORK5CYII='
                  }
                  alt={'thumbnail'}
                  width={180}
                  className="rounded-lg"
                />
              </Grid>
              <Grid item xs={6}>
                <div className="rounded-lg ml-5">
                  <p
                    style={{ fontSize: '22px', marginBottom: '3px' }}
                    className="flex uppercase"
                  >
                    {item.title}
                  </p>
                  <p style={{ fontSize: '16px' }} className="flex">
                    {item.description}
                  </p>
                </div>
              </Grid>

              <Grid item xs={3}>
                {item.files.map((each) => (
                  <div className="">
                    <img
                      src={
                        each.ext === '.png' || each.ext === '.jpg'
                          ? `https://smp.aapico.com/${each.url}`
                          : 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAMgAAADICAMAAACahl6sAAAAIVBMVEX09PS2trbMzMzo6OjPz8/b29vAwMDu7u7Dw8O4uLje3t6y659EAAABeUlEQVR4nO3c3U7CQBCAUYqI4Ps/sD8FExOdmNnZTjHnJNxu9utOS3rTwwEAAAAAAAAAAODLMuT6/ju/dDd8Gu247qVk7ESW/ZQUdOyj5NuGUiEf87WDktETueX0l9z2cco776NkvaLHgRVOyy6ma53zwZA9lJScyKq3pDCkt6QypLWkNKTzPikN6SypDWksqR2txpLi0eorKR6tvmfXhJCekhkhLSVTQjrukykhHSVzQhpK5oxWQ8mk0dq+ZNJofdi2ZDzk9bf3+OVctss/GA/5yXEdsOJVQzNDluJVQ0JCQvKEhI7rv2LxqiEnEhKSJyQkJE9ISEiekJCQPCEhIXlCQkLyhISE5AkJCckTEhKSJyQkJE9ISEiekJCQPCEhIXlCQkLyhISE5AkJCckTEhKSJyQkJE9ISEiekJCQPCEhIXlCQm0hl+dal2tTSLH7x7oePuRe8vghy/8JuXaFZL9BF+oIKbb9U+tppi1DAAAAAAAAAAAAtvMGsDkRBZH0SJMAAAAASUVORK5CYII='
                      }
                      alt={'thumbnail'}
                      width={80}
                    />
                    <IconButton
                      size="small"
                      onClick={() =>
                        viewFile('https://smp.aapico.com' + each.url)
                      }
                      style={{
                        padding: 5,
                        position: 'relative',
                        marginTop: '-10px',
                        marginRight: '-10px',
                        backgroundColor: 'aliceblue',
                      }}
                    >
                      <VisibilityIcon />
                    </IconButton>
                  </div>
                ))}
              </Grid>
              <Grid item xs={1} style={{ textAlign: 'right' }}>
                {(userType.includes('admin') ||
                  getUserType().includes('store')) && (
                  <>
                    <IconButton
                      onClick={() => {
                        setSelected(item);
                        setShow(true);
                      }}
                      size="medium"
                    >
                      <EditIcon />
                    </IconButton>

                    <IconButton
                      onClick={() => {
                        handleDelete(item);
                      }}
                      size="medium"
                    >
                      <TrashIcon />
                    </IconButton>
                  </>
                )}
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
      ))}
    </div>
  );
}
