import React from 'react';
import { DatePicker } from '@material-ui/pickers';
import { Paper, Grid, Button } from '@material-ui/core';
import { getUserType, vendor_id } from '../../utils';
import { getInvoices, updateInvoices } from '../../utils/apis';
import Table from './Table';
import ReactLoading from 'react-loading';
import _ from 'lodash';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import Card from '../../components/Card';

const Invoice_Admin = () => {
  const [month, setMonth] = React.useState(new moment());
  const [userType, setUserType] = React.useState(getUserType());
  const [data, setData] = React.useState([]);
  const [selectedOrg, setSelectedOrg] = React.useState([]);
  const [monthData, setMonthData] = React.useState();
  const [loading, setLoading] = React.useState(false);
  const [groupData, setGroupData] = React.useState({});
  const [sumBy, setSumBy] = React.useState([]);
  const { enqueueSnackbar } = useSnackbar();

  const handleSearchDateChange = (date) => {
    setMonth(date);
  };

  const refreshData = async () => {
    console.log(month.format('MMM-YYYY'));
    setLoading(true);
    let result = await updateInvoices({
      Month: month.format('MMM-YYYY'),
    });
    setLoading(false);
    if (result.status === 'success') {
      await getData();
      return enqueueSnackbar('Invoice data updated successfully', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    } else {
      return enqueueSnackbar('Invoice data updated fail', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };

  const summarizeData = (input) => {
    return _.map(_.groupBy(input, 'ORG_CODE'), (o, idx) => {
      return {
        id: idx,
        total: _.sumBy(o, (item) => parseFloat(item.TOTAL_AMOUNT)).toFixed(2),
      };
    });
  };

  const getGroupData = async () => {
    setLoading(true);

    let allData = await getInvoices();
    // default select to null
    let groupData = _.groupBy(allData[0].data, 'ORG_CODE');
    setMonthData(allData[0].month);
    console.log(groupData);
    setGroupData(groupData);

    // summaryData
    let vendorID = vendor_id();
    if (vendorID !== 'undefined') {
      allData[0].data = _.filter(allData[0].data, (item) => {
        return item.VENDOR_ID == vendorID;
      });
    }
    let sumData = summarizeData(allData[0].data);
    setSumBy(sumData);
    setLoading(false);
  };

  const getData = async () => {
    let currentData = [];
    _.filter(selectedOrg, (each) => {
      currentData = [...currentData, ...groupData[each]];
    });
    console.log(selectedOrg, currentData, groupData);

    // let allData = await getInvoices();
    let vendorID = vendor_id();
    if (currentData.length > 0) {
      if (vendorID !== 'undefined') {
        let allDataFilter = _.filter(currentData, (item) => {
          return item.VENDOR_ID == vendorID;
        });
        return setData(allDataFilter);
      }
      return setData(currentData);
    } else {
      return enqueueSnackbar('No Invoice data found', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
      });
    }
  };
  React.useEffect(() => {
    getGroupData();
  }, []);

  React.useEffect(() => {
    console.log(selectedOrg, sumBy);
    setData([]);
    if (selectedOrg.length > 0) {
      getData();
    }
  }, [selectedOrg]);

  if (!data) {
    return <ReactLoading type={'bars'} color={'#444'} className="preloader" />;
  }
  return (
    <div>
      {userType.includes('admin') && (
        <Paper>
          <Grid
            container
            fullWidth
            direction="row"
            spacing={1}
            alignItems={'center'}
          >
            <Grid
              item
              xs={2}
              style={{
                margin: 10,
              }}
            >
              <DatePicker
                size="small"
                defaultValue={null}
                views={['year', 'month']}
                label="Year and Month"
                value={month}
                onChange={handleSearchDateChange}
                inputVariant="outlined"
                clearable={true}
              />
            </Grid>
            <Button variant="contained" color="primary" onClick={refreshData}>
              Update
            </Button>
          </Grid>
        </Paper>
      )}
      {!loading && (
        <Grid
          container
          direction="row"
          spacing={2}
          fullWidth
          style={{ margin: 10 }}
          justify="center"
        >
          {sumBy.map((item) => (
            <Grid item>
              <Card
                amount={item.total}
                name={'BILLING,' + item.id}
                onCheck={(e, name) => {
                  let newSelect;
                  if (e.target.checked) {
                    // add to list
                    newSelect = [...selectedOrg, name.replace('BILLING,', '')];
                  } else {
                    newSelect = _.filter(
                      selectedOrg,
                      (e) => e !== name.replace('BILLING,', '')
                    );
                  }
                  setSelectedOrg(newSelect);
                }}
              />
            </Grid>
          ))}
        </Grid>
      )}
      <Grid container style={{ marginTop: 10 }}>
        {!data || loading ? (
          <ReactLoading type={'bars'} color={'#444'} className="preloader" />
        ) : (
          <Table data={data} month={monthData} />
        )}
      </Grid>
    </div>
  );
};

export default Invoice_Admin;
