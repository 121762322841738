import React, { useState } from 'react';

export default function Toggle({ handleToggle }) {
  const [enabled, setEnabled] = useState(false);

  React.useEffect(() => {
    handleToggle(enabled);
  }, [enabled]);
  return (
    <div className="relative flex flex-col items-center justify-center overflow-hidden">
      <div className="flex">
        <label class="inline-flex relative items-center mr-5 cursor-pointer">
          <input
            type="checkbox"
            class="w-5 h-5 accent-pink-500 mr-3"
            checked={enabled}
            onChange={() => setEnabled(!enabled)}
          />{' '}
          {enabled ? 'Show Not Download only' : 'Show All'}
        </label>
      </div>
    </div>
  );
}
