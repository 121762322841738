import React, { useEffect, useState } from 'react';
import { DataContext } from '../../ContextData';
import QRCode from 'qrcode';
import moment from 'moment';
import { getUserType, vendor_name } from '../../utils';
import _, { transform } from 'lodash';
import ReactLoading from 'react-loading';
import {
  Text,
  Document,
  Font,
  Page,
  StyleSheet,
  Image,
  View,
  Canvas,
  PDFViewer,
  PDFDownloadLink,
  pdf,
  BlobProvider,
} from '@react-pdf/renderer';
import fontBold from '../../font/BaiJamjuree-Bold.ttf';
import fontLight from '../../font/BaiJamjuree-Light.ttf';

const registerFont = () => {
  Font.register({
    family: 'Bai Jamjuree',
    src: fontBold,
  });

  Font.register({
    family: 'Bai Jamjuree Light',
    src: fontLight,
  });
};

const styles = StyleSheet.create({
  page: {
    padding: 32,
  },
  containerHeaderTop: {
    marginTop: 10,
    flexDirection: 'row',
  },
  container: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
    alignItems: 'stretch',
    marginTop: 0,
  },
  containerPack: {
    // borderBottomWidth: 0.5,
    // borderBottomColor: '#112131',
    // borderBottomStyle: 'dotted'
  },
  image: {
    marginBottom: 0,
  },
  item1: {
    flexDirection: 'column',
    width: '5%',
    paddingTop: 5,
    paddingRight: 15,
    paddingBottom: 5,
  },
  item2: {
    flexDirection: 'column',
    width: '40%',
    paddingTop: 5,
    paddingRight: 15,
    paddingBottom: 5,
  },
  item4: {
    flexDirection: 'column',
    width: '40%',
    paddingTop: 5,
    paddingRight: 15,
    paddingBottom: 5,
  },
  item5: {
    flexDirection: 'column',
    width: '10%',
    paddingTop: 5,
    paddingRight: 15,
    paddingBottom: 5,
    '@media max-width: 400': {
      width: '100%',
      paddingRight: 0,
    },
    '@media orientation: landscape': {
      width: 200,
    },
  },
  leftColumnHeader: {
    flexDirection: 'column',
    width: '20%',
  },
  middleColumnHeader: {
    flexDirection: 'column',
    width: '50%',
  },
  rightColumnHeader: {
    flexDirection: 'column',
    width: '30%',
    right: 0,
  },
  leftColumn: {
    flexDirection: 'column',
    width: '40%',
    paddingTop: 5,
    paddingRight: 15,
    paddingBottom: 5,
  },
  middleColumn: {
    flexDirection: 'column',
    width: '40%',
    paddingTop: 5,
    paddingRight: 15,
  },
  rightColumn: {
    flexDirection: 'column',
    width: '20%',
    paddingTop: 5,
    paddingRight: 15,
    right: 0,
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  text: {
    fontSize: 10,
    margin: 2,
    paddingLeft: 10,
    fontFamily: 'Bai Jamjuree Light',
  },
  textCompany: {
    fontSize: 12,
    margin: 2,
    paddingLeft: 10,
    fontFamily: 'Bai Jamjuree',
  },
  textPack: {
    fontSize: 11,
    margin: 2,
    paddingLeft: 10,
    fontFamily: 'Bai Jamjuree',
  },
  textPackDesc: {
    fontSize: 8,
    margin: 4,
    paddingLeft: 10,
    fontFamily: 'Bai Jamjuree Light',
  },
  textAmount: {
    fontSize: 10,
    margin: 2,
    paddingLeft: 10,
    fontFamily: 'Bai Jamjuree',
  },
  textQR: {
    fontSize: 7,
    marginTop: 0.5,
    marginBottom: 0.5,
    marginLeft: 'auto',
    marginRight: 'auto',
    fontFamily: 'Bai Jamjuree Light',
  },
  textEnd: {
    fontSize: 11,
    margin: 2,
    marginLeft: 'auto',
    marginRight: 'auto',
    fontFamily: 'Bai Jamjuree Light',
  },
  textHeader: {
    fontSize: 15,
    fontFamily: 'Bai Jamjuree',
  },
  containerHeader: {
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  containerHeaderRight: {
    position: 'absolute',
    marginRight: 15,
  },
  // for Lot Sup
  boxPack: {
    margin: 10,
    width: '95%',
    border: 'solid',
    borderWidth: 2,
    // boxShadow: '0 0 8px rgba(0, 0, 0, .5)'
  },
  topBox: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
    alignItems: 'stretch',
  },
  bottomBox: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
    alignItems: 'stretch',
  },
  lotLeft: {
    flexDirection: 'column',
    width: '80%',
    right: 0,
    borderRightWidth: 1,
    borderRightColor: '#112131',
    borderRightStyle: 'solid',
  },
  rowbox: {
    flexDirection: 'row',
    borderBottomWidth: 1,
    borderBottomColor: '#112131',
    borderBottomStyle: 'solid',
  },
  rowboxLeft: {
    width: '65%',
  },
  rowboxRight: {
    width: '35%',
  },
  rowboxbottom: {
    flexDirection: 'row',
  },
  row1left: {
    width: '60%',
    borderRightWidth: 1,
    borderRightColor: '#112131',
    borderRightStyle: 'solid',
  },
  row1right: {
    width: '40%',
  },
  row2left: {
    width: '75%',
    borderRightWidth: 1,
    borderRightColor: '#112131',
    borderRightStyle: 'solid',
  },
  row2right: {
    width: '25%',
  },
  row3left: {
    width: '30%',
    borderRightWidth: 1,
    borderRightColor: '#112131',
    borderRightStyle: 'solid',
  },
  row3right: {
    width: '70%',
  },
  row4left: {
    width: '70%',
    borderRightWidth: 1,
    borderRightColor: '#112131',
    borderRightStyle: 'solid',
  },
  row4right: {
    width: '30%',
  },
  lotRight: {
    flexDirection: 'column',
    width: '20%',
    right: 0,
  },
  pageNumber: {
    position: 'absolute',
    fontSize: 10,
    top: 20,
    right: 30,
    color: 'grey',
    fontFamily: 'Bai Jamjuree',
    marginBottom: 15,
  },
  stickerBox: {
    height: '1.5cm',
    width: '5.4cm',
    borderWidth: 0.5,
    borderColor: '#112131',
    borderStyle: 'solid',
    marginLeft: 'auto',
    marginRight: 'auto',
  },
  textSticker: {
    fontSize: 8,
    marginTop: 4,
    marginLeft: 'auto',
    marginRight: 'auto',
    color: 'grey',
    fontFamily: 'Bai Jamjuree Light',
  },
  watermark: {
    position: 'absolute',
    marginTop: '120mm',
    marginLeft: '20mm',
    transform: 'rotate(315deg)',
    opacity: 0.3,
    textAlign: 'center',
    color: 'red',
    fontSize: 30,
    fontFamily: 'Bai Jamjuree',
    fontWeight: 'bold',
  },
});

const currencyFormat = (num) => {
  return num.toFixed(2).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,');
};

const Output = (props) => {
  const [qrData, setQrData] = useState(null);
  const [qrData_DO_INV, setQrData_DO_INV] = useState(null);
  const [qrDataItem, setQrDataItem] = useState(null);
  const [qrKanbanItem, setQrKanbanItem] = useState(null);
  let state = props.state;

  console.log(state);

  // ADD delivery id to check during header scan
  let headerQR = `0|${state.doNo || !state.isInvoice ? 0 : 1}|${
    state.doNo ? state.doNo : state.invoiceNo
  }|${state.poSelect[0].INV_ORGID}|${moment(
    state.doDate ? state.doDate : state.invoiceDate
  ).format('YYYYMMDD')}|${state.poSelect.length}|${
    state.site['VENDOR_SITE_CODE']
  }|${props.state.poSelect[0].PO_NUMBER}|${props.vendor_id}${
    props.id ? '|' + props.id : ''
  }`;

  let headerQR_DO_INV;
  if (state.invoiceNo && state.doNo) {
    // add qr code for change scan
    headerQR_DO_INV = `2|${state.isInvoice ? 1 : 0}|${state.invoiceNo}|${
      state.doNo
    }|${state.poSelect[0].INV_ORGID} |${moment(state.invoiceDate).format(
      'YYYYMMDD'
    )}|${state.poSelect.length}|${state.site['VENDOR_SITE_CODE']}|${
      props.state.poSelect[0].PO_NUMBER
    }|${props.vendor_id}${props.id ? '|' + props.id : ''}`;
  }
  const getQRData = () =>
    QRCode.toDataURL(headerQR, (err, url) => {
      if (err) throw err;
      // console.log(url);
      setQrData(url);
      // return url
    });

  const getQRData_DO_INV = () =>
    QRCode.toDataURL(headerQR_DO_INV, (err, url) => {
      if (err) throw err;
      // console.log(url);
      setQrData_DO_INV(url);
      // return url
    });

  const getQRDataItem = () => {
    let alls = props.state.poSelect.map(async (po, index) => {
      let qrcode = `1|${state.doNo ? state.doNo : state.invoiceNo}|${
        po.PO_NUMBER
      }|${po.RELEASE_NUM}|${po.LINE_NUM}|${po.ITEM_CODE}|${
        props.state.delAmount[index]
      }|${props.state.qtyLot[index]}|${props.state.lotSup[index]}|${new moment(
        props.state.deliveryDate
      ).format('YYYYMMDD')}|${po.INV_ORGID}|${index + 1}|${po.SHIPMENT_NUM}|${
        props.vendor_id
      }|${po.LINE_TYPE_ID}`;
      return QRCode.toDataURL(qrcode);
    });
    Promise.all(alls).then((res) => setQrDataItem(res));
  };

  const getQRKanbanItem = async () => {
    let qrArray = [];
    await props.state.poSelect.map(async (po, index) => {
      let rows = Array.from(Array(parseInt(state.qtyLot[index])).keys());
      let subqr = [];
      rows.map((row, ind) => {
        // let qrcode = `3|${ind + 1}|${index + 1}|${po.PO_NUMBER}|${
        //   po.RELEASE_NUM
        // }|${po.LINE_NUM}|${po.ITEM_CODE}|${props.state.delAmount[index]}|${
        //   props.state.lotDetails[index][ind]
        // }|${state.doNo ? state.doNo : state.invoiceNo}|${new moment(
        //   props.state.deliveryDate
        // ).format('YYYYMMDD')}
        //   ${
        //     props.state.heatDetails[index]
        //       ? props.state.heatDetails[index][ind]
        //         ? '-' + props.state.heatDetails[index][ind]
        //         : ''
        //       : ''
        //   }
        //   |${po.SHIPMENT_NUM}|${props.vendor_id}|${
        //   // add heat number as lot
        //   po.INV_ORGID
        // }`;
        let qrcode = `3|${ind + 1}|${index + 1}|${po.PO_NUMBER}|${
          po.RELEASE_NUM
        }|${po.LINE_NUM}|${po.ITEM_CODE}|${props.state.delAmount[index]}|${
          props.state.lotDetails[index][ind]
        }|${state.doNo ? state.doNo : state.invoiceNo}|${new moment(
          props.state.deliveryDate
        ).format('YYYYMMDD')}${
          po.ITEM_CODE.includes('PRM') && po.INV_ORGID === '310'
            ? '-' + (ind + 1)
            : ''
        }|${po.SHIPMENT_NUM}|${props.vendor_id}|${
          // add heat number as lot
          po.INV_ORGID
        }`;
        return subqr.push(QRCode.toDataURL(qrcode));
      });
      qrArray.push(subqr);
    });
    // console.log(qrArray);
    // Promise.all(alls).then(res => setQrKanbanItem(res))
    Promise.all(qrArray.map(Promise.all.bind(Promise))).then((res) =>
      setQrKanbanItem(res)
    );
  };

  useEffect(() => {
    if (!qrData) getQRData();
    if (!qrData && state.doNo) getQRData_DO_INV();
    if (!qrDataItem) getQRDataItem();
    if (!qrKanbanItem) getQRKanbanItem();
    registerFont();
  }, []);

  const pdfReady = () => {
    console.log('props', props);
    return (
      <Document
        author="Soknath MIL"
        keywords="aapico, qrcode, received"
        subject="AAPICO Barcode Received"
        title="Delivery Form"
      >
        {state.doNo ? (
          <DeliveryNoteDOINV
            size="A4"
            qrData={qrData_DO_INV}
            original={true}
            print={props.print}
            qrDataItem={qrDataItem}
            state={state}
            company={props.company}
            issueDate={props.issueDate}
          />
        ) : null}
        {getUserType() !== 'store' ? (
          <DeliveryNote
            size="A4"
            qrData={qrData}
            original={true}
            print={props.print}
            qrDataItem={qrDataItem}
            state={state}
            company={props.company}
            issueDate={props.issueDate}
          />
        ) : // if DO & invoice exist add DO change invoice page
        null}
        <DeliveryNote
          size="A4"
          qrData={qrData}
          original={false}
          print={props.print}
          qrDataItem={qrDataItem}
          state={state}
          company={props.company}
          issueDate={props.issueDate}
        />
        <Page size="A4" style={styles.page} wrap>
          {!props.print ? (
            <View
              render={({ pageNumber, totalPages }) => (
                <View style={styles.watermark}>
                  <Text>
                    ไม่สามารถใช้ PSC นี้ได้ โปรดบันทึกไว้ก่อนแล้วไปพิมพ์ในหน้า
                  </Text>
                  <Text>DELIVERY STATUS</Text>
                </View>
              )}
              fixed
            />
          ) : null}

          <LotGroup
            qrData={qrKanbanItem}
            state={state}
            company={props.company}
            vendor_name={props.vendor_name}
            issueDate={props.issueDate}
          />
          {/* <Text style={styles.pageNumber} render={(props) => {
          console.log(props);
          return (
          `Page ${props.pageNumber} of ${props.totalPages}`
        )}} fixed /> */}
          {/* {LotGroup({ size: "A4", qrData: qrKanbanItem, state})} */}
        </Page>
      </Document>
    );
  };

  // console.log(qrData, qrDataItem)
  if (!qrData || !qrDataItem || !qrKanbanItem) {
    // return (
    //   <Document
    //     author="Soknath MIL"
    //     keywords="aapico, qrcode, received"
    //     subject="AAPICO Barcode Received"
    //     title="Delivery Form"
    //   ><Page size="A4"><View><Text style={{margin: "auto"}}>Rendering...</Text></View></Page>
    //   </Document>
    // )
    return null;
  }

  function Iframe(props) {
    return (
      <div
        onContextMenu={(e) => e.preventDefault()}
        dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : '' }}
      />
    );
  }

  return (
    // <PDFDownloadLink document={pdfReady()} fileName="test.pdf">
    //   {({ blob, url, loading, error }) => (loading ?
    //     <ReactLoading type={"bars"} color={"#444"}
    //   className="preloader"/> : 'Download now!')}
    // </PDFDownloadLink>
    <BlobProvider document={pdfReady()}>
      {({ blob, url, loading, error }) => {
        if (loading)
          return (
            <div style={{ margin: 'auto', height: '100%' }}>
              <ReactLoading
                type={'bubbles'}
                color={'#444'}
                className="preloader"
              />
            </div>
          );
        //Create a Blob from the PDF Stream
        const file = new Blob([blob], { type: 'application/pdf' });
        // Do whatever you need with blob here
        const fileURL = URL.createObjectURL(file);
        return (
          <Iframe
            iframe={`<iframe id="center-iframe" height="1000" style="width: 100%;" src=${
              props.print ? fileURL : fileURL.trim() + '#toolbar=0'
            }></iframe>`}
          />
        );
        // return window.open(fileURL);
      }}
    </BlobProvider>
  );
};

const LotSup = (props) => {
  //   console.log(props);
  return (
    <View style={styles.boxPack}>
      <View style={styles.topBox}>
        <View style={styles.lotLeft}>
          <View style={styles.rowbox}>
            <View style={styles.rowboxLeft}>
              {/* TODO add Lot sequence if raw material */}
              <Text style={styles.textCompany}>{props.company}</Text>
            </View>
            <View style={styles.rowboxRight}>
              {/* TODO add Lot sequence if raw material */}
              <Text style={styles.text}>TF-17.1-7, JULY 1, 2021 (Rev.1)</Text>
            </View>
          </View>
          <View style={styles.rowbox}>
            <View
              style={{
                flexDirection: 'column',
                width: '50%',
                borderRightWidth: 1,
                borderRightColor: '#112131',
                borderRightStyle: 'solid',
                alignItems: 'stretch',
              }}
            >
              {/* TODO add Lot sequence if raw material */}
              <Text style={styles.text}>
                Item:{' '}
                <Text style={styles.textPack}>
                  {props.state.poSelect[props.poIndex].ITEM_CODE}
                </Text>
              </Text>
            </View>
            <View>
              {/* TODO add Lot sequence if raw material */}
              <Text style={styles.text}>
                AAPICO Lot:{' '}
                <Text style={styles.textPack}>
                  {new moment(props.state.deliveryDate).format('YYYYMMDD')}
                  {props.state.poSelect[props.poIndex].ITEM_CODE.includes(
                    'PRM'
                  ) && '-' + props.index}
                </Text>
              </Text>
            </View>
          </View>
          <View style={styles.rowbox}>
            <Text style={styles.text}>
              Delivery Date:{' '}
              <Text style={styles.textPack}>{props.state.deliveryDate}</Text>
            </Text>
          </View>
          <View style={styles.row}>
            <Text style={styles.text}>
              PO, Release:{' '}
              <Text style={styles.textPack}>
                {props.state.poSelect[props.poIndex].PO_NUMBER},{' '}
                {props.state.poSelect[props.poIndex].RELEASE_NUM}
              </Text>
            </Text>
          </View>
        </View>
        <View style={styles.lotRight}>
          <Text style={styles.textQR}>
            {(props.state.doNo ? props.state.doNo : props.state.invoiceNo) +
              '|' +
              props.state.poSelect[props.poIndex].PO_NUMBER}
          </Text>
          <Image
            source={{ uri: props.qrData[props.poIndex][props.lotIndex] }}
            style={{
              width: 60,
              height: 60,
              margin: 'auto',
            }}
          ></Image>
          <Text style={styles.textQR}>
            {props.issueDate
              ? new moment(props.issueDate).format('YYYY-MM-DD HH:mm')
              : new moment().format('YYYY-MM-DD HH:mm')}
          </Text>
        </View>
      </View>
      <View style={styles.bottomBox}>
        <View style={styles.lotLeft}>
          <View style={{ flexDirection: 'row' }}>
            <View
              style={{
                flexDirection: 'column',
                width: '50%',
                borderRightWidth: 1,
                borderRightColor: '#112131',
                borderRightStyle: 'solid',
                alignItems: 'stretch',
              }}
            >
              <Text style={styles.text}>
                {' '}
                Qty:{' '}
                <Text style={styles.textPack}>
                  {props.state.lotDetails[props.poIndex][props.lotIndex]}
                </Text>
              </Text>
            </View>
            <View>
              <Text style={styles.text}>
                {' '}
                UOM:{' '}
                <Text style={styles.textPack}>
                  {props.state.poSelect[props.poIndex].UOM}
                </Text>
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.lotRight}>
          <Text style={styles.text}>
            {' '}
            Pack:{' '}
            <Text style={styles.textPack}>
              {props.index}/{props.totalPack}
            </Text>
          </Text>
        </View>
      </View>
      <View style={styles.bottomBox}>
        <Text style={styles.text}>Vendor: {props.vendor_name}</Text>
      </View>
      <View style={styles.row}>
        <Text style={styles.text}>
          Description:{' '}
          <Text style={styles.textPackDesc}>
            {props.state.poSelect[props.poIndex].DESCRIPTION}
          </Text>
        </Text>
      </View>
    </View>
  );
};

function chunkArray(myArray, chunk_size) {
  var index = 0;
  var arrayLength = myArray.length;
  var tempArray = [];

  for (index = 0; index < arrayLength; index += chunk_size) {
    let myChunk = myArray.slice(index, index + chunk_size);
    // Do something if you want with the group
    tempArray.push(myChunk);
  }

  return tempArray;
}

const LotGroup = (props) => {
  // console.log(props);
  // perform loop po
  return props.state.poSelect.map((po, index) => {
    let rows = Array.from(Array(parseInt(props.state.qtyLot[index])).keys());
    let totalPack = props.state.qtyLot[index];
    // splite array into chunk
    //   let chunks = chunkArray(rows, 2);
    // console.log(chunks);
    return (
      <View key={`${index}`} style={styles.containerPack}>
        {rows.map((item, inde) => {
          return (
            <LotSup
              key={`${index}-${inde}`}
              company={props.company}
              poIndex={index}
              lotIndex={item}
              index={item + 1}
              qrData={props.qrData}
              state={props.state}
              totalPack={totalPack}
              vendor_name={props.vendor_name}
              issueDate={props.issueDate}
            />
          );
        })}
      </View>
    );
  });
};

// this is item tags
const Item = (props) => {
  return props.state.poSelect.map((po, index) => {
    return (
      <View style={styles.container} key={index}>
        <View style={styles.item1}>
          <Text style={styles.text}>{index + 1}</Text>
        </View>
        <View style={styles.item2}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '40%' }}>
              <Text style={styles.text}>Item Code:</Text>
            </View>
            <View style={{ width: '60%' }}>
              <Text style={styles.text}>{po.ITEM_CODE}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '40%' }}>
              <Text style={styles.text}>Description:</Text>
            </View>
            <View style={{ width: '60%' }}>
              <Text style={styles.text}>{po.DESCRIPTION}</Text>
            </View>
          </View>

          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '40%' }}>
              <Text style={styles.text}>Lot Sup</Text>
            </View>
            <View style={{ width: '60%' }}>
              <Text style={styles.text}>{props.state.lotSup[index]}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '40%' }}>
              <Text style={styles.text}>Unit Price:</Text>
            </View>
            <View style={{ width: '60%' }}>
              <Text style={styles.text}>{po.UNIT_PRICE}</Text>
            </View>
          </View>
        </View>
        <View style={styles.item4}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '40%' }}>
              <Text style={styles.text}>Due Date:</Text>
            </View>
            <View style={{ width: '60%' }}>
              <Text style={styles.text}>{po.DUE_DATE}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '60%' }}>
              <Text style={styles.text}>Std. Qty/Pack:</Text>
            </View>
            <View style={{ width: '40%' }}>
              <Text style={styles.text}>{po.PIECES_PER_KANBAN}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '60%' }}>
              <Text style={styles.text}>Packs:</Text>
            </View>
            <View style={{ width: '40%' }}>
              <Text style={styles.text}>{props.state.qtyLot[index]}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '60%' }}>
              <Text style={styles.text}>Total Quantity Del.:</Text>
            </View>
            <View style={{ width: '40%' }}>
              <Text style={styles.text}>
                {props.state.delAmount[index].toString()}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '40%' }}>
              <Text style={styles.text}>Amount: </Text>
            </View>
            <View style={{ width: '60%' }}>
              <Text style={styles.textAmount}>
                {currencyFormat(
                  parseFloat(props.state.delAmount[index]) *
                    parseFloat(po.UNIT_PRICE).toFixed(2)
                )}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.item5}>
          <Text style={styles.textQR}>
            Ref:{' '}
            {(props.state.doNo ? props.state.doNo : props.state.invoiceNo) +
              '|' +
              po.PO_NUMBER}
          </Text>
          <Image
            source={{ uri: props.qrDataItem[index] }}
            style={{
              width: 60,
              height: 60,
              margin: 'auto',
            }}
          ></Image>
        </View>
      </View>
    );
  });
};

const DeliveryNote = (props) => {
  let totalAmount = 0;
  props.state.poSelect.map((po, index) => {
    let amount =
      parseFloat(po.UNIT_PRICE) * parseFloat(props.state.delAmount[index]);
    totalAmount = totalAmount + amount;
  });
  return (
    <Page size={props.size} style={styles.page}>
      {!props.print ? (
        <View style={styles.watermark}>
          <Text>
            ไม่สามารถใช้ PSC นี้ได้ โปรดบันทึกไว้ก่อนแล้วไปพิมพ์ในหน้า
          </Text>
          <Text>DELIVERY STATUS</Text>
        </View>
      ) : null}
      <View style={styles.containerHeaderTop}>
        <View style={styles.leftColumnHeader}>
          <Image
            src={`/logo/${props.state.poSelect[0].INV_ORGID}.png`}
            style={{
              width: 60,
              marginLeft: 0,
              marginRight: 'auto',
            }}
          ></Image>
        </View>
        <View style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <Text
            style={{ ...styles.text, marginLeft: 'auto', marginRight: 'auto' }}
          >
            {props.original ? '(Orignal)' : '(Copied)'}
          </Text>
          <Text
            style={{
              ...styles.textHeader,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            {props.company}
          </Text>
          <Text
            style={{ ...styles.text, marginLeft: 'auto', marginRight: 'auto' }}
          >
            {'Purchase Order Shipping Confirm (PSC)'}
          </Text>
        </View>
        <View style={styles.rightColumnHeader}>
          <Text style={styles.textQR}>{'TF-04-05, Oct 01,2020 (Rev.0)'}</Text>
          <View style={styles.stickerBox}>
            <Text style={styles.textSticker}>Receiving Number: </Text>
          </View>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.leftColumn}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>
                {props.state.isInvoice && !props.state.doNo ? 'Invoice' : 'DO'}{' '}
                No:
              </Text>
            </View>
            {props.state.doNo ? (
              <View style={{ width: '50%' }}>
                <Text style={styles.text}>{props.state.doNo}</Text>
              </View>
            ) : (
              <View style={{ width: '50%' }}>
                <Text style={styles.text}>{props.state.invoiceNo}</Text>
              </View>
            )}
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '40%' }}>
              <Text style={styles.text}>
                {props.state.isInvoice && !props.state.doNo ? 'Invoice' : 'DO'}{' '}
                Date:
              </Text>
            </View>
            {props.state.doNo ? (
              <View style={{ width: '60%' }}>
                <Text style={styles.text}>{props.state.doDate}</Text>
              </View>
            ) : (
              <View style={{ width: '60%' }}>
                <Text style={styles.text}>{props.state.invoiceDate}</Text>
              </View>
            )}
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '40%' }}>
              <Text style={styles.text}>PDS No.:</Text>
            </View>
            <View style={{ width: '60%' }}>
              <Text style={styles.text}>
                {props.state.poSelect[0].PDS_NUMBER}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '40%' }}>
              <Text style={styles.text}>Issue Date: </Text>
            </View>
            <View style={{ width: '60%' }}>
              <Text style={styles.text}>
                {props.issueDate
                  ? new moment(props.issueDate).format('YYYY-MM-DD')
                  : new moment().format('YYYY-MM-DD')}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '30%' }}>
              <Text style={styles.text}>Trip:</Text>
            </View>
            <View style={{ width: '70%' }}>
              <Text style={styles.text}>{props.state.poSelect[0].TRIP}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '40%' }}>
              <Text style={styles.text}>Supplier Site:</Text>
            </View>
            <View style={{ width: '60%' }}>
              <Text style={styles.text}>
                {props.state.site.VENDOR_SITE_CODE}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.text}>
              {props.state.site.ADDR.toLowerCase()}
            </Text>
          </View>
        </View>
        <View style={styles.middleColumn}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>PO, Release:</Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>
                {props.state.poSelect[0].PO_NUMBER},{' '}
                {props.state.poSelect[0].RELEASE_NUM}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>Delivery Date:</Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>{props.state.deliveryDate}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>Credit Term:</Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>
                {props.state.poSelect[0].CREDIT_TERMS}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>Dock Code:</Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>
                {props.state.poSelect[0].DOCK_CODE}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>Delivery Cycle:</Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>
                {props.state.poSelect[0].DELIVERY_CYCLE}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>Total Amount:</Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={styles.textAmount}>
                {currencyFormat(totalAmount)}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>Comment:</Text>
            </View>
            <View style={{ width: '150px' }}>
              <Text style={styles.text}>
                {props.state.poSelect[0].COMMENTS}
              </Text>
            </View>
          </View>
        </View>
        {/* top QR code */}
        <View style={styles.rightColumn}>
          <Text style={styles.textQR}>
            Ref:{' '}
            {(props.state.doNo ? props.state.doNo : props.state.invoiceNo) +
              '|' +
              props.state.poSelect[0].PO_NUMBER}
            {/* +
              props.state.deliveryDate.replace(/-/g, '')} */}
          </Text>
          <Image
            source={{ uri: props.qrData }}
            style={{
              width: 60,
              height: 60,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          ></Image>
          <Text style={styles.textQR}>
            {props.issueDate
              ? new moment(props.issueDate).format('YYYY-MM-DD HH:mm')
              : new moment().format('YYYY-MM-DD HH:mm')}
          </Text>
        </View>
      </View>
      <View>{Item(props)}</View>
      <Text> </Text>
      <Text style={styles.textEnd}>--- End of Document ---</Text>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => {
          return `Page ${pageNumber}`;
        }}
        fixed
      />
    </Page>
  );
};

const DeliveryNoteDOINV = (props) => {
  let totalAmount = 0;
  props.state.poSelect.map((po, index) => {
    let amount =
      parseFloat(po.UNIT_PRICE) * parseFloat(props.state.delAmount[index]);
    totalAmount = totalAmount + amount;
  });
  return (
    <Page size={props.size} style={styles.page}>
      {!props.print ? (
        <View style={styles.watermark}>
          <Text>
            ไม่สามารถใช้ PSC นี้ได้ โปรดบันทึกไว้ก่อนแล้วไปพิมพ์ในหน้า
          </Text>
          <Text>DELIVERY STATUS</Text>
        </View>
      ) : null}
      <View style={styles.containerHeaderTop}>
        <View style={styles.leftColumnHeader}>
          <Image
            src={`/logo/${props.state.poSelect[0].INV_ORGID}.png`}
            style={{
              width: 60,
              marginLeft: 0,
              marginRight: 'auto',
            }}
          ></Image>
        </View>
        <View style={{ marginLeft: 'auto', marginRight: 'auto' }}>
          <Text
            style={{ ...styles.text, marginLeft: 'auto', marginRight: 'auto' }}
          >
            {props.original ? '(Orignal)' : '(Copied)'}
          </Text>
          <Text
            style={{
              ...styles.textHeader,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          >
            {props.company}
          </Text>
          <Text
            style={{ ...styles.text, marginLeft: 'auto', marginRight: 'auto' }}
          >
            {'Purchase Order Shipping Confirm (PSC)'}
          </Text>
          {props.state.invoiceNo && props.state.doNo ? (
            <Text
              style={{
                ...styles.text,
                marginLeft: 'auto',
                marginRight: 'auto',
              }}
            >
              {'Change DO to INVOICE'}
            </Text>
          ) : null}
        </View>
        <View style={styles.rightColumnHeader}>
          <Text style={styles.textQR}>{'TF-04-05, Oct 01,2020 (Rev.0)'}</Text>
          <View style={styles.stickerBox}>
            <Text style={styles.textSticker}>Receiving Number: </Text>
          </View>
        </View>
      </View>
      <View style={styles.container}>
        <View style={styles.leftColumn}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '50%' }}>
              {props.state.invoiceNo && props.state.doNo ? (
                <Text style={styles.text}>{'Invoice/DO'} No:</Text>
              ) : (
                <Text style={styles.text}>
                  {props.state.isInvoice ? 'Invoice' : 'DO'} No:
                </Text>
              )}
            </View>
            <View style={{ width: '50%' }}>
              {props.state.invoiceNo && props.state.doNo ? (
                <Text style={styles.text}>
                  {props.state.invoiceNo}/{props.state.doNo}
                </Text>
              ) : (
                <Text style={styles.text}>{props.state.invoiceNo}</Text>
              )}
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '40%' }}>
              {props.state.invoiceNo && props.state.doNo ? (
                <Text style={styles.text}>{'Invoice/DO'} Date:</Text>
              ) : (
                <Text style={styles.text}>
                  {props.state.isInvoice ? 'Invoice' : 'DO'} Date:
                </Text>
              )}
            </View>
            <View style={{ width: '60%' }}>
              {props.state.invoiceNo && props.state.doNo ? (
                <Text style={styles.textPackDesc}>
                  {props.state.invoiceDate}/{props.state.doDate}
                </Text>
              ) : (
                <Text style={styles.text}>{props.state.invoiceDate}</Text>
              )}
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '40%' }}>
              <Text style={styles.text}>PDS No.:</Text>
            </View>
            <View style={{ width: '60%' }}>
              <Text style={styles.text}>
                {props.state.poSelect[0].PDS_NUMBER}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '40%' }}>
              <Text style={styles.text}>Issue Date: </Text>
            </View>
            <View style={{ width: '60%' }}>
              <Text style={styles.text}>
                {props.issueDate
                  ? new moment(props.issueDate).format('YYYY-MM-DD')
                  : new moment().format('YYYY-MM-DD')}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '30%' }}>
              <Text style={styles.text}>Trip:</Text>
            </View>
            <View style={{ width: '70%' }}>
              <Text style={styles.text}>{props.state.poSelect[0].TRIP}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '40%' }}>
              <Text style={styles.text}>Supplier Site:</Text>
            </View>
            <View style={{ width: '60%' }}>
              <Text style={styles.text}>
                {props.state.site.VENDOR_SITE_CODE}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <Text style={styles.text}>
              {props.state.site.ADDR.toLowerCase()}
            </Text>
          </View>
        </View>
        <View style={styles.middleColumn}>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>PO, Release:</Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>
                {props.state.poSelect[0].PO_NUMBER},{' '}
                {props.state.poSelect[0].RELEASE_NUM}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>Delivery Date:</Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>{props.state.deliveryDate}</Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>Credit Term:</Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>
                {props.state.poSelect[0].CREDIT_TERMS}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>Dock Code:</Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>
                {props.state.poSelect[0].DOCK_CODE}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>Delivery Cycle:</Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>
                {props.state.poSelect[0].DELIVERY_CYCLE}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>Total Amount:</Text>
            </View>
            <View style={{ width: '50%' }}>
              <Text style={styles.textAmount}>
                {currencyFormat(totalAmount)}
              </Text>
            </View>
          </View>
          <View style={{ flexDirection: 'row' }}>
            <View style={{ width: '50%' }}>
              <Text style={styles.text}>Comment:</Text>
            </View>
            <View style={{ width: '150px' }}>
              <Text style={styles.text}>
                {props.state.poSelect[0].COMMENTS}
              </Text>
            </View>
          </View>
        </View>
        <View style={styles.rightColumn}>
          <Text style={styles.textQR}>
            Ref:{' '}
            {(props.state.doNo ? props.state.doNo : props.state.invoiceNo) +
              '|' +
              props.state.poSelect[0].PO_NUMBER}
          </Text>
          <Image
            source={{ uri: props.qrData }}
            style={{
              width: 50,
              height: 50,
              marginLeft: 'auto',
              marginRight: 'auto',
            }}
          ></Image>
          <Text style={styles.textQR}>
            {props.issueDate
              ? new moment(props.issueDate).format('YYYY-MM-DD HH:mm')
              : new moment().format('YYYY-MM-DD HH:mm')}
          </Text>
        </View>
      </View>
      <View>{Item(props)}</View>
      <Text> </Text>
      <Text style={styles.textEnd}>--- End of Document ---</Text>
      <Text
        style={styles.pageNumber}
        render={({ pageNumber, totalPages }) => {
          return `Page ${pageNumber}`;
        }}
        fixed
      />
    </Page>
  );
};

export default Output;
