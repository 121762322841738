export const mainPage = [
  {
    target: '.user-profile',
    disableBeacon: true,
    content: 'This is user profile details',
  },
  {
    target: '.user-name',
    disableBeacon: true,
    content: 'This is user name and company details',
  },
  {
    target: '.po-number',
    placement: 'right',
    content: 'Input PO to performace search',
    placement: 'auto',
  },
];
