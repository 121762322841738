import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import Grid from '@material-ui/core/Grid';
import ReactLoading from 'react-loading';
import printJS from 'print-js';
import ScanInstruction from "../components/ScanInstruction";
import { Document, Page, Text, View, StyleSheet, PDFViewer, BlobProvider, Image } from '@react-pdf/renderer';
import QRCode from 'qrcode';
import useKeypress from "../routes/useKeyboardEvent";
import BarcodeReader from 'react-barcode-reader';
import {checkReceived} from "../utils/apis";
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import _ from "lodash";
import moment from "moment";
 
const SweetAlert = withReactContent(Swal)

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
    margin: 20
  },
  paper: {
    padding: theme.spacing(2),
    textAlign: 'center',
    // color: theme.palette.text.secondary,
  },
}));

// Create styles
const styles = StyleSheet.create({
  page: {
    flexDirection: 'row',
    backgroundColor: 'white'
  },
  container: {
    flexDirection: 'row',
    alignItems: 'stretch'
  },
  section: {
    margin: "auto",
    flexGrow: 1
  },
  textLight: {
    fontSize: 6,
    fontFamily: 'Bai Jamjuree Light',
  },
  text: {
    fontSize: 6,
    fontFamily: 'Bai Jamjuree'
  }
});

// Create Document Component
const MyDocument = (props) => {
  // console.log(props);
  return (
    <Document 
      author="Soknath MIL"
      keywords="aapico, qrcode, received"
      subject="AAPICO Barcode Received"
      title="Receiving No. Sticker"
    >{[1,2].map(data => 
      <Page size={{width: 144, height: 40}} orientation="portrait" style={styles.page}>
        <View style={styles.container}>
        <View style={{...styles.section, width: "25%", marginTop: 10}}>
          {/* <Image src={`/logo/103.png`}
            style={{
                width: 60,
                margin: "auto",
                marginLeft: 5
              }}></Image> */}
        </View>
        <View style={{...styles.section, width: "54%", marginTop: 10}}>
          <View style={{flexDirection: "row"}}>
            <View style={{width: "40%"}}>
              <Text style={styles.text}>Rec No: </Text>
            </View>
            <View style={{width: "60%"}}>
              <Text style={styles.text}>{props.recNo}</Text>
            </View>
          </View>
          <View style={{flexDirection: "row"}}>
            <View style={{width: "40%"}}>
              <Text style={styles.text}>Inv. No:</Text>
            </View>
            <View style={{width: "60%"}}>
              <Text style={styles.text}>{props.invoiceNo}</Text>
            </View>
          </View>
          <View style={{flexDirection: "row"}}>
            <View style={{width: "40%"}}>
              <Text style={styles.text}>PO./Rel:</Text>
            </View>
            <View style={{width: "60%"}}>
              <Text style={styles.text}>{props.poNo}/{props.relNo}</Text>
            </View>
          </View>
          <View style={{flexDirection: "row"}}>
            <View style={{width: "40%"}}>
              <Text style={styles.text}>Rec on: </Text>
            </View>
            <View style={{width: "60%"}}>
              <Text style={styles.text}>{moment(props.recStamp).format("YYYY-MM-DD")}</Text>
            </View>
          </View>
        </View>
        <View style={{...styles.section, width: "20%", marginTop: 10}}>
          <Image source={ {uri: props.qrData} } style={{
            width:30,
            height:30,
            margin: "auto"
          }}></Image>
        </View>
        </View>
      </Page>
    )}
    </Document>
  )
};

export default function CenteredGrid() {
  const classes = useStyles();
  const [data, setData] = React.useState("0");
  const [tempData, setTempData] = React.useState(null);
  const [recNo, setRecNo] = React.useState(null);
  const [poNo, setPoNo] = React.useState(null);
  const [relNo, setRelNo] = React.useState(null);
  const [recStamp, setRecStamp] = React.useState(null);
  const [qrData, setQrData] = React.useState(null);
  const [invoiceNo, setInvoiceNo] = React.useState(null);
    

  function Iframe(props) {
    return (<div id="divcontents" dangerouslySetInnerHTML={ {__html:  props.iframe?props.iframe:""}} />);
  }
  
  const checkReceivedNo = async (data) => {
    let checkCode = data.split("|")[0] === "0";  
    setRecNo(null);
    setQrData(null);

    if (!checkCode){
      return SweetAlert.fire({
        title: 'Information!',
        text: 'QR Code ไม่ถูกต้อง',
        icon: 'warning',
        confirmButtonText: 'OK'
      })
    } else {
      // console.log(data);
      let receivedNo = await checkReceived(data.split("|")[2], data.split("|")[8]);
      // console.log(receivedNo)
      let uniqueReceivedNo = _.filter(receivedNo, item => item.status === "completed")
      if (uniqueReceivedNo.length === 0){
        return SweetAlert.fire({
          title: 'Information!',
          text: 'ใบส่งของนี้ ยังไม่ได้ทำรับ',
          icon: 'warning',
          confirmButtonText: 'OK'
        })
      }
      setRecNo(uniqueReceivedNo[0].receivedNo);
      setRecStamp(uniqueReceivedNo[0].timeStamp);
      setPoNo(uniqueReceivedNo[0].details.poSelect[0].PO_NUMBER);
      setInvoiceNo(uniqueReceivedNo[0].details.invoiceNo);
      setRelNo(uniqueReceivedNo[0].details.poSelect[0].RELEASE_NUM);
      getQRData(`${uniqueReceivedNo[0].receivedNo}|${uniqueReceivedNo[0].timeStamp}|${data.split("|")[8]}`)
    }
  }

  const onLoadHandler = (url) => {
    console.log("handle success...");
    // console.log(url)
    printJS({printable:url, type:'pdf', showModal:true})
  }

  useEffect(() => {
    if(tempData) checkReceivedNo(tempData);
  }, [tempData])


  const getQRData = (encode) => QRCode.toDataURL(encode, (err, url) => {
    if (err) throw err
    // console.log(url);
    setQrData(url);
    // return url
  })

  return (
    <div className={classes.root}>
      <BarcodeReader
        onError={err => console.log("err", err)}
        onScan={data => setTempData(data)}
        timeBeforeScanTest={500}
      />
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <ScanInstruction />
        </Grid>
        {recNo && qrData?
        <Grid item xs={12}>
        <BlobProvider document={MyDocument({qrData, recNo, recStamp, poNo, relNo, invoiceNo})}>
                {({ blob, url, loading, error }) => {
                // console.log(loading);
                if (loading) return <div style={{margin: "auto", height: "100%"}}><ReactLoading type={"bubbles"} color={"#444"} className="preloader"/></div>
                
                //Create a Blob from the PDF Stream
                const file = new Blob(
                    [blob], 
                    {type: 'application/pdf'});
                // Do whatever you need with blob here
                const fileURL = URL.createObjectURL(file);
                return <Iframe iframe={`<iframe id="ifmcontentstoprint" height="1000" style="width: 100%;" src=${fileURL} onload="${onLoadHandler(fileURL)}"></iframe>`} />
                // return window.open(fileURL);
                }}
        </BlobProvider>
        </Grid>
        :null}
      </Grid>
    </div>
  );
}