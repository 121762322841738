import React, { createContext, useState, useEffect } from 'react';
import { getVendors, transits, getCompanies } from './utils/apis';
import _ from 'lodash';
import { getUserType, isLogin, login, logout } from './utils';
import ReactLoading from 'react-loading';
import { Route, Redirect, Link } from 'react-router-dom';
import Organization from './utils/company_code';

export const DataContext = createContext([{}, () => {}]);

const ContextProvider = (props) => {
  const [state, setState] = useState({
    companies: null,
    vendors: null,
    sites: [{ VENDOR_SITE_CODE: '', ADDR: null }],
    userForm: null,
    poSelect: [],
    delAmount: [],
    lotSup: [],
    qtyLot: [],
    lotDetails: [],
    heatDetails: [],
    open: false,
    selectedLot: null,
    invoiceNo: null,
    invoiceDate: null,
    isInvoice: true,
    site: null,
    deliveryDate: null,
    currentTransits: null,
    searchTerm: {
      inv_do: '',
      po: '',
      rel: '',
    },
    selectedValue: 'all',
    qrCodeMain: '',
    qrCodeMainChange: '',
    qrCodeItem: [],
    warning: null,
    deleted: false,
    employeeID: null,
    items: [],
    startTour: false,
    version: 'v.1.1.24',
  });

  // console.log(props);

  const getAllCompanies = async () => {
    setState((state) => ({ ...state, companies: [] }));
    await getCompanies().then(async (result) => {
      // let result = await res.json();
      let choice = [];
      let store_orgId = [];
      // console.log(result.Output);
      // Remove uniques company while use real name instead
      // let uniqueRes = _.uniqBy(result.Output, 'COMPANY_NAME');
      // need to show only 5 company first
      result[0].data.Output.map(
        (res) => {
          let fixedCompanies = [
            '103',
            '222',
            '175',
            '330',
            '350',
            '310',
            '291',
            '287',
          ]; // Add AAA
          let selectedOrg = _.filter(
            Organization,
            (org) =>
              org.INVENTORY_ORG_ID === res.INVENTORY_ORG_ID &&
              fixedCompanies.includes(res.INVENTORY_ORG_ID)
          );
          if (
            selectedOrg.length !== 0 &&
            !store_orgId.includes(res.INVENTORY_ORG_ID)
          ) {
            store_orgId.push(res.INVENTORY_ORG_ID);
            return choice.push({
              name:
                selectedOrg.length !== 0
                  ? selectedOrg[0].NAME
                  : res.COMPANY_NAME, // use name in company_code instead
              id: res.INVENTORY_ORG_ID, // unique
              operId: res.OPERATING_UNIT_ORG_ID, // same
            });
          }
        }
        // choice.push(res.COMPANY_NAME)
      );
      // console.log(choice);
      return setState((state) => ({ ...state, companies: choice }));
    });
  };
  const getAllVendors = async () => {
    setState((state) => ({ ...state, vendors: [] }));
    await getVendors().then(async (result) => {
      console.log(result);
      let choiceVendors = [];
      result[0].data.Output &&
        result[0].data.Output.map(
          (res) =>
            choiceVendors.push({
              name: res.VENDOR_NAME,
              id: res.VENDOR_ID,
            })
          // choice.push(res.COMPANY_NAME)
        );
      // console.log(state);
      return setState((state) => ({ ...state, vendors: choiceVendors }));
    });
  };

  const getTransits = async () => {
    setState((state) => ({ ...state, currentTransits: [] }));
    await transits().then(async (res) => {
      let result = await res.json();
      return setState((state) => ({ ...state, currentTransits: result }));
    });
  };

  useEffect(() => {
    console.log('login', isLogin());
    if (isLogin()) {
      if (!state.companies) getAllCompanies();
      if (!state.vendors) {
        if (getUserType().includes('admin') || getUserType() === 'store')
          getAllVendors();
      }
      if (!state.currentTransits) getTransits();
    }
  });

  if (!isLogin) {
    return <Redirect to="/signin" />;
  }

  // TODO remove state.vendors
  if (isLogin() && (getUserType() == 'admin' || getUserType() == 'store')) {
    if (!state.currentTransits || !state.vendors || !state.companies) {
      return (
        <ReactLoading type={'bars'} color={'#444'} className="preloader" />
      );
    }
    return (
      <DataContext.Provider value={[state, setState]}>
        {props.children}
      </DataContext.Provider>
    );
  } else if (
    isLogin() &&
    (getUserType() == 'vendor' || getUserType() == 'demo')
  ) {
    if (!state.currentTransits || !state.companies)
      return (
        <ReactLoading type={'bars'} color={'#444'} className="preloader" />
      );
    return (
      <DataContext.Provider value={[state, setState]}>
        {props.children}
      </DataContext.Provider>
    );
  } else {
    return (
      <DataContext.Provider value={[state, setState]}>
        {props.children}
      </DataContext.Provider>
    );
  }
};

export default ContextProvider;
