import React from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import SkipPreviousIcon from '@material-ui/icons/SkipPrevious';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import SkipNextIcon from '@material-ui/icons/SkipNext';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  details: {
    display: 'flex',
    flexDirection: 'column',
    width: 1200,
  },
  content: {
    flex: '1 0 auto'
  },
  cover: {
    width: 75,
  },
  text: {
    fontFamily: "Bai Jamjuree"
  }
}));

export default function MediaControlCard() {
  const classes = useStyles();
  const theme = useTheme();

  return (
    <Card className={classes.root}>
      <div className={classes.details}>
        <CardContent className={classes.content}>
          <Typography component="h6" variant="h6" className={classes.text}>
            Scan To Print
          </Typography>
          <Typography variant="subtitle2" color="textSecondary" className={classes.text}>
            Please scan PSC barcode to print out the received number.
          </Typography>
        </CardContent>
      </div>
      <Grid container justify={"flex-end"} style={{margin: 10}}>
        <CardMedia
            className={classes.cover}
            image= {`/logo512.png`}
            title="Scan to print"
        />
      </Grid>
    </Card>
  );
}