import React from 'react';
import TableView from './TableView';
import { BsCloudUpload } from 'react-icons/bs';
import { AiOutlineCloseCircle } from 'react-icons/ai';
import {
  getEmailTo,
  getVendors,
  uploadConfirmPO,
  updateConfirmPO,
  uploadConfirmPurchase,
  updateConfirmPurchase,
  sendingEmail,
  deleteConfirmPO,
  getSupplierEmail,
  getAllGroupEmail,
  sendingEmailPurchase,
  uploadByRef,
  uploadFiles,
  deleteConfirmPurchase,
  
} from '../../utils/apis';
import _ from 'lodash';
import { useSnackbar } from 'notistack';
import { useQuery } from '@tanstack/react-query';
import { company_name, username, getUserType, alert_email,user_email } from '../../utils/';
import moment from 'moment';
import Loading from '../../components/Loading';
import Dialogs from '../../components/Dialogs';
import { RiFileExcel2Fill } from "react-icons/ri";
import { SettingsPowerSharp } from '@material-ui/icons';
import { GrDocumentPdf } from "react-icons/gr";
import { IconContext } from 'react-icons'
import { IoTrashOutline } from "react-icons/io5";
// import { Dialog } from '@headlessui/react';


function Purchase() {
  const [vendorCode, setVendorCode] = React.useState('');
  const [vendors, setVendors] = React.useState([]);
  const [emailGroups, setEmailGroups] = React.useState([]);
  const [resultEmails, setResultEmails] = React.useState([]);
  const [selectedEmails, setSelectedEmails] = React.useState('');
  const [searchTerm, setSearchTerm] = React.useState('');
  const [POs, setPOs] = React.useState('');
  const [rels, setRels] = React.useState('');
  const [fileName, setFilename] = React.useState('');
  const [supplierCodes, setSupplierCodes] = React.useState([]);
  const [show, setShow] = React.useState(false);
  const [files, setFiles] = React.useState([]);
  const [dueDates, setDueDates] = React.useState([]);
  const [metaData, setMetaData] = React.useState([]);
  // const [dueDate, setDueDate] = React.useState(moment().format('YYYY-MM-DD'));
  const [reload, setReload] = React.useState(0);
  const [loading, setLoading] = React.useState(false);
  const [activePage, setActivePage] = React.useState(2);
  const { enqueueSnackbar } = useSnackbar();
  const [selectedSupplierCodes, setSelectedSupplierCodes] = React.useState([]);
  const [dialogUpload, setDialogUpload] = React.useState({
    open: false,
    // onConfirm
    // no no follow me
  })


  const handleFileAsync = async (e) => {
    // console.log("log 44 check formatfile : ", e.target.files);
    if (files?.length + e.target.files.length > 2) {
      return enqueueSnackbar(
        `over limit`,
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          autoHideDuration: 300000,
        }
      );
    }
    // if (
    //   _.some(e.target.files, (file) => {
    //     return file.name.split('-').length !== 6;
    //   })
    // ) {
    //   return enqueueSnackbar(
    //     `File format not support. File name should be 
    //     "FAC-SUPPLIER-SEQ-DATE-PO-REL.pdf"
    //     [i.e AHP-JSA-XXX-20221011-4300001-165.pdf]`,
    //     {
    //       variant: 'info',
    //       anchorOrigin: {
    //         vertical: 'bottom',
    //         horizontal: 'right',
    //       },
    //       autoHideDuration: 50000,
    //     }
    //   );
    // }

    if (_.some(e.target.files, (file) => {
      return !file.type.includes("pdf") && !file.type.includes("excel") && !file.type.includes("spreadsheetml")
    })) {
      return enqueueSnackbar(
        `File format not support. Please upload file .xlsx .pdf .xls`,
        {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
          autoHideDuration: 50000,
        }
      );
    }
        // cut string filename for rev on Email
        for (let i = 0; i < e.target.files.length; i++) {
          const file = e.target.files[i];
          // console.log("check file per loop :",file );
          if(file.type === "application/pdf"){
            setFilename(file.name.split('-')[1])
          }
        }
    // console.log("checknamefile",newSupplierCodes);
    // console.log("meta data",metaData);
    // let checkSPCinValid = false
    // _.map(e.target.files, (each) => {
    //   let check = each.name.split('-')
    //   console.log("spc",check[1]);
    //   // return check[1]
    //   console.log("_.some(metaData, supplier => supplier.VENDOR_NAME_ALT !== check[1])", _.some(metaData, supplier => supplier.VENDOR_NAME_ALT === check[1]));
    //   if(!_.some(metaData, supplier => supplier.VENDOR_NAME_ALT === check[1])){
    //     checkSPCinValid = true
    //   }
    // });
    // console.log("check",checkSPCinValid);
    // if(checkSPCinValid){
    //   return enqueueSnackbar(
    //     `File name not support. Please check file name (Supplier Code)`,
    //     {
    //       variant: 'error',
    //       anchorOrigin: {
    //         vertical: 'bottom',
    //         horizontal: 'right',
    //       },
    //       autoHideDuration: 5000,
    //     }
    //   );
    // }


    // const checkSPC = e.target.files.file
    // console.log("spc : ",checkSPC);
    // if(_.find(metaData, supplier => supplier.VENDOR_NAME_ALT === checkSPC) ){
    //   console.log("currect supplier")
    // }else{
    //   console.log("dnt success")
    // }   

    // if (
    //   _.some(e.target.files, (file) => {
    //     if (company_name() !== 'AH') {
    //       return file.name.split('-')[0] !== company_name();
    //     } else {
    //       return file.name.split('-')[0] !== 'APD';
    //     }
    //   })
    // ) {
    //   return enqueueSnackbar(`File name and user's factory is not same.`, {
    //     variant: 'info',
    //     anchorOrigin: {
    //       vertical: 'bottom',
    //       horizontal: 'right',
    //     },
    //     autoHideDuration: 300000,
    //   });
    // }
    let newPOs = [];
    let newRels = [];
    let newDueDates = [];
    let newSupplierCodes = [];
    let newSupplierName = [];
    // _.map(e.target.files, (each) => {
    //   let nameData = each.name.split('-');
    //   newDueDates.push(nameData[3]);
    //   newPOs.push(nameData[4]);
    //   newRels.push(nameData[5].split('.')[0]);
    //   newSupplierCodes.push(nameData[1]);
    // });
    setPOs(newPOs);
    setRels(newRels);
    setDueDates(newDueDates);
    setSupplierCodes(newSupplierCodes);
    const _files = e.target.files;
    setFiles((prev) => {
      if (prev) {
        return [...prev, ..._files]
      }
      return _files
    });
  };

  const getAllGroup = async () => {
    let data = await getAllGroupEmail();
    // console.log("show data : 103 = ",data);
    setEmailGroups(data);
  };



  const handleView = async (e) => {
        console.log("username log download ",username());
        console.log("download count ",e.download_count);
        console.log("getUserType()",getUserType());
    // console.log("check E on 108",e);
    // console.log("e.attachment[0].ext", e.attachment[0].ext);
    if(e.attachment[0].ext !== ".pdf"){
      // console.log("switch to 1");
      const Url = "https://smp.aapico.com" + e.attachment[1].url
      // console.log("testttttttt",pdfUrl);
      // update last download & counter & download
      window.open('https://smp.aapico.com' + e.attachment[1].url, '_blank');
      // console.log("data 109:",e);
      // const link = document.createElement('a');
      // console.log("url file", pdfUrl);
      // link.href = pdfUrl;
      // link.download = 'test.pdf'; // Customize filename if desired
      // link.click();
      fetch(Url)
        .then(response => response.blob())
        .then(blob => {
          // const url = window.URL.createObjectURL(blob);
          const link = document.createElement('a');
          link.href = window.URL.createObjectURL(blob);
          link.download = e.attachment[1].name ;
          link.click();
        });
        
    }
    const pdfUrl = "https://smp.aapico.com" + e.attachment[0].url
    // console.log("testttttttt",pdfUrl);
    // update last download & counter & download
    window.open('https://smp.aapico.com' + e.attachment[0].url, '_blank');
    // console.log("data 109:",e);
    // const link = document.createElement('a');
    // console.log("url file", pdfUrl);
    // link.href = pdfUrl;
    // link.download = 'test.pdf'; // Customize filename if desired
    // link.click();
    fetch(pdfUrl)
      .then(response => response.blob())
      .then(blob => {
        // const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = e.attachment[0].name ;
        link.click();
      });
        
    // no count if user no vendor
    if (getUserType() === 'vendor') {
      console.log("username log download form vender",username());
      console.log("download count from vender",e.download_count+1);
      await updateConfirmPurchase(e.id, {
        download_count: e.download_count + 1,
        downloaded_by: username(),
        downloaded_at: new moment().format('YYYY-MM-DD HH:mm'),
      });
      setReload((reload) => reload + 1);
    }
  };
  
  const handleViewConfirm = async (e) => {
    // console.log("check E on 108",e);
    const pdfUrl = "https://smp.aapico.com" + e.confirmAttachment[0].url
    // console.log("testttttttt",pdfUrl);
    // update last download & counter & download
    window.open('https://smp.aapico.com' + e.confirmAttachment[0].url, '_blank');
    // console.log("data 109:",e);
    // const link = document.createElement('a');
    // console.log("url file", pdfUrl);
    // link.href = pdfUrl;
    // link.download = 'test.pdf'; // Customize filename if desired
    // link.click();
    fetch(pdfUrl)
      .then(response => response.blob())
      .then(blob => {
        // const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = e.confirmAttachment[0].name + e.confirmAttachment[0].etx;
        link.click();
      });
  };

  const handleViewExcel = async (e) => {
        console.log("username log download excel",username());
        console.log("download count excel",e.download_count);
    // console.log("check E on 108",e);
    // console.log("e.attachment[1].ext", e.attachment[1].ext);
    if(e.attachment[1].ext === ".pdf"){
      // console.log("switch to 0");
      const pdfUrl = "https://smp.aapico.com" + e.attachment[0].url
    window.open('https://smp.aapico.com' + e.attachment[0].url, '_blank');
    fetch(pdfUrl)
      .then(response => response.blob())
      .then(blob => {
        // const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = e.attachment[0].name;
        link.click();
      });
      
    }
    const Url = "https://smp.aapico.com" + e.attachment[1].url
    // console.log("testttttttt",pdfUrl);
    // update last download & counter & download
    window.open('https://smp.aapico.com' + e.attachment[1].url, '_blank');
    // console.log("data 109:",e);
    // const link = document.createElement('a');
    // console.log("url file", pdfUrl);
    // link.href = pdfUrl;
    // link.download = 'test.pdf'; // Customize filename if desired
    // link.click();
    fetch(Url)
      .then(response => response.blob())
      .then(blob => {
        // const url = window.URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = window.URL.createObjectURL(blob);
        link.download = e.attachment[1].name ;
        link.click();
      });
    // no count if user no vendor
    // if (getUserType() === 'vendor') {
    //   await updateConfirmPurchase(e.id, {
    //     download_count: e.download_count + 1,
    //     downloaded_by: username(),
    //     downloaded_at: new moment().format('YYYY-MM-DD HH:mm'),
    //   });
    //   setReload((reload) => reload + 1);
    // }
  };

  const handleDelete = async (e) => {
    // update last download & counter & download by
    // if have ANS cannot delete
    if (e.confirmAttachment.length !== 0) {
      return enqueueSnackbar('Cannot delete, Supplier Confirm exist', {
        variant: 'info',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 300000,
      });
    }
    let deleteResult = await deleteConfirmPurchase(e.id);
    if (deleteResult.status == 200) {
      setReload((reload) => reload + 1);
      return enqueueSnackbar('Delete successfully', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 3000,
      });
    }
  };
  const handleSearch = async (e) => {
    console.log(emailGroups, e);
    let resultDrops =
      searchTerm === ''
        ? ''
        : emailGroups.filter((group) =>
          group.name
            .toLowerCase()
            .replace(/\s+/g, '')
            .includes(searchTerm.toLowerCase().replace(/\s+/g, ''))
        );
    if (
      selectedEmails &&
      resultDrops.length !== 0 &&
      resultDrops[0].name === selectedEmails.name
    ) {
      setResultEmails([]);
    } else {
      setResultEmails(resultDrops);
    }
  };
  const handleSubmit = async () => {
    console.log("handleSubmit 260",files)
    // console.log("fileName.split : ",fileName.split('.')[0])
    if (files.length < 2 ) {
      return enqueueSnackbar('No upload file,Please check file upload', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 5000,
      });
    }

    if (selectedEmails.length === 0) {
      return enqueueSnackbar('Please selected email group', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 5000,
      });
    }
    if(!fileName){
      return enqueueSnackbar('Please check filename (.pdf)', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        },
        autoHideDuration: 5000,
      });
    }

      // console.log("responseUploadAfterCreateRecord", responseUploadAfterCreateRecord.json());
    // console.log(fileName);
    // console.log("sub string",fileName.split('.')[0]);
    
    // check file name and selected groupif (
    // if (
    //   _.some(files, (file) => {
    //     console.log("check file",file.name.split('-'), selectedEmails.name.split('-'));
    //     if (selectedEmails.name.split('-')[0] !== 'AH') {
    //       return (
    //         file.name.split('-')[0] !== selectedEmails.name.split('-')[0] ||
    //         file.name.split('-')[1] !== selectedEmails.name.split('-')[1]
    //       );
    //     } else {
    //       return (
    //         file.name.split('-')[0] !== 'APD' ||
    //         file.name.split('-')[1] !== selectedEmails.name.split('-')[1]
    //       );
    //     }
    //   })
    // ) {
    //   return enqueueSnackbar(`File name and email group is not same.`, {
    //     variant: 'info',
    //     anchorOrigin: {
    //       vertical: 'bottom',
    //       horizontal: 'right',
    //     },
    //     autoHideDuration: 300000,
    //   });
    // }

    // _.map([...files], async function (file, index) {
      // console.log("check for some thing :", files);
      // console.log("data.VENDOR_NAME_ALT",metaData );
      
      // start prepare form for submit
      // add sending email status
      setLoading(true);
      let formData = new FormData();
      // formData.append('files.attachment', files);
      formData.append(
        'data',
        JSON.stringify({
          factory: company_name(),
          supplier_name: selectedEmails.supplier_name,
          supplier_code: selectedSupplierCodes,
          vendor_id: selectedEmails.vendor_id,
          receiver_email: selectedEmails.email_list,
          uploader: username(),
          po_number: POs[0],
          rel_number: rels[0],
          due_date: dueDates[0],
        })
      );
        // console.log("check formdata 340 : ",formData);
      let uploadResult = await uploadConfirmPurchase(formData);
      // console.log("uploadResult", uploadResult);
      const result = await uploadResult.json()
      // console.log("result", result.id);
      for (let i = 0; i < files.length; i++) {
        const file = files[i];
        const myFormData = new FormData()
        myFormData.append("ref", "sqm-table")
        myFormData.append("refId", `${result.id}`)
        myFormData.append("field", "attachment")
        myFormData.append("files", file)
        uploadByRef(myFormData)
        // console.log("responseUploadAfterCreateRecord", responseUploadAfterCreateRecord.json());
      }
      if (uploadResult.status !== 200) {
        return enqueueSnackbar('SQM uploaded fails', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 100000,
        });
      }

      let uploadOutput = result
      // TODO sending email notification and update record
      let textOnEmail = `This is automatic generated email to inform that there is a SQM rev.${fileName.split('.')[0]} uploaded and waiting for your confirmation.`
      let emailResult = await sendingEmailPurchase({
        to: selectedEmails.email_list.split(","),
        cc: user_email() || null,
        subject: 'Automatic SQM Confirmation',
        templateReferenceId: 666, // template number
        data: {
          url: 'https://smp.aapico.com/#/purchase',
          supplier_name: selectedEmails.supplier_name,
          email: username(),
          rev:fileName.split('.')[0],
          text:textOnEmail,
          name:"SQM"
        },
      });

      if (emailResult.status !== 200) {
        setShow(false);
        setLoading(false);
        setReload((reload) => reload + 1);
        return enqueueSnackbar('Email not sent', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 5000,
        });
      } else {
        // update email sent status
        await updateConfirmPurchase(uploadOutput.id, { mail_sent: true });
        setLoading(false);
        setShow(false);
        setReload((reload) => reload + 1);
        return enqueueSnackbar('Email sent successfully', {
          variant: 'success',
          anchorOrigin: {
            vertical: 'top',
            horizontal: 'right',
          },
          autoHideDuration: 3000,
        });
      }
    // });
  };
  const {data, isLoading,isFetched} = useQuery(
    ['MetaData'],
    () =>
    getVendors()
  );

  React.useEffect(() => {
    getAllGroup();
  }, []);
  React.useEffect(() => {
    if(!isLoading){
      // const metaData = data[0].data.Output.filter(dataVender => dataVender.VENDOR_NAME_ALT !== null).map(data => ({VENDOR_NAME_ALT: data.VENDOR_NAME_ALT}))
      const metaData = data[0].data.Output.filter(dataVender => dataVender.VENDOR_NAME_ALT !== null)
      setMetaData(metaData)
    }
  }, [isFetched]);

  React.useEffect(() => {
    // perform
    handleSearch();
  }, [searchTerm]);


  const handleOnChangeSupplierName = (each) => {
    console.log("check each : ", each);
    console.log(metaData);
    const supplierCode = metaData.find(data => {
      return data.VENDOR_ID === each.vendor_id
    })
    console.log("check supplierCode", supplierCode);
    if(supplierCode === undefined) return enqueueSnackbar(`Supplier code don't exist in oracle,Please contract oracle team`, {
      variant: 'error',
      anchorOrigin: {
        vertical: 'top',
        horizontal: 'right',
      },
      autoHideDuration: 5000,
    })
    setSelectedSupplierCodes(supplierCode.VENDOR_NAME_ALT)
    setSearchTerm(each.name);
    setSelectedEmails(each);
    setResultEmails([]);
  }

  return (
    <div>
      <div>
        <Dialogs
          open={dialogUpload.open}
          onConfirm={() => {
            handleSubmit()
            setDialogUpload(prev => ({
              ...prev,
              open: false
            }))
          }}
          onClose={() => {
            setDialogUpload(prev => ({
              ...prev,
              open: false,
            }))
            setResultEmails([])
            setFiles([])
            setSearchTerm('')
            setSelectedEmails('')
        }}
        >
          <div className="px-4">
            {/* input supplier name */}

            <div className="flex w-1/2 p-4">
              <div className="flex flex-col w-64">
                <label for="due">Supplier Name</label>
                <input
                  type="text"
                  name="Group mail"
                  id="groupMail"
                  value={searchTerm}
                  placeholder="Group Mail"
                  className=" w-64 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  required
                  onChange={(e) => setSearchTerm(e.target.value)}
                ></input>
                {resultEmails.length !== 0 && (
                  <div className="flex absolute z-50 w-84 max-h-72 rounded shadow bg-white overflow-hidden peer-checked:flex flex-col mt-12 border border-gray-200">
                    {resultEmails.map((each) => (
                      <div class="cursor-pointer group">
                        <button
                          onClick={() => {
                            handleOnChangeSupplierName(each)
                          }}
                          className="block w-full p-2 border-transparent border-l-4 hover:border-blue-600 hover:bg-gray-100 text-left"
                        >
                          {each.name} [{each.description}]
                        </button>
                      </div>
                    ))}
                  </div>
                )}
              </div>
              {/* upload button */}
              <div className="flex items-end justify-end px-2 ">
                <div className="bg-indigo-500 p-2 w-auto rounded-md h-10 shadow-lg hover:bg-indigo-700">
                  <label
                    for="upload"
                    class="flex flex-col items-center cursor-pointer"
                  >
                    <div className="flex justify-center text-white gap-2 ">
                      <div className={`flex items-center justify-center`}><BsCloudUpload className="w-4 h-4" /></div>
                      <div>Upload</div>
                    </div>
                  </label>
                  <input
                    id="upload"
                    type="file"
                    multiple="true"
                    class="hidden"
                    // accept="application/pdf"
                    onChange={(e) => {
                      console.log('upload');
                      handleFileAsync(e);
                    }}
                    onClick={(event) => {
                      console.log('upload');
                      event.target.value = null;
                    }}
                  />
                </div>

                {/* {files && (
                      <div className="p-2 bg-gray-200 rounded-md z-50 space-y-2 shadow-lg">
                        {[...files].map((file) => (
                          <p className="text-gray-800 border-2 border-gray-300 p-1 rounded-md">
                            {file.name}
                          </p>
                        ))}
                      </div>
                    )} */}
              </div>
              <div className={`flex items-end justify-center px-2`}>
                <button
                  onClick={(e) => {
                    setFiles();
                  }}
                  className="bg-gray-400 p-2 w-auto rounded-md h-10 shadow-lg"
                >
                  Clear
                </button>
              </div>
              {/* input email group */}
              {/* <div className="flex w-full mx-2 space-x-2">
                  {selectedEmails &&
                    selectedEmails.email_list.split(',').map((email) => {
                      return (
                        <span className="flex p-2 bg-indigo-500 mt-5 rounded-md break-normal text-white">
                          {email}
                        </span>
                      );
                    })}
                </div> */}
            </div>
            <div className=" flex flex-wrap justify-between gap-1">
              {selectedEmails &&
                selectedEmails.email_list.split(',').map((email) => {
                  return (
                    <div className=" p-2 bg-indigo-500 mt-5 rounded-md break-normal text-white">
                      {email}
                    </div>
                  );
                })}
            </div>
            <div className="flex justify-between w-1/2">
              <div className="flex space-x-2">
                {/* Due Date */}
                {/* <div>
                    <label for="due">Due Date</label>
                    <input
                      type="date"
                      name="due"
                      id="due"
                      value={dueDate}
                      placeholder="Due Date"
                      className="bg-gray-50 w-36 h-[32px]
                   border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                      required
                      onChange={async (e) => setDueDate(e.target.value)}
                    ></input>
                  </div> */}
              </div>
            </div>
            <div className={'p-4'}>
              {/* {console.log("check files", files)} */}
              {/* dialog filter files */}
              {
                files && [...files].map((file, indexFile) => (
                  file.type.includes("pdf")
                    ?
                    <div key={`file${indexFile}`} className="flex p-2 rounded-md gap-4 shadow-lg justify-between w-full">
                      <div className="flex items-center  text-gray-800  bg-red-500 border-2 border-black rounded-md text-lg gap-4 w-full overflow-hidden overflow-ellipsis">
                        <div className={`pl-2`}><GrDocumentPdf /></div>
                        <div className={`truncate  w-full`}>{file.name}</div>
                      </div>
                      <div
                        className={`text-white bg-red-600 rounded-lg p-2 flex items-center justify-center cursor-pointer w-[30px] h-[30px]`}
                        onClick={() => {
                          setFiles(prev => {
                            const cloneFiles = _.cloneDeep(prev)
                            cloneFiles.splice(indexFile, 1)
                            return cloneFiles
                          })
                        }}
                      >
                        <IoTrashOutline />
                      </div>
                    </div>
                    :
                    <div key={`file${indexFile}`} className="flex p-2 rounded-md gap-4 shadow-lg justify-between w-full">
                      <div className="flex items-center  text-gray-800  bg-green-500 border-2 border-black rounded-md text-lg gap-4 w-full overflow-hidden overflow-ellipsis">
                        <div className={`pl-2`}><RiFileExcel2Fill /></div>
                        <div className={`truncate  w-full`}>{file.name}</div>
                      </div>
                      <div
                        className={`text-white bg-red-600 rounded-lg p-2 flex items-center cursor-pointer w-[30px] h-[30px]`}
                        onClick={() => {
                          setFiles(prev => {
                            const cloneFiles = _.cloneDeep(prev)
                            cloneFiles.splice(indexFile, 1)
                            return cloneFiles
                          })
                        }}
                      >
                        <IoTrashOutline />
                      </div>
                    </div>
                ))
              }
            </div>
          </div>
        </Dialogs>
      </div>
      {loading && <Loading />}
      {show && (
        <div className="m-2 flex space-x-2 bg-white rounded-md p-2 pr-5 pt-4 pb-6">
          {/* input supplier name */}
          <button
            onClick={() => setShow(false)}
            className="absolute bg-white p-1 rounded-full right-0 mr-0 -mt-6 shadow-lg"
          >
            <AiOutlineCloseCircle className="w-6 h-6 bg-gray-50" />
          </button>
          <div className="flex w-1/2">
            <div className="flex flex-col w-64">
              <label for="due">Supplier Name</label>
              <input
                type="text"
                name="Group mail"
                id="groupMail"
                value={searchTerm}
                placeholder="Group Mail"
                className=" w-64 bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                required
                onChange={async (e) => setSearchTerm(e.target.value)}
              ></input>
              {resultEmails.length !== 0 && (
                <div className="flex absolute z-50 w-84 max-h-72 rounded shadow bg-white overflow-hidden peer-checked:flex flex-col mt-12 border border-gray-200">
                  {resultEmails.map((each) => (
                    <div class="cursor-pointer group">
                      <button
                        onClick={() => {
                          setSearchTerm(each.name);
                          setSelectedEmails(each);
                          setResultEmails([]);
                          console.log("check each : ", each);
                        }}
                        className="block w-full p-2 border-transparent border-l-4 hover:border-blue-600 hover:bg-gray-100 text-left"
                      >
                        {each.name} [{each.description}]
                      </button>
                    </div>
                  ))}
                </div>
              )}
            </div>
            {/* input email group */}
            <div className="flex w-full mx-2 space-x-2">
              {selectedEmails &&
                selectedEmails.email_list.split(',').map((email) => {
                  return (
                    <span className="flex p-2 bg-indigo-500 mt-5 rounded-md break-normal text-white">
                      {email}
                    </span>
                  );
                })}
            </div>
          </div>
          <div className="flex justify-between w-1/2">
            <div className="flex space-x-2">
              {/* Due Date */}
              {/* <div>
                <label for="due">Due Date</label>
                <input
                  type="date"
                  name="due"
                  id="due"
                  value={dueDate}
                  placeholder="Due Date"
                  className="bg-gray-50 w-36 h-[32px]
               border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block p-2.5 dark:bg-gray-600 dark:border-gray-500 dark:placeholder-gray-400 dark:text-white"
                  required
                  onChange={async (e) => setDueDate(e.target.value)}
                ></input>
              </div> */}
            </div>
            <div className="flex mt-5 h-10 space-x-2">
              {/* upload button */}
              <div className="flex flex-col space-y-2">
                <div className="flex w-8 my-auto justify-center hover:cursor-pointer shadow-md ml-auto space-x-2 bg-indigo-500 rounded-lg h-10 text-white align-middle">
                  <label
                    for="upload"
                    class="flex flex-col items-center cursor-pointer"
                  >
                    <div className="flex w-auto m-auto space-x-2 justify-center h-10">
                      <BsCloudUpload className="w-4 h-4 m-auto" />
                    </div>
                  </label>
                  <input
                    id="upload"
                    type="file"
                    multiple="true"
                    class="hidden"
                    // accept="application/pdf"
                    onChange={(e) => {
                      console.log('upload');
                      handleFileAsync(e);
                    }}
                    onClick={(event) => {
                      console.log('upload');
                      event.target.value = null;
                    }}
                  />
                </div>
                {console.log("check files", files)}
                {/* dialog */}
                {
                  files && [...files].map((file, indexFile) => (
                    file.type.includes("pdf")
                      ?
                      <div className="p-2 bg-red-400 rounded-md z-50 space-y-2 shadow-lg">
                        <p className="text-gray-800 border-2 border-black p-1 rounded-md text-lg">
                          {file.name}
                        </p>
                      </div>
                      :
                      <div className="p-2 bg-green-500 rounded-md z-50 space-y-2 shadow-lg">
                        <p className="text-gray-800 border-2 border-black p-1 rounded-md text-lg">
                          {file.name}
                        </p>
                      </div>
                  ))
                }
                {/* {files && (
                  <div className="p-2 bg-gray-200 rounded-md z-50 space-y-2 shadow-lg">
                    {[...files].map((file) => (
                      <p className="text-gray-800 border-2 border-gray-300 p-1 rounded-md">
                        {file.name}
                      </p>
                    ))}
                  </div>
                )} */}
              </div>
              <button
                onClick={(e) => {
                  setFiles();
                }}
                className="bg-gray-400 p-2 w-auto rounded-md h-10 shadow-lg"
              >
                Clear
              </button>
              <button
                onClick={handleSubmit}
                className="bg-indigo-500 text-white rounded-md p-2 "
              >
                
                Submit
              </button>
            </div>
          </div>
        </div>
      )}
      <div>
        <TableView
          show={show}
          reload={reload}
          setShow={setShow}
          handleDelete={handleDelete}
          handleView={handleView}
          handleViewExcel={handleViewExcel}
          handleViewConfirm={handleViewConfirm}
          onClickAdd={() => setDialogUpload(prev => ({ ...prev, open: true }))}
        />
      </div>
    </div>
  );
}

export default Purchase;
