import React from 'react';
import { Table } from '../../components/Table';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import settings from '../../config';
import { AiOutlineEdit } from 'react-icons/ai';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { header, getUserType } from '../../utils';
import { updateUser, deleteUser } from '../../utils';
import IconButton from '@material-ui/core/IconButton';
import DeleteIcon from '@material-ui/icons/Delete';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Form from '../../components/Forms';
import { useSnackbar } from 'notistack';
import { useConfirm } from 'material-ui-confirm';
import { userId } from '../../utils';
import EditIcon from '@material-ui/icons/Edit';
import DialogEmail from '../../components/Dialog';
import { Grid } from '@material-ui/core';
import _ from 'lodash';
import EmailDialog from './EmailDialog';
// import makeData from '../utils/data'

// Let's simulate a large dataset on the server (outside of our component)
// const serverData = makeData(10000)
const useStyles = makeStyles((theme) => ({
  root: {
    margin: theme.spacing(2),
    wordBreak: 'break-word',
  },
  button: {
    marginBottom: theme.spacing(2),
  },
}));

function App() {
  const classes = useStyles();
  const theme = useTheme();
  const confirm = useConfirm();
  const [open, setOpen] = React.useState(false);
  // We'll start our table without any data
  const [show, setShow] = React.useState(false);
  const [editData, setEditData] = React.useState();
  const [data, setData] = React.useState([]);
  const [loading, setLoading] = React.useState(false);
  const [pageCount, setPageCount] = React.useState(0);
  const [remoteData, setRemoteData] = React.useState([]);
  const [filterData, setFilterData] = React.useState([]);
  const [selectedUser, setSelectedUser] = React.useState();
  const [showEmailDialog, setShowEmailDialog] = React.useState(false);
  const fetchIdRef = React.useRef(0);
  const { enqueueSnackbar } = useSnackbar();
  const fullScreen = useMediaQuery(theme.breakpoints.down('sm'));
  const handleChange = async (original) => {
    // console.log(original)
    confirm({
      description: `This will permanently disable current user from accessing the system.`,
    })
      .then(async () => {
        await updateUser(
          original.id,
          { blocked: !original.blocked },
          enqueueSnackbar
        );
        window.location.reload();
      })
      .catch(() => console.log('Deletion cancelled.'));
  };
  const handleDelete = (original) => {
    confirm({
      description: `This will permanently delete current user from accessing the system.`,
    })
      .then(async () => {
        await deleteUser(original.id, enqueueSnackbar);
        window.location.reload();
      })
      .catch(() => console.log('Deletion cancelled.'));
  };
  const handleEditAlert = (original) => {
    console.log(original);
    setEditData(original);
    setShow(true);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
  };

  let columns = [
    {
      Header: 'Username',
      accessor: 'username',
      width: 60,
    },
    {
      Header: 'Email',
      accessor: 'email',
      Cell: ({ row: { original } }) => {
        return <div className="flex space-x-2">
          <div>{original.email}</div>
          <button className="" onClick={() => {
            setSelectedUser(original);
            setShowEmailDialog(true)
          }}>
            <AiOutlineEdit className="w-7 h-7 bg-indigo-400 text-white p-1 rounded-lg" />
          </button></div>
      }
    },
    // {
    //   Header: 'Alert Email',
    //   accessor: 'email_alert',
    // },
    {
      Header: 'Vendor Name',
      accessor: 'vendor_name',
    },
    {
      Header: 'Vendor Code',
      accessor: 'vendor_code',
    },
  ];

  if (getUserType() === 'store') {
    columns = [
      ...columns,
      {
        Header: 'Alert Email',
        accessor: 'email_alert',
        Cell: ({ row: { original } }) => {
          return (
            <div className="flex">
              <div className="flex w-4/5">
                <p>{original.email_alert}</p>
              </div>
              <div className="flex w-1/5">
                <IconButton
                  color="primary"
                  aria-label="edit"
                  disabled={true}
                  // disabled={original.id === userId() ? true : false}
                  component="span"
                  className="flex"
                  // onClick={() => handleEditAlert(original)}
                >
                  <EditIcon />
                </IconButton>
              </div>
            </div>
          );
        },
      },
    ];
  }
  if (getUserType().includes('admin')) {
    columns = [
      ...columns,
      {
        Header: 'Blocked',
        Cell: ({ row: { original } }) => {
          return (
            <FormControlLabel
              control={
                <Switch
                  disabled={original.id === userId() ? true : false}
                  checked={original.blocked}
                  onChange={() => handleChange(original, data)}
                  name="checkedB"
                  color="primary"
                />
              }
            />
          );
        },
      },
      {
        Header: 'Actions',
        Cell: ({ row: { original } }) => {
          return (
            <>
              <IconButton
                color="primary"
                aria-label="edit"
                disabled={original.id === userId() ? true : false}
                component="span"
                onClick={() => handleDelete(original)}
              >
                <DeleteIcon />
              </IconButton>
            </>
          );
        },
        width: 50,
      },
    ];
  }

  const onFilter = (e) => {
    let filteredData;
    filteredData = _.filter(remoteData, (item) =>
      item.vendor_name.includes(e.target.value.toUpperCase())
    );
    if (!e.target.value) {
      filteredData = remoteData;
    }
    setFilterData(filteredData);
  };

  const getRemoteData = () => {
    // add filter by useer type
    let query;
    if (getUserType() === 'account_admin') {
      query = `?user_type=account&_limit=-1&_sort=createdAt:DESC`;
    } else {
      query = `?user_type=vendor&user_type=demo&_limit=-1&_sort=createdAt:DESC`;
    }
    fetch(`${settings.server}/users${query}`, {
      headers: header(),
    }).then(async (res) => {
      let data = await res.json();
      // console.log(data);
      setRemoteData(data);
      setFilterData(data);
    });
  };

  React.useEffect(() => {
    getRemoteData();
  }, []);

  const fetchData = React.useCallback(
    ({ pageSize, pageIndex }) => {
      // This will get called when the table needs new data
      // You could fetch your data from literally anywhere,
      // even a server. But for this example, we'll just fake it.
      // Give this fetch an ID
      const fetchId = ++fetchIdRef.current;

      // Set the loading state
      setLoading(true);

      // get real data
      if (fetchId === fetchIdRef.current) {
        const startRow = pageSize * pageIndex;
        const endRow = startRow + pageSize;

        setData(filterData.sort().slice(startRow, endRow));
        setPageCount(filterData.length);
        setLoading(false);
      }
    },
    [filterData]
  );

  return (
    <div className={classes.root}>
      {showEmailDialog && <EmailDialog open={showEmailDialog} setOpen={setShowEmailDialog} data={selectedUser} reload={() => { getRemoteData() }} />}
      <Dialog
        fullScreen={fullScreen}
        fullWidth={true}
        maxWidth={'md'}
        open={open}
        onClose={handleClose}
        aria-labelledby="responsive-dialog-title"
      >
        <DialogTitle id="responsive-dialog-title">
          {'Creating new user?'}
        </DialogTitle>
        <DialogContent>
          <Form closeForm={() => setOpen(false)} userType={getUserType()} />
        </DialogContent>
        <DialogActions>
          {/* <Button autoFocus onClick={handleClose} color="primary">
            Submit
          </Button>
          <Button onClick={handleClose} color="secondary" autoFocus>
            Cancel
          </Button> */}
        </DialogActions>
      </Dialog>
      {show && <DialogEmail open={show} setOpen={setShow} data={editData} />}
      <Grid container fullWidth justifyContent="space-between">
        <Grid item xs={10}>
          <Button
            variant="contained"
            color="primary"
            className={classes.button}
            onClick={handleClickOpen}
          >
            New User
          </Button>
        </Grid>
        <Grid item xs={2} justifyContent="end">
          <TextField
            label="Name Filter"
            color="primary"
            variant="outlined"
            size="small"
            onChange={onFilter}
          />
        </Grid>
      </Grid>
      <Table
        columns={columns}
        data={data}
        fetchData={fetchData}
        loading={loading}
        pageCount={pageCount}
      />
    </div>
  );
}

export default App;
