import React from 'react';
import MaterialTable from 'material-table';
import NumberFormat from 'react-number-format';

const Table = ({ data, month }) => {
  let sum = 0;
  return (
    <MaterialTable
      title={
        <p
          style={{
            fontFamily: 'Bai Jamjuree',
            fontSize: 16,
            fontWeight: 'bold',
          }}
        >
          Monthly Invoice Summary [{month}]
        </p>
      }
      columns={[
        { title: 'Invoice No', field: 'INVOICE_NO', width: 250 },
        {
          title: 'Org. ',
          field: 'ORG_CODE',
          type: 'numeric',
          width: 70,
          header: { justify: 'center' },
        },
        { title: 'Supplier Name', field: 'SUPPLIER_NAME', width: 300 },
        { title: 'Supplier No', field: 'SUPPLIER_NO', width: 100 },
        {
          title: 'Total Amount',
          field: 'TOTAL_AMOUNT',
          width: 150,
          render: (rowData) => {
            return (
              <NumberFormat
                value={rowData.TOTAL_AMOUNT}
                displayType={'text'}
                thousandSeparator={true}
                prefix={'฿ '}
              />
            );
          },
        },
        { title: 'Invoice Date', field: 'INVOICE_DATE', width: 150 },
      ]}
      data={data}
      options={{
        fixedColumns: {
          left: 1,
        },
        cellStyle: { fontFamily: 'Bai Jamjuree', textAlign: 'left' },
        pageSize: 100,
        pageSizeOptions: [5, 10, 20, 50, 100],
        headerStyle: {
          fontWeight: 'bold',
          fontFamily: 'Bai Jamjuree',
          textAlign: 'left',
        },
        exportAllData: true,
        exportButton: true,
        exportButton: {
          csv: true,
          pdf: false,
        },
        exportFileName: `invoice-${month}`,
      }}
    />
  );
};

export default Table;
