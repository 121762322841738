import React from 'react';
import {TextField} from "@material-ui/core"

const details = (props) => {
    let item = props.item;

    return <form className={props.styleProps} noValidate autoComplete="off">
        <TextField size="small" fullWidth id="del-id" label="PO Number" variant="outlined" disabled value={item.split("|")[2]} />
        <TextField size="small" fullWidth id="del-id" label="Realese No." variant="outlined" disabled value={item.split("|")[3]==="N/A"?"":item.split("|")[3]} />
        <TextField size="small" fullWidth id="del-id" label="Line No." variant="outlined" disabled value={item.split("|")[4]} />
        <TextField size="small" fullWidth id="del-id" label="Item Code" variant="outlined" disabled value={item.split("|")[5]} />
        <TextField size="small" fullWidth id="del-id" label="Del. QTY" variant="outlined" disabled value={item.split("|")[6]} />
        <TextField size="small" fullWidth id="del-id" label="QTY/Pack" variant="outlined" disabled value={item.split("|")[7]} />
        <TextField size="small" fullWidth id="del-id" label="lotSup" variant="outlined" disabled value={item.split("|")[8]!=="undefined"?item.split("|")[8]:"None"} />
        <TextField size="small" fullWidth id="del-id" label="Del. Date" variant="outlined" disabled value={item.split("|")[9]} />
    </form>
}

export default details;