import { header, getUserType, vendor_id } from './index';
import moment from 'moment';
import { company_name } from '../utils';

// const url = "https://ess.aapico.com/pos"
const backendEmail = 'https://ev.powermap.live';
const url_out = 'https://ess.aapico.com';
const backend = 'https://smp.aapico.com'; // https://backend.powermap.live
const backend_to_oracle = 'https://smp.aapico.com/deliveries/pos_v2'; //_v2 for new version https://backend.powermap.live
const oracle = 'http://ahappl04.aapico.com/OracleApp/PROGRAM/ALL/barcode_v2'; // BarcodeProJ for new project
const newBackendEmail ='https://apigw.aapico.com/api/sendemailbytemplate'

const TOKEN_KEY = 'jwt';

// get companies
export const companies = () => {
  // call api if success then save credential
  // if vendor or admin
  // console.log(getUserType());
  if (getUserType() === 'vendor') {
    return fetch(
      `${backend_to_oracle}?api=companies_vendors&vendor_id=${vendor_id()}`,
      {
        headers: header(),
      }
    );
  } else {
    return fetch(`${backend_to_oracle}?api=companies`, {
      headers: header(),
    });
  }
};

export const companiesByVendor = (id) => {
  return fetch(`${backend_to_oracle}?api=companies_vendors&vendor_id=${id}`, {
    headers: header(),
  })
    .then((res) => res.json())
    .then((res) => res);
};

export const vendors = () => {
  // call api if success then save crdential
  return fetch(`${backend_to_oracle}?api=vendors`, {
    headers: header(),
  });
};

export const getPOs = (company_id, vendor_id) => {
  // call api if success then save crdential
  return fetch(
    `${backend_to_oracle}?api=po&inv_orgid=${company_id}&vendor_id=${vendor_id}`,
    {
      headers: header(),
    }
  );
};

export const getVendorSites = (company_id, vendor_id) => {
  // call api if success then save crdential
  return fetch(
    `${backend_to_oracle}?api=vendor_sites&company=${company_id}&vendor_id=${vendor_id}`,
    {
      headers: header(),
    }
  );
};

export const getPOType = (company_oper_id, po_no) => {
  // call api if success then save crdential
  return fetch(
    `${backend_to_oracle}?api=po_type&company=${company_oper_id}&po_no=${po_no}`,
    {
      headers: header(),
    }
  );
};

export const searchPOStandard = (vendor_id, po_no) => {
  // call api if success then save crdential
  return fetch(
    `${backend_to_oracle}?api=FindPOSTD&vendor_id=${vendor_id}&ponum=${po_no}`,
    {
      headers: header(),
    }
  );
};
export const searchPOBlk = (vendor_id, po_no, release) => {
  // call api if success then save crdential
  return fetch(
    `${backend_to_oracle}?api=FindPOBLK&vendor_id=${vendor_id}&ponum=${po_no}&rel=${release}`,
    {
      headers: header(),
    }
  ).then((res) => res.json());
};

export const download = async (link, userType, current_count, rowID) => {
  // call api if success then save crdential TODO
  if (userType === 'vendor') {
    await fetch(`${backend}/forecasts/${rowID}`, {
      method: 'PUT',
      headers: header(),
      body: JSON.stringify({
        down_count: current_count + 1,
      }),
    });
  }
  return window.open(`${backend}${link}`, '_blank');
};

export const transits = () => {
  // call api if success then save crdential
  console.log(vendor_id());
  if (vendor_id() !== 'undefined') {
    return fetch(
      `${backend}/deliveries?status=transit&vendor_id=${vendor_id()}&_limit=-1`,
      {
        headers: header(),
      }
    );
  }
  return fetch(`${backend}/deliveries?status=transit&_limit=-1`, {
    headers: header(),
  });
};

export const checkDup = async (invoiceNo) => {
  // call api if success then save crdential
  return await fetch(
    `${backend}/deliveries/checkdup?invoiceNo=${invoiceNo}&vendor_id=${vendor_id()}`,
    {
      headers: header(),
    }
  ).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

export const checkExistRecord = async (invoiceNo, vendor_id, status) => {
  // call api if success then save crdential
  return await fetch(
    `${url_out}/posInvoice/checkexist?invoiceNo=${invoiceNo}&vendor_id=${vendor_id}&status=${status}`
  ).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

export const checkValidHeader = async (id) => {
  // call api if success then save credential
  return await fetch(`${url_out}/posInvoice/${id}`).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

export const checkReceived = async (invoiceNo, vendor_id) => {
  // call api if success then save crdential
  return await fetch(
    `${url_out}/posInvoice/checkreceived?invoiceNo=${invoiceNo}&vendor_id=${vendor_id}`
  ).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

export const getPDSStatus = async (id) => {
  // call api if success then save crdential
  return await fetch(
    `${backend}/pdsstatuses?org_id=${id}&_limit=1&_sort=createdAt:DESC`,
    {
      headers: header(),
    }
  ).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

export const deleteForecast = (id) => {
  // call api if success then save crdential
  return fetch(`${backend}/forecasts/${id}`, {
    method: 'DELETE',
    headers: header(),
  });
};

export const updateForecast = (id) => {
  // call api if success then save crdential
  return fetch(`${backend}/forecasts/${id}`, {
    method: 'PUT',
    headers: header(),
    body: JSON.stringify({ email_status: '1' }),
  });
};

export const forecast = (formData) =>
  fetch(`${backend}/forecasts`, {
    headers: header(),
    method: 'POST',
    body: formData,
  });

export const getForecast = (filter) =>
  fetch(`${backend}/forecasts${filter}`, {
    headers: header(),
    method: 'GET',
  }).then((res) => res.json());

export const createDelivery = (data) =>
  fetch(`${backend}/deliveries`, {
    headers: header(),
    method: 'POST',
    body: JSON.stringify(data),
  }).then((res) => res.json());

export const checkStatus = async (id) => {
  const result = await fetch(`${backend}/deliveries/${id}`, {
    headers: header(),
    method: 'GET',
  }).then((res) => res.json());
  console.log(result);
  return result.status;
};

export const deleteDelivery = (id, remarks) => {
  fetch(`${backend}/deliveries/${id}`, {
    headers: header(),
    method: 'PUT',
    body: JSON.stringify({ status: 'deleted', remarks }),
  }).then((res) => res.json());
};

export const doToInvoice = (data, invoiceNo, id, inv, newInvoiceDate) => {
  // fetch(`${backend}/deliveries/pos?api=toinvoice`,{
  //         headers: header(),
  //         method: 'POST',
  //         body: JSON.stringify([{
  //             DO: data.invoiceNo,
  //             INV: invoiceNo,
  //             INV_ORGID: inv
  //         }])
  // }).then(res => res.json()).then(res => {
  //     // upload to backend
  //     if (res.Status !== "ERROR") {
  data.isInvoice = true;
  data.doNo = data.invoiceNo; // record old DO number
  data.doDate = data.invoiceDate; // record old DO number
  data.invoiceNo = invoiceNo;
  data.invoiceDate = newInvoiceDate;
  return fetch(`${backend}/deliveries/${id}`, {
    headers: header(),
    method: 'PUT',
    body: JSON.stringify({
      details: data,
    }),
  }).then((res) => res.json());
  // }
  // return res;
  // })
};

export const doToInvoiceOracle = (doNo, invoiceNo, inv, invoiceDate) => {
  // oracle/replaceDO
  // alert(doNo  + "," + invoiceNo  + "," + inv  + "," + invoiceDate);
  return fetch(`${oracle}/replaceDO`, {
    // fetch(`${backend}/deliveries/pos?api=toinvoice`,{
    // headers: header(), // remove if internal use
    method: 'POST',
    body: JSON.stringify([
      {
        DO: doNo,
        INV: invoiceNo,
        INV_ORGID: inv,
        INV_DATE: invoiceDate,
      },
    ]),
  }).then((res) => res.json());
};

export const createReceived = (data) => {
  // console.log(data);
  return fetch(`${backend}/deliveries/pos?api=received`, {
    headers: header(),
    method: 'POST',
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

export const createReceivedInternal = (data) => {
  // alert(`test3:${JSON.stringify(data)}`);
  return fetch(`${oracle}/invoice`, {
    method: 'POST',
    body: JSON.stringify(data),
  }).then((res) => res.json());
};

// export const receivedDeliveryInternal = (id, isInvoice, employeeID, response) => {
//     return fetch(`${url_out}/posInvoice/${id}`,{
//     // headers: header(),
//     method: 'PUT',
//     headers: {
//       Accept: 'application/json',
//       'Content-Type': 'application/json',
//     },
//     body: JSON.stringify({
//         status: isInvoice?"completed":"received",
//         receiver: employeeID,
//         receivedNo: response.ReceiveNumber,
//         timeStamp: new moment().format("YYYY-MM-DD HH:mm")
//     })
// })};

export const receivedDeliveryInternal = (
  data,
  isInvoice,
  employeeID,
  response
) => {
  // alert(data);
  return fetch(`${url_out}/posInvoice/updatestatus`, {
    // headers: header(),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...data,
      status: isInvoice ? 'completed' : 'received',
      receiver: employeeID,
      receivedNo: response.ReceiveNumber,
      timeStamp: new moment().format('YYYY-MM-DD HH:mm'),
    }),
  });
};

export const receivedDeliveryInternal_store = (
  data,
  isInvoice,
  employeeID,
  response,
  store_name
) => {
  // alert(data);
  return fetch(`${url_out}/posInvoice/updatestatus`, {
    // headers: header(),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...data,
      status: isInvoice ? 'completed' : 'received',
      receiver: employeeID,
      receivedNo: response.ReceiveNumber,
      timeStamp: new moment().format('YYYY-MM-DD HH:mm'),
      store_name,
    }),
  });
};

export const doToInvoiceInternal = (data) => {
  return fetch(`${url_out}/posInvoice/updatedo`, {
    // headers: header(),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({ ...data, status: 'completed' }),
  });
};

export const testExternal = (data, isInvoice, employeeID, response) => {
  // alert(data);
  return fetch(`${url_out}/posInvoice/updatestatus`, {
    // headers: header(),
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
    },
    body: JSON.stringify({
      ...data,
      status: isInvoice ? 'completed' : 'received',
      receiver: employeeID,
      receivedNo: response.ReceiveNumber,
      timeStamp: new moment().format('YYYY-MM-DD HH:mm'),
    }),
  });
};

export const receivedDelivery = (id, isInvoice, employeeID, response) =>
  fetch(`${backend}/deliveries/${id}`, {
    // headers: header(),
    method: 'PUT',
    body: JSON.stringify({
      status: isInvoice ? 'completed' : 'received',
      receiver: employeeID,
      receivedNo: response.ReceiveNumber,
      timeStamp: new moment().format('YYYY-MM-DD HH:mm'),
    }),
  });

export const updateUserAlert = (id, data) => {
  console.log(id, data);
  // call api if success then save crdential
  return fetch(`${backend}/users/${id}`, {
    headers: new Headers({
      Authorization: `Bearer ${localStorage.getItem(TOKEN_KEY)}`,
      'Content-Type': 'application/json',
    }),
    method: 'PUT',
    body: JSON.stringify(data),
  })
    .then((res) => res.json())
    .then((res) => {
      if (res.statusCode && res.statusCode !== 200) {
        return false;
      } else {
        return true;
      }
    });
};

export const getAllUsers = () => {
  // call api if success then save crdential
  return fetch(`${backend}/users?user_type=vendor`, {
    headers: header(),
  }).then(async (res) => {
    let result = await res.json();
    // console.log(result);
    return result;
  });
};

export const email = (from, fromDetail, to, subject, body, cc) => {
  // set email to manager
  let emailHeaders = new Headers();
  emailHeaders.append('Content-Type', 'application/x-www-form-urlencoded');
  var urlencoded = new URLSearchParams();
  urlencoded.append('form', from);
  urlencoded.append('formdetail', fromDetail);
  urlencoded.append('to', to);
  urlencoded.append('cc', cc);
  urlencoded.append('subject', subject);
  urlencoded.append('body', body);

  var requestOptions = {
    method: 'POST',
    headers: emailHeaders,
    body: urlencoded,
    // redirect: 'follow',
  };

  return fetch(`https://ess.aapico.com/email`, requestOptions)
    .then((response) => response.json())
    .then((result) => result)
    .catch((error) => console.log('error', error));
};

export const getSupplierEmail = (vendor_code) =>
  fetch(`${backend}/users?vendor_code=${vendor_code}`, {
    headers: header(),
    method: 'GET',
  })
    .then((res) => res.json())
    .then((res) => res);

// TODO new version need /${REL}
export const checkSubInv = (PO, ORG, ITEM, REL) => {
  return fetch(`${oracle}/BC_Chk_DefSub/${PO}/${ORG}/${ITEM}/${REL}`, {
    method: 'GET',
  }).then((res) => res.json());
};

export const getInvoices = async () => {
  // call api if success then save crdential
  return await fetch(`${backend}/invoices?_limit=1&_sort=createdAt:DESC`, {
    headers: header(),
  }).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

export const updateInvoices = async (data) => {
  // call api if success then save crdential
  return await fetch(`${backend}/invoices/updateinvoices`, {
    headers: header(),
    method: 'POST',
    body: JSON.stringify(data),
  }).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

export const getPayments = async () => {
  // call api if success then save crdential
  return await fetch(`${backend}/payments?_limit=1&_sort=createdAt:DESC`, {
    headers: header(),
  }).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

export const updatePayments = async (data) => {
  // call api if success then save crdential
  return await fetch(`${backend}/invoices/updatepayments`, {
    headers: header(),
    method: 'POST',
    body: JSON.stringify(data),
  }).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

export const getAllInform = async (filter) => {
  // call api if success then save crdential
  return await fetch(`${backend}/information?_limit=-1${filter}`, {
    headers: header(),
    method: 'GET',
  }).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

export const updateInform = async (id, formData) => {
  await fetch(`${backend}/information/${id}`, {
    headers: header(),
    method: 'PUT',
    body: formData,
  });
};
export const newInform = async (formData) => {
  await fetch(`${backend}/information`, {
    headers: header(),
    method: 'POST',
    body: formData,
  });
};
export const deleteInform = async (id) => {
  await fetch(`${backend}/information/${id}`, {
    headers: header(),
    method: 'DELETE',
  });
};

export const uploadFiles = async (formData) => {
  await fetch(`${backend}/upload`, {
    headers: header(),
    method: 'POST',
    body: formData,
  });
};

export const deleteFile = async (id) => {
  await fetch(`${backend}/upload/files/${id}`, {
    headers: header(),
    method: 'DELETE',
  });
};

export const getConfirmPO = async (
  activePage,
  pageSize,
  searchPO,
  searchREL,
  toggle,
  searchSupName,
  searchSupCode
) => {
  // check user type and perform filter
  let userFilter =
    getUserType() === 'store' || getUserType() === 'admin'
      ? '&factory=' + company_name()
      : '&vendor_id=' + vendor_id();

  return await fetch(
    `${backend}/confirm-pos?_sort=createdAt:desc&_start=${
      (activePage - 1) * pageSize
    }&_limit=${pageSize}${searchPO ? '&po_number_contains=' + searchPO : ''}${
      searchSupName ? '&supplier_name_contains=' + searchSupName : '' // search by supplier name
    }${
      searchSupCode ? '&supplier_code_contains=' + searchSupCode : '' // search by supplier code
    }${searchREL ? '&rel_number_contains=' + searchREL : ''}${userFilter}${
      toggle ? '&download_count=0' : ''
    }`,
    {
      headers: header(),
      method: 'GET',
    }
  ).then(async (res) => {
    let output = await res.json();
    // console.log(res.headers, output);
    return { data: output, total: res.headers.get('Content-Range') };
  });
};



export const getEmailTo = async (vendor_id) => {
  return await fetch(`${backend}/users?vendor_id=${vendor_id}`, {
    headers: header(),
    method: 'GET',
  }).then(async (res) => {
    let output = await res.json();
    return output;
  });
};
export const getVendors = async () => {
  return await fetch(`${backend}/metadata?type=vendors`, {
    headers: header(),
    method: 'GET',
  }).then(async (res) => {
    let output = await res.json();
    return output;
  });
};
export const getCompanies = async () => {
  return await fetch(`${backend}/metadata?type=companies`, {
    headers: header(),
    method: 'GET',
  }).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

export const uploadConfirmPO = (formData) =>
  fetch(`${backend}/confirm-pos`, {
    headers: header(),
    method: 'POST',
    body: formData,
  });

export const updateConfirmPO = async (id, data) => {
  return await fetch(`${backend}/confirm-pos/${id}`, {
    headers: header(),
    method: 'PUT',
    body: JSON.stringify(data),
  }).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

// peelawit 06/01/66

export const getConfirmPurchase = async (
  activePage,
  pageSize,
  searchPO,
  searchREL,
  toggle,
  searchSupName,
  searchSupCode
) => {
  // check user type and perform filter
  let userFilter =
      getUserType() === 'admin' || getUserType() === 'purchase'
      ? '&factory=' + company_name()
      : '&vendor_id=' + vendor_id();

  return await fetch(
    
    `${backend}/sqm-tables?_sort=createdAt:desc&_start=${
      (activePage - 1) * pageSize
    }&_limit=${pageSize}${searchPO ? '&po_number_contains=' + searchPO : ''}${
      searchSupName ? '&supplier_name_contains=' + searchSupName : '' // search by supplier name
    }${
      searchSupCode ? '&supplier_code_contains=' + searchSupCode : '' // search by supplier code
    }${searchREL ? '&rel_number_contains=' + searchREL : ''}${userFilter}${
      toggle ? '&download_count=0' : ''
    }`,
    {
      headers: header(),
      method: 'GET',
    },
    console.log(`test api`,`${backend}/sqm-tables?_sort=createdAt:desc&_start=${
      (activePage - 1) * pageSize
    }&_limit=${pageSize}${searchPO ? '&po_number_contains=' + searchPO : ''}${
      searchSupName ? '&supplier_name_contains=' + searchSupName : '' // search by supplier name
    }${
      searchSupCode ? '&supplier_code_contains=' + searchSupCode : '' // search by supplier code
    }${searchREL ? '&rel_number_contains=' + searchREL : ''}${userFilter}${
      toggle ? '&download_count=0' : ''
    }`)
  ).then(async (res) => {
    let output = await res.json();
    // console.log(res.headers, output);
    return { data: output, total: res.headers.get('Content-Range') };
  });
};

export const uploadConfirmSQM = (formData) =>
  fetch(`${backend}/sqm-tables`, {
    headers: header(),
    method: 'POST',
    body: formData,
  });

export const uploadConfirmPurchase = async (formData) =>
  await fetch(`${backend}/sqm-tables`, {
    headers: header(),
    method: 'POST',
    body: formData,
  });

export const updateConfirmPurchase = async (id, data) => {
  return await fetch(`${backend}/sqm-tables/${id}`, {
    headers: header(),
    method: 'PUT',
    body: JSON.stringify(data),
  }).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

export const sendingEmailPurchase = async (data) => {
  return await fetch(`https://apigw.aapico.com/api/sendemailbytemplate`, {
    headers: new Headers({ 'Content-Type': 'application/json' }),
    method: 'POST',
    body: JSON.stringify(data),
  }).then(async (res) => {
    return res;
  });
};
export const uploadByRef = async (formData) => {
  return await fetch(
    `${backend}/upload`,
    {
      headers: {
        ...header(),
      },
      method: "POST",
      body: formData,
    },
    )
}
export const deleteConfirmPurchase = async (id) => {
  return await fetch(`${backend}/sqm-tables/${id}`, {
    headers: header(),
    method: 'DELETE',
  }).then(async (res) => {
    return res;
  });
};

export const getPurchaseEmail = async (type, company) => {
  return await fetch(
    `${backend}/users?user_type=${type}&company_name=${company}`,
    {
      headers: header(),
      method: 'GET',
    }
  ).then(async (res) => {
    return res.json();
  });
};

export const getPurchaseSearchDate = (filter) =>
  fetch(`${backend}/sqm-tables${filter}`, {
    headers: header(),
    method: 'GET',
  }).then((res) => res.json());

// ----------------------------------------------------------------------------------------------------------------------

// --------------------------------- Code of Conduct -------------------------------------------------------------------//
export const getCOC = async (
  activePage,
  pageSize,
  searchPO,
  searchREL,
  toggle,
  searchSupName,
  searchSupCode
) => {
  // check user type and perform filter
  let userFilter =
      getUserType() === 'admin' || getUserType() === 'purchase'
      ? '&factory=' + company_name()
      : '&vendor_id=' + vendor_id();
  return await fetch(
    `${backend}/coc-tables?_sort=createdAt:desc&_start=${
      (activePage - 1) * pageSize
    }&_limit=${pageSize}${searchPO ? '&po_number_contains=' + searchPO : ''}${
      searchSupName ? '&supplier_name_contains=' + searchSupName : '' // search by supplier name
    }${
      searchSupCode ? '&supplier_code_contains=' + searchSupCode : '' // search by supplier code
    }${searchREL ? '&rel_number_contains=' + searchREL : ''}${userFilter}${
      toggle ? '&download_count=0' : ''
    }`,
    {
      headers: header(),
      method: 'GET',
    },
    console.log(`test api`,`${backend}/coc-tables?_sort=createdAt:desc&_start=${
      (activePage - 1) * pageSize
    }&_limit=${pageSize}${searchPO ? '&po_number_contains=' + searchPO : ''}${
      searchSupName ? '&supplier_name_contains=' + searchSupName : '' // search by supplier name
    }${
      searchSupCode ? '&supplier_code_contains=' + searchSupCode : '' // search by supplier code
    }${searchREL ? '&rel_number_contains=' + searchREL : ''}${userFilter}${
      toggle ? '&download_count=0' : ''
    }`)
  ).then(async (res) => {
    let output = await res.json();
    console.log("res.headers",res.headers, output);
    return { data: output, total: res.headers.get('Content-Range') };
  });
};

export const uploadCOC = async (formData) =>
  await fetch(`${backend}/coc-tables`, {
    headers: header(),
    method: 'POST',
    body: formData,
  });

  export const deleteCOC = async (id) => {
    return await fetch(`${backend}/coc-tables/${id}`, {
      headers: header(),
      method: 'DELETE',
    }).then(async (res) => {
      return res;
    });
  };

  export const updateCOC = async (id, data) => {
    return await fetch(`${backend}/coc-tables/${id}`, {
      headers: header(),
      method: 'PUT',
      body: JSON.stringify(data),
    }).then(async (res) => {
      let output = await res.json();
      return output;
    });
  };

  export const sendingEmailCOC = async (data) => {
    return await fetch(`https://apigw.aapico.com/api/sendemailbytemplate`, {
      headers: new Headers({ 'Content-Type': 'application/json' }),
      method: 'POST',
      body: JSON.stringify(data),
    }).then(async (res) => {
      return res;
    });
  };

  
// ------------------------------------------------------------------------------------------------------------------------------------------------
export const searchConfirmPO = (po_number, rel_number) =>
  fetch(
    `${backend}/confirm-pos?${
      rel_number ? 'po_number_contains=' + po_number : ''
    }${rel_number ? 'po_number_contains=' + rel_number : ''}`,
    {
      headers: header(),
      method: 'GET',
    }
  ).then((res) => res.json());

// export const sendingEmail = async (data) => {
//   return await fetch(`${backendEmail}/api/utils/sendEmail`, {
//     headers: new Headers({ 'Content-Type': 'application/json' }),
//     method: 'POST',
//     body: JSON.stringify(data),
//   }).then(async (res) => {
//     return res;
//   });
// };
// peelawit change API sending mail 01/02/24 
export const sendingEmail = async (data) => {
  return await fetch(`${newBackendEmail}`, {
    headers: new Headers({ 'Content-Type': 'application/json' }),
    method: 'POST',
    body: JSON.stringify(data),
  }).then(async (res) => {
    return res;
  });
};

export const uploadConfirmASN = (formData) =>
  fetch(`${backend}/confirm-asns`, {
    headers: header(),
    method: 'POST',
    body: formData,
  });

export const updateConfirmASN = async (id, data) => {
  return await fetch(`${backend}/confirm-asns/${id}`, {
    headers: header(),
    method: 'PUT',
    body: JSON.stringify(data),
  }).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

export const deleteConfirmPO = async (id) => {
  return await fetch(`${backend}/confirm-pos/${id}`, {
    headers: header(),
    method: 'DELETE',
  }).then(async (res) => {
    return res;
  });
};

export const getStoreEmail = async (type, company) => {
  return await fetch(
    `${backend}/users?user_type=${type}&company_name=${company}`,
    {
      headers: header(),
      method: 'GET',
    }
  ).then(async (res) => {
    return res.json();
  });
};


export const getGroupEmail = async (activePage, pageSize, searchSupplier) => {
  let userFilter =
    getUserType() === 'store' || getUserType() === 'admin'
      ? '&factory=' + company_name()
      : '';
  return await fetch(
    `${backend}/email-groups?_sort=createdAt:desc&_start=${
      (activePage - 1) * pageSize
    }&_limit=${pageSize}${
      searchSupplier ? '&supplier_name_contains=' + searchSupplier : ''
    }${userFilter}`,
    {
      headers: header(),
      method: 'GET',
    }
  ).then(async (res) => {
    let output = await res.json();
    console.log(res.headers, output);
    return { data: output, total: res.headers.get('Content-Range') };
  });
};

export const createGroupEmail = async (data) => {
  return await fetch(`${backend}/email-groups?`, {
    headers: header(),
    method: 'POST',
    body: JSON.stringify(data),
  }).then((res) => res);
};

export const updateGroupEmail = async (data, id) => {
  return await fetch(`${backend}/email-groups/${id}?`, {
    headers: { ...header(), 'Content-Type': 'application/json' },
    method: 'PUT',
    body: JSON.stringify(data),
  }).then((res) => res);
};

export const deleteGroupEmail = async (id) => {
  return await fetch(`${backend}/email-groups/${id}?`, {
    headers: header(),
    method: 'DELETE',
  }).then((res) => res);
};

export const getAllGroupEmail = async (name) => {
  return await fetch(
    `${backend}/email-groups?&_limit=-1${
      name ? '&name_contains=' + name : ''
    }&factory=${company_name()}`,
    {
      headers: header(),
      method: 'GET',
    }
  ).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

export const countGroupEmail = async (name) => {
  return await fetch(`${backend}/email-groups/count?name=${name}`, {
    headers: header(),
    method: 'GET',
  }).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

export const getOSPJobDetails = async (data) => {
  // http://agrtrack02.aapico.com/oracle/API/smp/FindJobOSP
  return await fetch(
    `${backend_to_oracle}?api=FindJobOSP&data=${JSON.stringify(data)}`,
    {
      headers: header(),
      method: 'GET',
    }
  ).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

export const getJobDetails = async (data) => {
  //agrtrack02.aapico.com/oracle/API/smp/FindJobOSP
  http: return await fetch(
    `${backend_to_oracle}?api=FindJobDetails&data=${JSON.stringify(data)}`,
    {
      headers: header(),
      method: 'GET',
    }
  ).then(async (res) => {
    let output = await res.json();
    return output;
  });
};

export const updateUserEmail = async (data, id) => {
  return await fetch(`${backend}/users/${id}?`, {
    headers: { ...header(), 'Content-Type': 'application/json' },
    method: 'PUT',
    body: JSON.stringify(data),
  }).then((res) => res);
};


