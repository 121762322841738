import React, { useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { useSnackbar } from 'notistack';

function FormDialog(props) {
  // console.log(props);
  const { enqueueSnackbar } = useSnackbar();
  const [open, setOpen] = React.useState(props.open);
  const [email, setEmail] = React.useState('');
  const handleClose = () => {
    props.close();
    setOpen(false);
  };

  const handleSubmit = () => {
    // https://backend.powermap.live
    fetch('https://smp.aapico.com/auth/forgot-password', {
      method: 'POST', // or 'PUT'
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({
        email,
      }),
    })
      .then((response) => response.json())
      .then((data) => {
        // console.log('Success:', data);
        enqueueSnackbar('Email send...', {
          varint: 'success',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        setOpen(false);
      })
      .catch((error) => {
        enqueueSnackbar('Something went wrong...', {
          variant: 'error',
          anchorOrigin: {
            vertical: 'bottom',
            horizontal: 'right',
          },
        });
        // console.error('Error:', error);
      });
  };

  useEffect(() => {
    setOpen(props.open);
  }, [props]);

  const handleChange = (e) => {
    setEmail(e.target.value);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="form-dialog-title"
      >
        <DialogTitle id="form-dialog-title">
          Please let me know your email.
        </DialogTitle>
        <DialogContent>
          <DialogContentText>
            To reset password, please enter your email address here. We will
            send link to reset.
          </DialogContentText>
          <TextField
            autoFocus
            margin="dense"
            id="name"
            label="Email Address"
            type="email"
            fullWidth
            onChange={handleChange}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleSubmit} color="primary">
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

export default FormDialog;
